import { Log } from '@retorio/sdk'

import { hasIdentity, selectOption, selectOptions } from './selectors'

export const SET_OPTIONS = 'options/set'

export const setOptions = options => dispatch => {
  const meta = options.meta || {}

  if ('id' in meta || 'name' in meta || 'email' in meta) {
    Log.setUser(meta)
  }

  dispatch({
    type: SET_OPTIONS,
    payload: options,
  })
}

export const nextWithIdentity = values => (dispatch, getState) => {
  const state = getState()
  const options = selectOptions(state)
  const storeIdentity = selectOption('identityRequired')(state) && !hasIdentity(state)

  if (storeIdentity) {
    // const identity = getFormValues('identity')(state)

    dispatch({
      type: SET_OPTIONS,
      payload: {
        ...options,
        meta: { ...options.meta, ...values },
      },
    })
  }
}
