import { config } from '@retorio/sdk'

import { FormattedMessage } from './FormattedMessage'
import style from './InsufficientLengthError.scss'

const InsufficientLengthError = () => (
  <div className={style.container}>
    <h2>
      <FormattedMessage id="error.insufficientLength.title" />
    </h2>
    <p>
      <FormattedMessage
        id="error.insufficientLength.description"
        values={{ seconds: config.minRecordingTime }}
      />
    </p>
  </div>
)

export default InsufficientLengthError
