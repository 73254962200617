import {
  ButtonProps,
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react'
import { isString } from '@retorio/common-utils'
import { IoLanguage } from 'react-icons/io5'

import { colors } from '../../theme/foundations/colors'

export type Language = {
  label: string
  value: string
}

export type LanguageSwitchMenuProps = {
  selectedLanguage: string
  availableLanguages: ReadonlyArray<Language>
  onChange: (v: string) => void
} & Omit<ButtonProps, 'onChange'>

export const LanguageSwitchMenu = ({
  selectedLanguage,
  availableLanguages,
  onChange,
  ...rest
}: LanguageSwitchMenuProps) => {
  const handleOnChange = (value: string | Array<string>) => {
    if (isString(value)) {
      onChange(value)
    }
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Language"
        bg="transparent !important"
        color={colors['primary-dark']}
        size="md"
        icon={<IoLanguage size="20px" />}
        {...rest}
      />

      <MenuList zIndex={100}>
        <MenuOptionGroup type="radio" onChange={handleOnChange} value={selectedLanguage}>
          {availableLanguages.map(({ label, value }) => (
            <MenuItemOption value={value} key={value}>
              {label}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
