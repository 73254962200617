// Chakra Theme overrides
// https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src/components/checkbox.ts

export const checkboxTheme = {
  baseStyle: {
    control: {
      alignSelf: 'flex-start',
      mt: '4px',
    },
  },
}
