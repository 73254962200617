import classNames from 'classnames'
import PropTypes from 'prop-types'

import style from './style.scss'

const Icon = ({ name, className }) => {
  const iconClass = `icon-${name}`

  return <i className={classNames(style.icon, style[iconClass], className)} />
}

Icon.propTypes = {
  className: PropTypes.any,
  name: PropTypes.string.isRequired,
}

export default Icon
