import cx from 'classnames'
import PropTypes from 'prop-types'
import { FaTimes } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { hideDialog } from '../store/dialog'
import Card from './Card'
import style from './Dialog.scss'
import IconButton from './IconButton'

const Dialog = ({ children, error, overlay = true }) => {
  const dispatch = useDispatch()
  const dialog = useSelector(state => state.dialog)

  const toggle = () => {
    if (dialog.type === 'dialog') {
      dispatch(hideDialog())
    }
  }

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  return (
    <div className={cx(style.dialog, !!error && style['is-error'])} data-cy="dialog">
      {overlay && <div className={style.overlay} onClick={toggle} role="main" />}
      <div className={style.wrapper}>
        <Card className={style.card}>
          {!error && (
            <IconButton
              className={style['close-btn']}
              icon={FaTimes}
              onClick={toggle}
              size="sm"
              data-cy="dialog-close-btn"
            />
          )}
          {children}
        </Card>
      </div>
    </div>
  )
}

Dialog.propTypes = {
  children: PropTypes.node,
  error: PropTypes.bool,
  overlay: PropTypes.bool,
}

export default Dialog
