import cx from 'classnames'
import PropTypes from 'prop-types'

import style from './Button.scss'

const Button = ({ children, className, size = 'md', variant = 'default', ...other }) => (
  <button
    className={cx(style.btn, style[`btn-${variant}`], style[`btn-${size}`], className)}
    type="button"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...other}
  >
    {children}
  </button>
)

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.any,
  size: PropTypes.oneOf(['md', 'sm']),
  variant: PropTypes.oneOf(['dark', 'default', 'light', 'primary']),
}

export default Button
