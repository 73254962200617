type BrowserSupport = {
  [key: string]: string
}

export const minimumBrowserVersions: BrowserSupport = {
  Chrome: '107.0.5304.105',
  Firefox: '106.0.5',
  Safari: '15.5',
  Edge: '107.0.1418.42',
}
