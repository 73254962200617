import { Icon } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { FaPlayCircle, FaStopCircle } from 'react-icons/fa'

import Button from './Button'
import { FormattedMessage } from './FormattedMessage'
import { RecorderState } from './MainRecorder'
import style from './RecordButton.scss'

const RecordButton = ({
  disabled,
  onStop = () => {
    /* do nothing */
  },
  onResume = () => {
    /* do nothing */
  },
  onStart = () => {
    /* do nothing */
  },
  setStartCountdown = () => {
    /* do nothing */
  },

  state = RecorderState.READY_TO_RECORD,
}) => {
  const [countdownActive, setCountdownActive] = useState(false)
  const [countdown, setCountdown] = useState(3)
  const [timer, setTimer] = useState(null)

  useEffect(() => {
    if (countdown === 0) {
      clearInterval(timer)
      setCountdownActive(false)
      setCountdown(3)
      onStart()
    }
  }, [countdown])

  useEffect(() => {
    if (countdownActive) {
      setStartCountdown(countdownActive)
    }
  }, [setStartCountdown, countdownActive])

  const start = () => {
    const decrement = () => setCountdown(prevCountdown => prevCountdown - 1)

    clearInterval(timer)
    setTimer(setInterval(decrement, 1000))
    setCountdownActive(true)
  }

  const handleClick = () => {
    switch (state) {
      case RecorderState.RECORDING:
        onStop()
        break
      case RecorderState.READY_TO_PLAY:
        onResume()
        break
      default:
        start()
    }
  }

  let label
  let icon
  let dataCyMessage

  if (countdownActive) {
    label = <FormattedMessage id="recordBtn.countdown" values={{ countdown }} />
  } else {
    switch (state) {
      case RecorderState.RECORDING:
        dataCyMessage = 'stop'
        label = <FormattedMessage id="recordBtn.stop" />
        icon = <Icon as={FaStopCircle} color="white" pr={2} boxSize="25px" />
        break
      case RecorderState.READY_TO_PLAY:
        dataCyMessage = 'restart'
        label = <FormattedMessage id="recordBtn.restart" />
        icon = <Icon as={FaPlayCircle} color="white" pr={2} boxSize="25px" />
        break
      case RecorderState.SAVING:
        dataCyMessage = 'restart'
        label = <FormattedMessage id="recordBtn.saving" />
        icon = <Icon as={FaPlayCircle} color="white" pr={2} boxSize="25px" />
        break
      default:
        dataCyMessage = 'start'
        label = <FormattedMessage id="recordBtn.start" />
        icon = <Icon as={FaPlayCircle} color="white" pr={2} boxSize="25px" />
    }
  }

  const isDisabled =
    [RecorderState.UPLOADING, RecorderState.SAVING].includes(state) ||
    disabled ||
    countdownActive

  return (
    <Button
      className={style.btn}
      variant="dark"
      onClick={handleClick}
      data-cy="record-button"
      disabled={isDisabled}
      data-cy-message={dataCyMessage}
    >
      {icon}
      <span className={style.label}>{label}</span>
    </Button>
  )
}

RecordButton.propTypes = {
  disabled: PropTypes.bool,
  onResume: PropTypes.func,
  onStart: PropTypes.func,
  onStop: PropTypes.func,
  setStartCountdown: PropTypes.func,
  state: PropTypes.oneOf(Object.values(RecorderState)),
}

export default RecordButton
