// Chakra Theme overrides
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/breadcrumb.ts

const breadcrumbTheme = {
  baseStyle: {
    link: {
      color: 'primary-dark',
    },
  },
}

export { breadcrumbTheme }
