import { createFormattedMessage, FormattedMessageProps } from '@retorio/shared-components'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react'

import en from '../../i18n/en.json'

export const FormattedMessage = ({ id, ...rest }: FormattedMessageProps<typeof en>) => {
  const M = createFormattedMessage(en)

  return <M id={id} {...rest} />
}
