// Chakra Theme overrides
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/radio.ts

const radioTheme = {
  baseStyle: {
    label: {
      _hover: {
        cursor: 'pointer',
      },
    },
    control: {
      _hover: {
        cursor: 'pointer',
      },
    },
  },
}

export { radioTheme }
