import PropTypes from 'prop-types'

import style from './style.scss'

const Container = ({ main, footer }) => (
  <div className={style.container}>
    <div className={style.main}>{main}</div>
    <div className={style.footer}>{footer}</div>
  </div>
)

Container.propTypes = {
  footer: PropTypes.node,
  main: PropTypes.node,
}

export default Container
