import { Log } from '@retorio/sdk'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import dialog from './dialog'
import intl from './intl'
import locale from './locale'
import options from './options'
import plugin from './plugin'
import recordings from './recordings'

const reducers = combineReducers({
  dialog,
  locale,
  options,
  plugin,
  recordings,
  intl,
})

const logMiddleware = _ => next => action => {
  Log.addBreadcrumb('action', { type: action.type })

  return next(action)
}

const middleware = applyMiddleware(thunk, logMiddleware)
/* eslint-disable no-underscore-dangle */
const enhancer = window.__REDUX_DEVTOOLS_EXTENSION__
  ? compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__())
  : middleware
/* eslint-enable */

const store = createStore(reducers, enhancer)

export default store
