import { HIDE_DIALOG, SHOW_DIALOG, SHOW_ERROR } from './actions'

const defaultState = null

export default (state = defaultState, { type, content } = {}) => {
  switch (type) {
    case HIDE_DIALOG:
      return defaultState
    case SHOW_DIALOG:
      return { type: 'dialog', content }
    case SHOW_ERROR:
      return { type: 'error', content }
    default:
      return state
  }
}
