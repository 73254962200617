import { useMemo } from 'react'
import {
  MessageDescriptor as IntlMessageDescriptor,
  useIntl as useIntlReact,
} from 'react-intl'

import { getDefaultMessage, LanguageDictionary } from './FormattedMessageFactory'

export type MessageDescriptor<T extends LanguageDictionary = LanguageDictionary> =
  Exclude<IntlMessageDescriptor, 'id'> & {
    // eslint-disable-next-line @typescript-eslint/ban-types
    id: keyof T | String
  }
type MessageValues = Record<string, string | number | boolean | Date>

const createUseIntlHook = <T extends LanguageDictionary>(enDict: T) => {
  // create a new useIntl hook with default message fallback
  const useIntl = () => {
    const intl = useIntlReact()

    return useMemo(
      () => ({
        ...intl,
        formatMessage(descriptor: MessageDescriptor<T>, values?: MessageValues) {
          return intl.formatMessage(
            { ...descriptor, defaultMessage: getDefaultMessage(enDict, descriptor.id) },
            // We always allow `lineBreak` placeholder
            { ...values, lineBreak: <br /> }
          ) as string
        },
      }),
      [intl]
    )
  }

  return useIntl
}

export { createUseIntlHook }
