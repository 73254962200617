const getLocalState = state => state.recordings

export const selectAllRecordings = getLocalState

// We don't have reselect installed in this repo and for simplicities sake I won't
// install it and instead use the `make` syntax like this. I know it's not ideal.
export const selectRecording = (state, questionId) =>
  selectAllRecordings(state).find(recording => recording.questionId === questionId)

export const selectAnalysisId = (state, questionId) =>
  selectRecording(state, questionId).analysisId

export const selectActiveRecording = state =>
  selectAllRecordings(state).find(({ active }) => active) || {}

export const selectActiveRecordingIndex = state =>
  selectAllRecordings(state).findIndex(({ active }) => active)

export const selectNextRecording = state => {
  const activeRecordingIndex = selectActiveRecordingIndex(state)

  return selectAllRecordings(state)[activeRecordingIndex + 1]
}
