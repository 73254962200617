import {
  Box,
  Button,
  chakra,
  Heading,
  Image,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { colors } from '@retorio/ui'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment } from 'react'
import { MdCheckCircle } from 'react-icons/md'

import IntroExecution from '../assets/intro-execution.jpg'
import { FormattedMessage } from './FormattedMessage'

type InstructionsModalProps = {
  isSecondaryButton?: boolean
  product?: 'recruiting' | 'coaching'
}

export const InstructionsModal = ({
  isSecondaryButton,
  product,
}: InstructionsModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Fragment>
      {product === 'recruiting' && (
        <Button
          onClick={onOpen}
          w={['full', 'auto']}
          bgColor={colors.instructionButtonColor}
          color="dark"
          borderRadius="1rem"
          _hover={{
            bg: 'gray.300',
            border: 'none',
          }}
        >
          <FormattedMessage id="dashboard.intro.subtitle.goToInstructions" />
        </Button>
      )}

      {product === 'coaching' && (
        <Button
          variant={isSecondaryButton ? 'outline' : 'solid'}
          onClick={onOpen}
          w={['full', 'auto']}
        >
          <FormattedMessage id="dashboard.intro.subtitle.goToInstructions" />
        </Button>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p="10">
            <VStack spacing="8" align="stretch">
              <Box>
                <Heading as="h3" size="lg" marginBottom="3">
                  <FormattedMessage id="dashboard.intro.modal.quickTips.title" />
                </Heading>
                <List
                  spacing={2}
                  sx={{
                    li: {
                      display: 'flex',
                    },
                    span: {
                      lineHeight: 1,
                    },
                  }}
                >
                  <ListItem>
                    <ListIcon as={MdCheckCircle} color="green.400" />
                    <chakra.span pl={2}>
                      <FormattedMessage id="dashboard.intro.modal.quickTips.tip.1" />
                    </chakra.span>
                  </ListItem>
                  <ListItem>
                    <ListIcon as={MdCheckCircle} color="green.400" />
                    <chakra.span pl={2}>
                      <FormattedMessage id="dashboard.intro.modal.quickTips.tip.2" />
                    </chakra.span>
                  </ListItem>
                  <ListItem>
                    <ListIcon as={MdCheckCircle} color="green.400" />
                    <chakra.span pl={2}>
                      <FormattedMessage id="dashboard.intro.modal.quickTips.tip.3" />
                    </chakra.span>
                  </ListItem>
                  <ListItem>
                    <ListIcon as={MdCheckCircle} color="green.400" />
                    <chakra.span pl={2}>
                      <FormattedMessage id="dashboard.intro.modal.quickTips.tip.4" />
                    </chakra.span>
                  </ListItem>
                </List>
              </Box>
              <Box>
                <Heading as="h3" size="lg" marginBottom="3">
                  <FormattedMessage id="dashboard.intro.modal.execution.title" />
                </Heading>
                <Text>
                  <FormattedMessage id="dashboard.intro.modal.execution.text" />
                </Text>
                <Image src={IntroExecution} alt="Execution" />
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              bgColor={product === 'recruiting' ? colors.startButtonColor : 'blue.500'}
              mr={3}
              onClick={onClose}
              color="white"
              _hover={{
                bg: product === 'recruiting' ? colors.startButtonHoverColor : '',
              }}
            >
              <FormattedMessage id="dashboard.intro.modal.close" />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  )
}
