// Chakra Theme overrides
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/input.ts

export const inputTheme = {
  baseStyle: {},
  defaultProps: {
    size: 'sm',
  },
  sizes: {
    sm: {
      field: { borderRadius: 'md' },
      addon: { borderRadius: 'md' },
    },
  },
  variants: {
    filled: {
      field: {
        _hover: {
          bg: 'secondary-light',
        },
        _disabled: {
          cursor: 'not-allowed',
          color: 'gray.700',
        },
        bg: 'secondary-light',
      },
    },
  },
}
