import { Center } from '@chakra-ui/react'
import PropTypes from 'prop-types'

import Spinner from './Spinner'

const RecorderSpinner = ({ height, width }) => (
  <Center style={{ width, height, backgroundColor: 'black' }}>
    <Spinner size="xlarge" color="white" />
  </Center>
)

RecorderSpinner.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
}

export default RecorderSpinner
