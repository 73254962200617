import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { colors } from '@retorio/ui'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from 'react'

import { FormattedMessage } from './FormattedMessage'

const NoDeviceErrorModal = ({ hasAccess, isLoading, product }) => {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: !hasAccess })

  useEffect(() => {
    if (isLoading === false && hasAccess === false) {
      onOpen()
    }
  }, [hasAccess, onOpen, isLoading])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={4}>
          <Heading as="h4" size="sm" p={4}>
            <FormattedMessage id="error.noCamera.title" />
          </Heading>
          <Text align="center" fontSize="sm">
            <FormattedMessage id="error.noCamera.description" />
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            bgColor={product === 'recruiting' ? colors.startButtonColor : 'blue.500'}
            mr={3}
            onClick={onClose}
            color="white"
            _hover={{
              bg: product === 'recruiting' ? colors.startButtonHoverColor : '',
            }}
          >
            <FormattedMessage id="dashboard.intro.modal.close" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default NoDeviceErrorModal
