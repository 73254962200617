export const tabsTheme = {
  variants: {
    'solid-rounded': {
      tablist: {
        gap: 2,
      },
      tab: {
        bg: 'blue.50',
        color: 'blue.700',

        _selected: {
          bg: 'blue.500',
          color: 'white',
        },
      },
    },
  },
}
