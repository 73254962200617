import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Route } from 'react-router-dom'

import { FormattedMessage } from './FormattedMessage'
import style from './PluginRoute.scss'
import Spinner from './Spinner'

// PluginRoute makes sure that the plugin is loaded before rendering the component.
const PluginRoute = ({ component: Component, ...props }) => {
  const plugin = useSelector(state => state.plugin)

  return (
    <Route
      {...props}
      render={routeProps =>
        plugin ? (
          <Component {...routeProps} />
        ) : (
          <div className={style.loader}>
            <Spinner color="primary" size="xlarge" />
            <p>
              <FormattedMessage id="loading" />
            </p>
          </div>
        )
      }
    />
  )
}

PluginRoute.propTypes = {
  component: PropTypes.node.isRequired,
}

export default PluginRoute
