import { VStack } from '@chakra-ui/react'
import { FormikFormField } from '@retorio/shared-components'
import { Form, useFormikContext } from 'formik'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react'

import { useIntl } from './FormattedMessage'

type IdentityFormValues = {
  email: string
  name: string
}

export const IdentityForm = () => {
  const { formatMessage } = useIntl()
  const { errors } = useFormikContext<IdentityFormValues>()

  return (
    <Form>
      <VStack alignItems="flex-start" spacing={4}>
        <FormikFormField
          errors={errors.name}
          name="name"
          placeholder={formatMessage({
            id: 'forms.identity.name.placeholder',
          })}
        />

        <FormikFormField
          errors={errors.email}
          name="email"
          placeholder={formatMessage({
            id: 'forms.identity.email.placeholder',
          })}
        />
      </VStack>
    </Form>
  )
}
