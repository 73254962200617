import logoDark from '../../assets/images/logo-dark.svg'
import logoEye from '../../assets/images/logo-eye.svg'
import logoLight from '../../assets/images/logo-light.svg'
import { LogoProps, LogoType } from './Logo.types'

const getLogoImg = (type?: LogoType) => {
  switch (type) {
    case 'dark':
      return logoDark
    case 'light':
      return logoLight
    default:
      return logoEye
  }
}

export const Logo = ({ type }: LogoProps) => (
  <img src={getLogoImg(type)} alt="Retorio logo" />
)
