import { Icon } from '@chakra-ui/react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { FaFileUpload } from 'react-icons/fa'

import { FormattedMessage } from './FormattedMessage'
import style from './UploadButton.scss'

const UploadButton = ({
  className,
  onComplete = () => {
    /* do nothing */
  },
  variant = 'dark',
  isMobile,
}) => {
  const handleChange = ({ target: { files } }) => onComplete(files[0])

  /* eslint-disable jsx-a11y/label-has-associated-control */
  return (
    <label
      className={cx(style.btn, style[`btn-${variant}`], className)}
      data-cy="upload-button"
      style={{ display: 'flex', alignContent: 'center' }}
    >
      <input
        name="file"
        type="file"
        accept="video/*"
        style={{ display: 'none' }}
        onChange={handleChange}
      />
      <Icon as={FaFileUpload} color="white" pr={2} boxSize="20px" />
      <span className={style.label}>
        <FormattedMessage id={isMobile ? 'uploadBtnMobile' : 'uploadBtn'} />
      </span>
    </label>
  )
}

UploadButton.propTypes = {
  className: PropTypes.any,
  onComplete: PropTypes.func,
  variant: PropTypes.oneOf(['danger', 'dark', 'default', 'primary', 'success']),
  isMobile: PropTypes.bool,
}

export default UploadButton
