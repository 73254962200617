import { useEffect, useState } from 'react'
import { useIntercom } from 'react-use-intercom'

import config from '../config'

const intercomAppId = config?.intercom?.appID

export const RetorioIntercom = () => {
  const [hasBooted, setHasBooted] = useState(false)
  const { boot } = useIntercom()

  useEffect(() => {
    if (intercomAppId) {
      if (!hasBooted) {
        boot()
        setHasBooted(true)
      }
    }
  }, [boot, hasBooted])

  return null
}
