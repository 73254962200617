import { maxUploadSize } from '@retorio/sdk'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { hideDialog } from '../store/dialog'
import Button from './Button'
import { FormattedMessage } from './FormattedMessage'
import style from './VideoUploadError.scss'

const VideoUploadError = ({ onClose, maxLength, type }) => {
  const dispatch = useDispatch()

  const closeDialog = () => {
    onClose()
    dispatch(hideDialog())
  }

  return (
    <div className={style.container}>
      <h2>
        <FormattedMessage id={`error.videoUpload.title.${type}`} />
      </h2>
      <p>
        <FormattedMessage
          id={`error.videoUpload.description.${type}`}
          values={{
            maxUploadSize: Math.trunc(maxUploadSize / 1024 / 1024),
            maxLength: <strong>{maxLength}</strong>,
          }}
        />
      </p>
      <div className={style.actions}>
        <Button variant="primary" onClick={closeDialog}>
          <FormattedMessage id="error.videoUpload.okButton" />
        </Button>
      </div>
    </div>
  )
}

VideoUploadError.propTypes = {
  onClose: PropTypes.func,
  type: PropTypes.oneOf(['size', 'duration']).isRequired,
  maxLength: PropTypes.number,
}

VideoUploadError.defaultProps = {
  onClose: Function.prototype,
  maxLength: 0,
}

export default VideoUploadError
