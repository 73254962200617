import PropTypes from 'prop-types'

import style from './ExceededTriesError.scss'
import { FormattedMessage } from './FormattedMessage'

const ExceededTriesError = ({ tries }) => (
  <div className={style.container} data-cy="exceeded-tries-error">
    <h2>
      <FormattedMessage id="error.exceededTries.title" />
    </h2>
    <p>
      <FormattedMessage id="error.exceededTries.text.1" values={{ tries }} />
    </p>
    <p>
      <FormattedMessage id="error.exceededTries.text.2" />
    </p>
  </div>
)

ExceededTriesError.propTypes = {
  tries: PropTypes.number.isRequired,
}

export default ExceededTriesError
