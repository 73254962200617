import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import type { Breadcrumb, Primitive, ScopeContext } from '@sentry/types/types'

// eslint-disable-next-line import/no-relative-packages
import pkg from '../../../package.json'
import config from './config'

const logState = {
  initialized: false,
}

const init = () => {
  // prevent double initialization
  if (logState.initialized) {
    return
  }

  const { NODE_ENV } = process.env

  const isProdOrStaging = NODE_ENV === 'production' || NODE_ENV === 'staging'

  if (!isProdOrStaging) {
    console.warn(`Not recording client errors when NODE_ENV is ${NODE_ENV}`)

    return
  }

  if (!config.sentry) {
    console.warn('No sentry config given - not recording client errors.')

    return
  }

  const release = `retorio@${pkg.version}`

  Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.sentry.environment,
    release,
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    replaysOnErrorSampleRate: 0.25,
    tracesSampleRate: config.sentry.environment === 'staging' ? 0.3 : 0.5,
  })

  logState.initialized = true
}

const setUser = ({ id, name, email }) => {
  Sentry.configureScope(scope => {
    scope.setUser({ id, username: name, email })
  })
}

const setTag = (name, value) => {
  Sentry.configureScope(scope => {
    scope.setTag(name, value)
  })
}

const setContext = (name, value) => {
  Sentry.setContext(name, value)
}

const addBreadcrumb = (breadcrumb: Breadcrumb) => {
  Sentry.addBreadcrumb(breadcrumb)
}

interface Context extends ScopeContext {
  message?: string
}
const error = (err: Error, context: Partial<Context> = {}) => {
  console.error(err)
  if (!context) {
    Sentry.captureException(err)

    return
  }

  const { tags, extra, message, fingerprint } = context

  Sentry.withScope(scope => {
    if (tags) {
      Object.entries<Primitive>(tags).forEach(([tag, value]) => scope.setTag(tag, value))
    }

    if (extra) {
      Object.entries(extra).forEach(([name, value]) => Sentry.setExtra(name, value))
    }

    if (message) {
      Sentry.captureMessage(message, 'info')
    }

    if (fingerprint?.length) {
      scope.setFingerprint(fingerprint)
    }

    Sentry.captureException(err)
  })
}

/**
 * Log message
 * @param {String} msg - the message to log
 * @param {'fatal'|'error'|'warning'|'log'|'info'|'debug'|'critical'} level - logging level
 */
const message = (msg: string, level: Sentry.SeverityLevel = 'info') => {
  // eslint-disable-next-line no-console
  console.log(msg)
  Sentry.captureMessage(msg, level)
}

// Prevents webpack build warning
export const Log = {
  init,
  setUser,
  setTag,
  setContext,
  addBreadcrumb,
  error,
  message,
}
