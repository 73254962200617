import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Icon,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Log } from '@retorio/sdk'
import { DeviceContext } from '@retorio/shared-components'
import { colors } from '@retorio/ui'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, {
  forwardRef,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import {
  browserName,
  fullBrowserVersion,
  isAndroid,
  isChrome,
  isDesktop,
  isEdge,
  isFirefox,
  isIOS,
  isMobile,
  isSafari,
  osName,
  osVersion,
} from 'react-device-detect'
import { AiFillChrome, AiFillInfoCircle } from 'react-icons/ai'
import { FaEdge, FaFirefox, FaSafari } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid'

import { minimumBrowserVersions } from '../utils/minimumBrowserVersions'
import { FormattedMessage } from './FormattedMessage'
import { InstructionsModal } from './InstructionsModal'
import { InternetSpeedMeter } from './InternetSpeedMeter'
import MainRecorder, { OnRecorderStateChange, RecorderState } from './MainRecorder'
import NoDeviceErrorModal from './NoDeviceErrorModal'
import Spinner from './Spinner'
import { UserMediaCheck } from './UserMediaCheck'

const BrowserPrerequisite = ({ name, version, icon, link }) => (
  <VStack spacing="5px">
    <Icon as={icon} boxSize={6} color="gray.500" />
    <Text fontSize="18px">{name}</Text>

    <Link href={link} color="blue" isExternal>
      <Text textAlign="center">
        <FormattedMessage id="route.prerequisites.browser.min.version" />
      </Text>
      <Text textAlign="center"> {version} </Text>
    </Link>
  </VStack>
)

const BrowserVersion = () => {
  if (isChrome && (isDesktop || isAndroid)) {
    return (
      <BrowserPrerequisite
        name="Chrome"
        version={minimumBrowserVersions.Chrome}
        icon={AiFillChrome}
        link="https://support.google.com/chrome/answer/95414"
      />
    )
  }

  if (isEdge && isDesktop) {
    return (
      <BrowserPrerequisite
        name="Edge"
        version={minimumBrowserVersions.Edge}
        icon={FaEdge}
        link="https://support.microsoft.com/en-us/topic/update-to-the-new-microsoft-edge-182d0668-e3f0-49da-b8bb-db5675245dc2"
      />
    )
  }

  if (isFirefox && isDesktop) {
    return (
      <BrowserPrerequisite
        name="Firefox"
        version={minimumBrowserVersions.Firefox}
        icon={FaFirefox}
        link="https://support.mozilla.org/en-US/kb/update-firefox-latest-release"
      />
    )
  }

  if (isSafari) {
    return (
      <BrowserPrerequisite
        name="Safari"
        version={minimumBrowserVersions.Safari}
        icon={FaSafari}
        link="https://support.apple.com/en-us/HT204416"
      />
    )
  }

  return (
    <Fragment>
      <Text>
        <FormattedMessage id="route.prerequisites.browser.version.supported" />
      </Text>
      {isDesktop && (
        <VStack spacing="36px">
          <HStack spacing="36px">
            <BrowserPrerequisite
              name="Chrome"
              version={minimumBrowserVersions.Chrome}
              icon={AiFillChrome}
              link=" https://www.google.com/chrome"
            />
            <BrowserPrerequisite
              name="Edge"
              version={minimumBrowserVersions.Edge}
              icon={FaEdge}
              link="https://www.microsoft.com/en-us/edge/download"
            />
          </HStack>
          <HStack spacing="36px">
            <BrowserPrerequisite
              name="Firefox"
              version={minimumBrowserVersions.Firefox}
              icon={FaFirefox}
              link="https://www.mozilla.org/en-GB/firefox/new"
            />
            <BrowserPrerequisite
              name="Safari"
              version={minimumBrowserVersions.Safari}
              icon={FaSafari}
              link="https://support.apple.com/en-us/HT204416"
            />
          </HStack>
        </VStack>
      )}

      {isAndroid && (
        <BrowserPrerequisite
          name="Chrome"
          version={minimumBrowserVersions.Chrome}
          icon={AiFillChrome}
          link=" https://www.google.com/chrome"
        />
      )}
      {isIOS && (
        <BrowserPrerequisite
          name="Safari"
          version={minimumBrowserVersions.Safari}
          icon={FaSafari}
          link="https://support.apple.com/en-us/HT204416"
        />
      )}
    </Fragment>
  )
}

const BrowserLink = ({ browser, product }) => {
  let href
  let buttonText

  switch (browser) {
    case 'Chrome':
      href = 'https://support.google.com/chrome/answer/2693767'
      buttonText = 'Chrome'
      break
    case 'Edge':
      href =
        'https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857#:~:text=In%20Microsoft%20Edge%2C%20select%20Settings,Permissions%2C%20select%20Camera%20or%20Microphone.'

      buttonText = 'Edge'
      break
    case 'Firefox':
      href =
        'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions'

      buttonText = 'Firefox'
      break
    case 'Safari':
      href = 'https://support.apple.com/en-gb/guide/safari/ibrwe2159f50/mac'
      buttonText = 'Safari'
      break
    default:
      href = ''
      buttonText = ''
      break
  }

  return (
    <Link href={href} isExternal>
      <Button
        bgColor={product === 'recruiting' ? colors.startButtonColor : 'blue.500'}
        color="white"
      >
        {buttonText}
      </Button>
    </Link>
  )
}

type RecorderTechCheckProps = {
  nextPage: () => void

  order?: number
  product: 'recruiting' | 'coaching'
  getLoadingState?: (state: boolean) => void
  getHasAccess?: (state: boolean) => void
  getBrowserError?: (state: boolean) => void
}
type RecorderTechCheckHandle = {
  onClick: () => void
}

const RecorderTechCheck = forwardRef<RecorderTechCheckHandle, RecorderTechCheckProps>(
  ({ nextPage, order, product, getLoadingState, getHasAccess, getBrowserError }, ref) => {
    const { setVideoDevice, setAudioDevice, audioDevice, videoDevice } =
      useContext(DeviceContext)

    const [hasAccess, setHasAccess] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [permissionGranted, setPermissionGranted] = useState(false)
    const [hasBrowserError, setHasBrowserError] = useState(false)
    const uuid = useMemo(() => uuidv4(), [])

    useEffect(() => {
      if (getBrowserError) {
        getBrowserError(hasBrowserError)
      }
    }, [getBrowserError, hasBrowserError])

    const setUserMediaDevices = useCallback(
      (videoDevices?: string, audioDevices?: string) => {
        setVideoDevice(videoDevices)
        setAudioDevice(audioDevices)
      },
      [setAudioDevice, setVideoDevice]
    )

    const handleStateChange: OnRecorderStateChange = ({ state }) => {
      if (state === RecorderState.READY_TO_RECORD) {
        setHasAccess(true)
        setPermissionGranted(true)
      }

      if (state === RecorderState.ERROR) {
        setIsLoading(false)
        setHasAccess(false)
      }
    }

    const browserVersionToNumber = version =>
      parseFloat(version.split('.').slice(0, 2).join('.'))

    const checkBrowserVersion = useCallback(() => {
      const browserVersion = browserVersionToNumber(fullBrowserVersion)

      if (isMobile === false) {
        if (
          (isChrome || isEdge) &&
          browserVersion >= browserVersionToNumber(minimumBrowserVersions.Chrome)
        ) {
          return setHasBrowserError(false)
        }

        if (
          isSafari &&
          browserVersion >= browserVersionToNumber(minimumBrowserVersions.Safari)
        ) {
          return setHasBrowserError(false)
        }

        if (
          isFirefox &&
          browserVersion >= browserVersionToNumber(minimumBrowserVersions.Firefox)
        ) {
          return setHasBrowserError(false)
        }
      } else {
        if (
          isIOS &&
          isSafari &&
          browserVersion >= browserVersionToNumber(minimumBrowserVersions.Safari)
        ) {
          return setHasBrowserError(false)
        }

        if (
          isAndroid &&
          isChrome &&
          browserVersion >= browserVersionToNumber(minimumBrowserVersions.Chrome)
        ) {
          return setHasBrowserError(false)
        }
      }

      return setHasBrowserError(true)
    }, [])

    const checkPermissions = useCallback(async () => {
      try {
        setIsLoading(true)
        const constraints: MediaStreamConstraints = {
          audio: {
            deviceId: audioDevice ? { exact: audioDevice } : undefined,
          },
          video: {
            deviceId: videoDevice ? { exact: videoDevice } : undefined,
            width: { max: 640 },
            height: { max: 720 },
          },
        }

        const stream = await navigator.mediaDevices.getUserMedia(constraints)

        setIsLoading(false)
        stream?.getTracks().forEach(track => {
          track.stop()
        })

        nextPage()
      } catch (error) {
        console.error('navigator.mediaDevices error', error)
        setHasAccess(false)
        setIsLoading(false)
      }
    }, [audioDevice, nextPage, videoDevice])

    useImperativeHandle(ref, () => ({
      onClick: () => {
        checkPermissions()
      },
    }))

    useEffect(() => {
      if (getLoadingState) {
        getLoadingState(isLoading)
      }
    }, [isLoading, getLoadingState])

    useEffect(() => {
      checkBrowserVersion()
    }, [checkBrowserVersion])

    useEffect(() => {
      if (getHasAccess) {
        getHasAccess(hasAccess)
      }
    }, [hasAccess, getHasAccess])

    useEffect(() => {
      if (order !== 0) {
        checkPermissions()
      }
    }, [checkPermissions, order])

    useEffect(() => {
      Log.setContext('Browser info', {
        fullBrowserVersion,
        browserName,
        isMobile,
        osName,
        osVersion,
      })

      Log.setTag('fullBrowserVersion', fullBrowserVersion)
      Log.setTag('browserName', browserName)
      Log.setTag('isMobile', isMobile)
      Log.setTag('osName', osName)
      Log.setTag('osVersion', osVersion)
    }, [])

    if (order !== 0 && isLoading && hasAccess) {
      return <Spinner />
    }

    const setHasGCPAccess = hasGCPAccess => {
      if (hasGCPAccess === false) {
        setIsLoading(true)
      }
    }

    const isBrowserSupported = isChrome || isFirefox || isEdge || isSafari

    return hasBrowserError ? (
      <Box
        bgColor="white"
        borderRadius="md"
        p={8}
        w="100%"
        h="100%"
        display="flex"
        alignContent="center"
        justifyContent="center"
      >
        <VStack justifyContent="center" spacing="15px">
          <Icon as={AiFillInfoCircle} boxSize={6} />
          {isBrowserSupported ? (
            <Fragment>
              <Text fontWeight={600} fontSize="18px" textAlign="center">
                <FormattedMessage id="route.prerequisites.browser.version.notSupported" />
              </Text>
              <Text textAlign="center">
                <FormattedMessage id="route.prerequisites.browser.version" />
              </Text>
              <BrowserVersion />
            </Fragment>
          ) : (
            <Fragment>
              <Text fontWeight={600} fontSize="18px" textAlign="center">
                <FormattedMessage id="route.prerequisites.browser.notSupported" />
              </Text>
              <Text textAlign="center">
                <FormattedMessage id="route.prerequisites.browser.notSupported.text" />
                Chrome, Safari, FireFox, Edge
              </Text>
            </Fragment>
          )}
        </VStack>
      </Box>
    ) : (
      <Box bgColor="white" borderRadius="md" p={8} w="100%">
        <NoDeviceErrorModal
          hasAccess={hasAccess}
          isLoading={isLoading}
          product={product}
        />
        <Grid
          w={['300px', '600px']}
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          gap={4}
          display="flex"
          alignItems="stretch"
          flexWrap={['wrap', 'nowrap']}
        >
          <GridItem display="flex" flexDirection="column" justifyContent="space-between">
            <VStack alignItems="flex-start">
              <Text fontSize="18px" fontWeight={600}>
                <FormattedMessage id="route.prerequisites.title" />
              </Text>
              <Text fontSize="14px" fontWeight={400} maxWidth="400px" pb={4}>
                <FormattedMessage id="route.prerequisites.description" />
              </Text>
            </VStack>

            {hasAccess ? (
              <UserMediaCheck
                setUserMediaDevices={setUserMediaDevices}
                permissionGranted={permissionGranted}
              />
            ) : (
              <VStack alignItems="flex-start">
                <Text fontSize="18px" fontWeight={600}>
                  <FormattedMessage id="route.terms.camera.title" />
                </Text>
                <Text fontSize="14px" fontWeight={400} maxWidth="400px">
                  <FormattedMessage id="route.terms.camera.description" />
                </Text>
                {isChrome && <BrowserLink browser="Chrome" product={product} />}
                {isEdge && <BrowserLink browser="Edge" product={product} />}

                {isFirefox && <BrowserLink browser="Firefox" product={product} />}
                {isSafari && <BrowserLink browser="Safari" product={product} />}
              </VStack>
            )}
          </GridItem>
          <GridItem display="flex" alignItems="flex-end">
            <VStack alignItems="flex-start">
              {hasAccess ? (
                <MainRecorder
                  product={product}
                  onStateChange={handleStateChange}
                  width="300px"
                  height="225px"
                  stage="techCheck"
                />
              ) : (
                <Box
                  w="300px"
                  h="225px"
                  backgroundColor="gray.200"
                  display="flex"
                  borderRadius="4px"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  p={2}
                >
                  <Icon as={AiFillInfoCircle} />
                  <Text textAlign="center" fontSize="14px" fontWeight={400}>
                    <FormattedMessage id="route.terms.camera.error" />
                  </Text>
                </Box>
              )}

              <InternetSpeedMeter
                uuid={uuid}
                getHasAccessToGCP={setHasGCPAccess}
                getLoadingState={setIsLoading}
              />
            </VStack>
          </GridItem>
        </Grid>
        <HStack display="flex" justifyContent="center" mt={30} spacing="24px">
          {product === 'coaching' && (
            <Fragment>
              <InstructionsModal product="coaching" isSecondaryButton />
              <Button
                w={['full', 'auto']}
                isDisabled={isLoading}
                onClick={checkPermissions}
                minW="110px"
              >
                {hasAccess ? (
                  <FormattedMessage id="route.prerequisites.startBtn" />
                ) : (
                  <FormattedMessage id="route.terms.camera.refresh" />
                )}
              </Button>
            </Fragment>
          )}
        </HStack>
      </Box>
    )
  }
)

export default RecorderTechCheck
