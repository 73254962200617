// SOURCE: https://gist.github.com/kripod/4434e7cecfdecee160026aee49ea6ee8

import { ComponentProps, ElementType, forwardRef, Ref } from 'react'

interface BoxOwnProps<E extends ElementType = ElementType> {
  as?: E
}

const defaultElement = 'div'

type BoxProps<E extends ElementType> = BoxOwnProps<E> &
  Omit<ComponentProps<E>, keyof BoxOwnProps>

export type PolymorphicComponentProps<E extends ElementType, P> = P & BoxProps<E>

/**
 *
 * @example
 * ```
 * interface CustomComponentOwnProps {
 *   customProp?: number
 *   onClick: boolean
 * }
 *
 * type CustomComponentProps<E extends ElementType> = PolymorphicComponentProps<
 *   E,
 *   CustomComponentOwnProps
 * >
 *
 * export const CustomComponent = <E extends ElementType>({
 *   customProp,
 *   onClick,
 *   ...props
 * }: CustomComponentProps<E>): JSX.Element => {
 *   // TODO: Huge workaround due to the emotion custom JSX
 *   const RRenderAs = RenderAs as any
 *   return <RRenderAs {...props} />
 * }
 * ```
 */
export const RenderAs = forwardRef(
  ({ as, ...restProps }: BoxOwnProps, ref: Ref<Element>) => {
    const Element = as || defaultElement

    return <Element ref={ref} {...restProps} />
  }
) as <E extends ElementType = typeof defaultElement>(props: BoxProps<E>) => JSX.Element
