import { getUserLanguage, Product } from '@retorio/sdk'

import { getLangFromLocale } from '../../utils'
import { SET_LOCALE } from './actions'

export default (
  state = getLangFromLocale(getUserLanguage(Product.RECRUITING)),
  action = {}
) => {
  switch (action.type) {
    case SET_LOCALE:
      return action.locale
    default:
      return state
  }
}
