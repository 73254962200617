import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Button from '../../components/Button'
import Container from '../../components/Container'
import { FormattedMessage } from '../../components/FormattedMessage'
import { InstructionsModal } from '../../components/InstructionsModal'
import RecorderTechCheck from '../../components/RecorderTechCheck'
import { startSession } from '../../store/plugin'
import { SCREENS } from '../../utils/constants'

const PrerequisitesContainer = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [hasAccess, setHasAccess] = useState(true)
  const [browserError, setBrowserError] = useState(false)
  const history = useHistory()

  const techCheckRef = useRef()
  const nextPage = () => {
    setLoading(true)
    dispatch(startSession())
    history.push('/recorder')
  }

  return (
    <Container
      actions={
        browserError === false
          ? [
              <div
                key="instructions"
                style={{ width: ['full', 'auto'], minWidth: '110px' }}
              >
                <InstructionsModal product="recruiting" isSecondaryButton />
              </div>,
              <Button
                key="startBtn"
                disabled={loading}
                variant="primary"
                data-cy="continue-button"
                onClick={() => techCheckRef.current.onClick()}
              >
                {hasAccess ? (
                  <FormattedMessage id="route.prerequisites.startBtn" />
                ) : (
                  <FormattedMessage id="route.terms.camera.refresh" />
                )}
              </Button>,
            ]
          : []
      }
      nextStep={<FormattedMessage id="navigation.step.start" />}
      prevStep={<FormattedMessage id="navigation.step.consent" />}
      data-cy-route={SCREENS.PREREQUISITES}
    >
      <RecorderTechCheck
        ref={techCheckRef}
        product="recruiting"
        nextPage={nextPage}
        getLoadingState={setLoading}
        getHasAccess={setHasAccess}
        getBrowserError={setBrowserError}
        order={0}
      />
    </Container>
  )
}

export default PrerequisitesContainer
