import cx from 'classnames'
import PropTypes from 'prop-types'

import style from './Spinner.scss'

const Spinner = ({ size, className, color }) => {
  const spinnerClassName = cx(className, style.spinner, {
    [style.small]: size === 'small',
    [style.large]: size === 'large',
    [style.xlarge]: size === 'xlarge',
    [style.primary]: color === 'primary',
    [style.white]: color === 'white',
  })

  return (
    <div className={spinnerClassName}>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

Spinner.propTypes = {
  size: PropTypes.oneOf(['small', 'large', 'xlarge']),
  color: PropTypes.oneOf(['white', 'primary', 'black']),
  className: PropTypes.string,
}

Spinner.defaultProps = {
  color: 'black',
}

export default Spinner
