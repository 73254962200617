import { Box, Flex } from '@chakra-ui/react'
import { detect } from 'detect-browser'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { Fragment, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { object, string } from 'yup'

import Button from '../../components/Button'
import Container from '../../components/Container'
import CustomerContent from '../../components/CustomerContent'
import { FormattedMessage, useIntl } from '../../components/FormattedMessage'
import { IdentityForm } from '../../components/IdentityForm'
import PrivacyPolicy from '../../components/PrivacyPolicy'
import SlideCheckbox from '../../components/SlideCheckbox'
import { showDialog } from '../../store/dialog'
import { nextWithIdentity } from '../../store/options/actions'
import {
  hasIdentity as hasIdentitySelector,
  selectOption,
} from '../../store/options/selectors'
import { SCREENS } from '../../utils/constants'
import style from './Intro.scss'

const browser = detect()

const BrowserInfo = () => {
  const validBrowsers = ['firefox', 'chrome']

  if (validBrowsers.includes(browser?.name)) {
    return null
  }

  return (
    <Fragment>
      <br />
      <br />
      <FormattedMessage id="route.intro.browserInfo" />
    </Fragment>
  )
}

const IntroContainer = ({ history }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const formRef = useRef()
  const hasIdentity = useSelector(hasIdentitySelector)
  const identityRequired = useSelector(selectOption('identityRequired'))
  const askForIdentity = identityRequired && !hasIdentity

  const handleNextWithIdentity = async () => {
    try {
      if (askForIdentity) {
        dispatch(nextWithIdentity(formRef.current.values))
      }

      history.push('/consent')
    } catch (err) {
      console.error(err)
    }
  }

  const [privacyConsent, setPrivacyConsent] = useState(false)
  const handlePrivacyPolicyWindow = event => {
    event.preventDefault()
    event.stopPropagation()
    dispatch(showDialog(<PrivacyPolicy />))
  }

  const defaultValues = {
    name: '',
    email: '',
  }

  const validationSchema = object().shape({
    email: string()
      .email(formatMessage({ id: 'organization.settings.general.form.invalid.email' }))
      .required(
        formatMessage({ id: 'organization.settings.general.form.field.required' })
      ),
    name: string().required(),
  })

  return (
    <Container
      actions={
        <Button
          disabled={!privacyConsent}
          variant="primary"
          data-cy="continue-button"
          type="submit"
          onClick={() => {
            if (askForIdentity) {
              formRef.current.handleSubmit()
            } else {
              handleNextWithIdentity()
            }
          }}
        >
          <FormattedMessage id="route.intro.continueBtn" />
        </Button>
      }
      nextStep={<FormattedMessage id="navigation.step.consent" />}
      data-cy-route={SCREENS.INTRO}
    >
      <div className={style.container}>
        <CustomerContent screen={SCREENS.INTRO}>
          <p>
            <FormattedMessage id="route.intro.p.1" />
            <br />
            <br />
            <FormattedMessage id="route.intro.p.2" />
            <br />
            <br />
            <FormattedMessage id="route.intro.p.3" />
            <br />
            <br />
            <FormattedMessage id="route.intro.p.4" />
            <br />
            <br />
            <FormattedMessage id="route.intro.p.5" />
            <br />
            <br />
            <BrowserInfo />
          </p>
        </CustomerContent>
        {askForIdentity && (
          <Fragment>
            <span className={style['form-title']}>
              <FormattedMessage id="forms.identity.title" />
            </span>
            <Formik
              innerRef={formRef}
              initialValues={{
                ...defaultValues,
              }}
              validationSchema={validationSchema}
              validateOnBlur={false}
              onSubmit={handleNextWithIdentity}
            >
              <IdentityForm />
            </Formik>
          </Fragment>
        )}

        <Flex justifyItems="center" mt={8}>
          <Box mr={8}>
            <SlideCheckbox
              checked={privacyConsent}
              onChange={() => setPrivacyConsent(!privacyConsent)}
            />
          </Box>

          <p>
            <FormattedMessage
              id="route.intro.terms"
              values={{
                privacyLink: (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/control-has-associated-label
                  <a onClick={handlePrivacyPolicyWindow} href="#">
                    <FormattedMessage id="privacyLink.text" />
                  </a>
                ),
              }}
            />
          </p>
        </Flex>
      </div>
    </Container>
  )
}

IntroContainer.propTypes = {
  history: PropTypes.object.isRequired,
}

export default IntroContainer
