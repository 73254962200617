// Chakra Theme overrides
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/tag.ts

export const tagTheme = {
  sizes: {
    sm: {
      container: { borderRadius: 'full' },
    },
    md: {
      container: { borderRadius: 'full', px: 2.5 },
    },
    lg: {
      container: { borderRadius: 'full' },
    },
  },
  variants: {
    accepted: {
      container: {
        bg: 'indicator.green-light',
        color: 'indicator.green',
      },
    },
    favorite: {
      container: {
        bg: 'indicator.yellow-light',
        color: 'indicator.yellow',
      },
    },
    rejected: {
      container: {
        bg: 'indicator.red-light',
        color: 'indicator.red',
      },
    },
    big5: {
      container: {
        px: '0.6rem',
        py: '0.3rem',
        bg: 'gray.100',
        color: 'primary-dark',
      },
    },
    dimension: {
      container: {
        px: '0.4rem',
        py: '0.7rem',
        bg: 'secondary',
        color: 'primary-dark',
      },
    },
    'indicator-red': {
      container: {
        bg: 'indicator.red-extra-light',
        color: 'indicator.red-dark',
      },
    },
    'indicator-yellow': {
      container: {
        bg: 'indicator.yellow-extra-light',
        color: 'indicator.yellow-dark',
      },
    },
    'indicator-orange': {
      container: {
        bg: 'indicator.orange-extra-light',
        color: 'indicator.orange-dark',
      },
    },
    'indicator-green': {
      container: {
        bg: 'indicator.green-extra-light',
        color: 'indicator.green-dark',
      },
    },
    'indicator-caribbean-green': {
      container: {
        bg: 'indicator.caribbean-green-extra-light',
        color: 'indicator.caribbean-green-dark',
      },
    },
    'value-indicator-low': {
      container: {
        bg: 'blue.150',
        color: 'blue.700',
      },
    },
    'value-indicator-mediumLow': {
      container: {
        bg: 'blue.100',
        color: 'blue.700',
      },
    },
    'value-indicator-medium': {
      container: {
        bg: 'blue.50',
        color: 'blue.700',
      },
    },
    'value-indicator-mediumHigh': {
      container: {
        bg: 'blue.100',
        color: 'blue.700',
      },
    },
    'value-indicator-high': {
      container: {
        bg: 'blue.150',
        color: 'blue.700',
      },
    },
    primary: {
      container: {
        bg: 'primary',
        color: 'white',
      },
    },
    secondary: {
      container: {
        bg: 'secondary',
        color: 'primary-dark',
      },
    },
    'secondary-dark': {
      container: {
        bg: 'secondary-dark',
        color: 'primary-dark',
      },
    },
  },
}
