import PropTypes from 'prop-types'

import style from './style.scss'

const VideoBar = ({ duration, currentTime, onChange = null }) => (
  <input
    type="range"
    min="0"
    step="0.01"
    max={duration}
    value={currentTime}
    onChange={onChange}
    className={style['process-bar']}
  />
)

// I don't know yet what kind of value `currentTime` and `duration`
// get (float, number, Date object?)
// TODO: Fix type
VideoBar.propTypes = {
  currentTime: PropTypes.any.isRequired,
  duration: PropTypes.any.isRequired,
  onChange: PropTypes.func,
}

export default VideoBar
