import { Box, Center } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useAppContext } from '../../AppContext'
import Button from '../../components/Button'
import ConfirmationDialog from '../../components/ConfirmationDialog'
import Container from '../../components/Container'
import CustomerContent from '../../components/CustomerContent'
import { FormattedMessage } from '../../components/FormattedMessage'
import Spinner from '../../components/Spinner'
import { showDialog } from '../../store/dialog'
import { sendConsent } from '../../store/plugin'
import { selectPlugin } from '../../store/plugin/selectors'
import { selectAllRecordings } from '../../store/recordings/selectors'
import { SCREENS } from '../../utils/constants'
import style from './Submit.scss'

const Submit = () => {
  const {
    appEvents: { onFlowFinished },
  } = useAppContext()

  const dispatch = useDispatch()
  const history = useHistory()
  const recordings = useSelector(selectAllRecordings)
  const { videoProcessingConsent } = useSelector(selectPlugin)

  const [savingConsent, setSavingConsent] = useState(false)

  useEffect(() => {
    onFlowFinished({ recordings })
  }, [onFlowFinished, recordings])

  const handleSubmit = async () => {
    // The recording session is at this point already submitted. So instead of
    // uploading files here, we send the consent to the backend to proceed with
    // the analysis and the sharing of the results.
    setSavingConsent(true)
    await dispatch(sendConsent(videoProcessingConsent, true))
    setSavingConsent(false)
    history.push('/done')
  }

  const handleDelete = useCallback(() => {
    // Instead of directly deleting the session data, we have a cronjob in
    // the backend that finds and removes sessions without company consent.
    // Delete === not sending consent.
    dispatch(
      showDialog(
        <ConfirmationDialog onYes={() => history.push('/done')}>
          <FormattedMessage id="route.submit.cancel.text" />
        </ConfirmationDialog>
      )
    )
  }, [dispatch])

  return (
    <Container
      prevStep={<FormattedMessage id="navigation.step.recording" />}
      nextStep={<FormattedMessage id="navigation.step.finish" />}
      actions={[
        <Button key="backBtn" onClick={handleDelete} disabled={savingConsent}>
          <FormattedMessage id="route.submit.cancel" />
        </Button>,
        <Button
          key="submitBtn"
          variant="primary"
          onClick={handleSubmit}
          disabled={savingConsent}
        >
          <FormattedMessage id="route.submit.confirm" />
        </Button>,
      ]}
    >
      <CustomerContent screen={SCREENS.DONE} className={style.container}>
        {savingConsent ? (
          <Center h="100%">
            <Spinner color="primary" size="xlarge" />
          </Center>
        ) : (
          <Box m="auto">
            <h3 style={{ textAlign: 'center', marginTop: 0, marginBottom: ' .5rem' }}>
              <FormattedMessage id="route.submit.title" />
            </h3>
            <p style={{ textAlign: 'center' }}>
              <FormattedMessage id="route.submit.text" />
            </p>
          </Box>
        )}
      </CustomerContent>
    </Container>
  )
}

export default Submit
