import { FETCH_PLUGIN_CONFIG_SUCCESS } from '../plugin/actions'
import { SET_OPTIONS } from './actions'

const initialState = {
  meta: {},
  tags: [],
  pluginId: null,
}

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_OPTIONS:
      return { ...state, ...payload }
    case FETCH_PLUGIN_CONFIG_SUCCESS:
      return {
        ...state,
        tags: [
          ...new Set(
            []
              .concat(state.tags ? state.tags : [])
              .concat(payload.tags ? payload.tags : [])
          ),
        ],
      }
    default:
      return state
  }
}
