import { ref, string } from 'yup'

export const passwordSchemaValidator = {
  password: string()
    .min(8, 'signup.form.field.password.error.length')
    .matches(/(?=.*[a-z])/, 'signup.form.field.password.error.lowercase')
    .matches(/(?=.*[A-Z])/, 'signup.form.field.password.error.uppercase')
    .matches(/(?=.*\d)/gm, 'signup.form.field.password.error.digit')
    .matches(/(?=.*[\W_])/gm, 'signup.form.field.password.error.symbol')
    .test(
      'containsEmail',
      'signup.form.field.password.error.email',
      (value, ctx) =>
        (ctx.parent.email &&
          !value?.toLowerCase().includes(ctx.parent.email.split('@')[0].toLowerCase())) ??
        true
    )
    .test(
      'containsUsername',
      'signup.form.field.password.error.firstname',
      (value, ctx) =>
        (ctx.parent.firstName &&
          (!value
            ?.toLowerCase()
            .includes(ctx.parent.firstName.toLowerCase()) as boolean)) ??
        true
    )

    .required('signup.form.field.password.error.required'),
}

export const passwordSchemaValidatorWithConfirm = {
  ...passwordSchemaValidator,
  confirmPassword: string()
    .oneOf([ref('password')], 'signup.form.field.confirmPassword.error.nomatch')
    .required('signup.form.field.confirmPassword.error.nomatch'),
}
