import { Log } from './log'

export enum LanguageLabels {
  ENGLISH = 'English',
  GERMAN = 'Deutsch',
  SPANISH = 'Español',
  FRENCH = 'Français',
  ITALIAN = 'Italiano',
  PORTUGUESE = 'Português',
}

export enum Languages {
  ENGLISH = 'en-US',
  SPANISH = 'es-ES',
  FRENCH = 'fr-FR',
  GERMAN = 'de-DE',
  ITALIAN = 'it-IT',
  PORTUGUESE = 'pt-PT',
}

export enum LocaleLanguage {
  'en' = 'en-US',
  'fr' = 'fr-FR',
  'de' = 'de-DE',
  'es' = 'es-ES',
  'it' = 'it-IT',
  'pt' = 'pt-PT',
}

export const RECRUITING_LANGUAGES_WITH_LABELS = [
  { label: LanguageLabels.GERMAN, value: Languages.GERMAN },
  { label: LanguageLabels.ENGLISH, value: Languages.ENGLISH },
  { label: LanguageLabels.SPANISH, value: Languages.SPANISH },
  { label: LanguageLabels.FRENCH, value: Languages.FRENCH },
  { label: LanguageLabels.ITALIAN, value: Languages.ITALIAN },
  { label: LanguageLabels.PORTUGUESE, value: Languages.PORTUGUESE },
]

export const RECRUITING_LANGUAGES = RECRUITING_LANGUAGES_WITH_LABELS.map(l => l.value)

export const COACHING_LANGUAGES_WITH_LABEL = [
  { label: LanguageLabels.GERMAN, value: Languages.GERMAN },
  { label: LanguageLabels.ENGLISH, value: Languages.ENGLISH },
  { label: LanguageLabels.SPANISH, value: Languages.SPANISH },
  { label: LanguageLabels.FRENCH, value: Languages.FRENCH },
  { label: LanguageLabels.ITALIAN, value: Languages.ITALIAN },
  { label: LanguageLabels.PORTUGUESE, value: Languages.PORTUGUESE },
]

export const RECORDER_LANGUAGES_WITH_LABEL = COACHING_LANGUAGES_WITH_LABEL

export const COACHING_LANGUAGES = COACHING_LANGUAGES_WITH_LABEL.map(l => l.value)

export enum Product {
  RECRUITING,
  COACHING,
}

const getLanguages = (product: Product) => {
  switch (product) {
    case Product.RECRUITING:
      return RECRUITING_LANGUAGES
    case Product.COACHING:
      return COACHING_LANGUAGES
    default:
      throw new Error(`invalid product: ${product}`)
  }
}

// Detecting browser language
// https://stackoverflow.com/questions/1043339/javascript-for-detecting-browser-language-preference
//
// So the basic issue when trying to detect the user language, is that the browser language
// is not necessarily the language the user can read or speak. It's the language the browser
// displayed in.
//
// To get the actual user language we have to get the value set in the regional language
// options in the respective browser. And that is quite a difference if we want to support
// IE 11 for example.
export const detectBrowserLanguage = (product: Product) => {
  // `navigator.userLanguage` is IE only
  // In Other browsers, the user spoken language is generally the language
  // which is at the top of the `languages` list.
  const userLanguage =
    // eslint-disable-next-line dot-notation
    navigator['userLanguage'] || navigator.languages?.[0] || navigator.language

  if (userLanguage) {
    const userLanguageCode = userLanguage.split('-')[0]
    const availableLanguage = getLanguages(product).find(
      locale => locale.split('-')[0] === userLanguageCode
    )

    if (availableLanguage) {
      return availableLanguage
    }
  }

  return Languages.ENGLISH
}

export const setUserLanguage = val => {
  try {
    localStorage.setItem('lang', val)
    Log.setTag('locale', val)
  } catch (error) {
    console.error(error)
  }
}

// In our apps, user can set their language. This value is stored in `lang` inside the
// localStorage. If we cannot find a key `lang` we fallback to the language the user set
// in his browser.
export const getUserLanguage = (product: Product) => {
  try {
    return localStorage.getItem('lang') || detectBrowserLanguage(product)
  } catch (error) {
    console.error(error)

    return detectBrowserLanguage(product)
  }
}
