// Chakra Theme overrides
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/link.ts

const contentVariantStyle = {
  cursor: 'pointer',
  textDecoration: 'none',
  color: 'primary',
  _hover: {
    textDecoration: 'underline',
    color: 'primary-dark',
  },
  _active: {
    boxShadow: 'outline',
    color: 'blue.800',
  },
}

export const linkTheme = {
  baseStyle: {
    transition: 'none',
  },
  variants: {
    content: contentVariantStyle,
    'content-primary-dark': {
      ...contentVariantStyle,
      color: 'primary-dark',
      _hover: {
        color: 'blue.800',
      },
    },
  },
}
