import { useSelector } from 'react-redux'

import { selectPlugin } from '../store/plugin/selectors'
import { useIntl } from './FormattedMessage'
import style from './PrivacyPolicy.scss'

const PrivacyPolicy = () => {
  const { formatWithInterpolation } = useIntl()
  const {
    organization: { privacyPolicy },
  } = useSelector(selectPlugin)

  return (
    <div
      className={style.container}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: formatWithInterpolation(privacyPolicy),
      }}
    />
  )
}

export default PrivacyPolicy
