import { Button as ChakraButton } from '@chakra-ui/react'
import { colors } from '@retorio/ui'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { useAppContext } from '../AppContext'
import { showDialog } from '../store/dialog'
import { selectActiveRecording } from '../store/recordings/selectors'
import Button from './Button'
import style from './Container.scss'
import { FormattedMessage } from './FormattedMessage'
import { LanguageSwitch } from './LanguageSwitch'
import PrivacyPolicy from './PrivacyPolicy'

const Container = ({
  actions,
  children,
  className,
  nextStep,
  prevStep,
  title,
  ...props
}) => {
  const { isMobile } = useAppContext()
  const dispatch = useDispatch()
  const activeRecording = useSelector(selectActiveRecording)
  const showLanguageSwitch =
    !activeRecording || (activeRecording && !activeRecording.running)

  const handlePrivacyPolicyWindow = event => {
    event.preventDefault()
    event.stopPropagation()
    dispatch(showDialog(<PrivacyPolicy />))
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={cx(style.container, className)} data-cy="container" {...props}>
      <div className={style['logo-container']}>
        <span className={style['powered-by']}>
          <FormattedMessage id="container.privacy.powered" />
        </span>
        <div className={style.logo} />
      </div>
      {title && <h1 className={style.title}>{title}</h1>}
      <div className={style.content}>{children}</div>
      <div className={style.globals}>
        <ChakraButton
          colorScheme={colors.recorderLanguageIconColor}
          onClick={handlePrivacyPolicyWindow}
          variant="ghost"
        >
          <FormattedMessage id="container.privacy.label" />
        </ChakraButton>
        {showLanguageSwitch && <LanguageSwitch />}
      </div>
      {isMobile && actions && (
        <div
          className={style.actions}
          style={{ marginTop: '1rem', justifyContent: 'space-evenly' }}
        >
          {actions}
        </div>
      )}

      <div className={style.navigation}>
        <div className={style['prev-step']}>
          {prevStep && (
            <Button variant="light" size="sm" disabled>
              {prevStep}
            </Button>
          )}
        </div>
        {!isMobile && actions && <div className={style.actions}>{actions}</div>}
        <div className={style['next-step']}>
          {nextStep && (
            <Button variant="light" size="sm" disabled>
              <FormattedMessage id="navigation.next" values={{ content: nextStep }} />
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

Container.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.any,
  nextStep: PropTypes.node,
  prevStep: PropTypes.node,
  title: PropTypes.node,
}

export default Container
