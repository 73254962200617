import { Box, useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react'
import { ReactNode, useMemo } from 'react'

/**
 * To ensure consistency between all toasts, we create our own `useToast`
 * and apply default options to `useChakraToast`. For an easy to use API we
 * provide functions for each status-type.
 */
export const useToast = () => {
  const toast = useChakraToast({ isClosable: true, position: 'top-right' })

  return useMemo(() => {
    const makeOptions = ({ description, ...rest }: UseToastOptions) => ({
      description: <Box maxW="215px">{description}</Box>,
      ...rest,
    })

    const showError = (description: ReactNode) => {
      toast(makeOptions({ description, status: 'error' }))
    }

    const showInfo = (description: ReactNode) => {
      toast(makeOptions({ description, status: 'info' }))
    }

    const showSuccess = (description: ReactNode) => {
      toast(makeOptions({ description, status: 'success' }))
    }

    return {
      showSuccess,
      showInfo,
      showError,
    }
  }, [toast])
}
