import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Container from '../../components/Container'
import FirstTimeInfo from '../../components/FirstTimeInfo'
import Player from '../../components/Player'
import { showDialog } from '../../store/dialog'
import {
  selectActiveRecording,
  selectActiveRecordingIndex,
} from '../../store/recordings/selectors'

const PlayerContainer = () => {
  const dispatch = useDispatch()
  const { attempts } = useSelector(selectActiveRecording)
  const recordingIndex = useSelector(selectActiveRecordingIndex)

  useEffect(() => {
    if (attempts === 1 && recordingIndex === 0) {
      dispatch(showDialog(<FirstTimeInfo />))
    }
  }, [attempts, dispatch, recordingIndex])

  return (
    <Container>
      <Player />
    </Container>
  )
}

export default PlayerContainer
