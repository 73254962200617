import config from './config'

const request = async (endpoint, params) => {
  const { responseType, ...fetchParams } = params
  const fetchOptions = {
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    ...fetchParams,
  }

  // do not stringify blob bodies
  if (!(fetchOptions.body instanceof Blob)) {
    fetchOptions.body = JSON.stringify(fetchOptions.body)
  }

  const res = await fetch(`${config.apiBaseUrl}${endpoint}`, fetchOptions)

  if (res.status === 204) {
    return null
  }

  let response = null

  switch (responseType) {
    case 'text':
      response = await res.text()
      break
    default:
      response = await res.json()
  }

  if (res.ok) {
    return response
  }

  if (response != null) {
    // usually error message is in this json
    // our backend returns json for erroneous cases as well
    throw response
  } else {
    throw Error(res)
  }
}

export default {
  analysis: {
    warmUp: () => request('/analysis/warm_up', { method: 'GET' }),
    create: () =>
      request('/analysis', {
        method: 'POST',
      }),
    uploadChunk: (analysisId, start, end, blob, type) =>
      request(`/analysis/${analysisId}/chunk`, {
        method: 'POST',
        headers: {
          'Retorio-Start': start,
          'Retorio-End': end,
          'Content-Type': type,
        },
        body: blob,
      }),
  },
  plugins: {
    get: id => request(`/plugins/${id}`, { method: 'GET' }),
    getPrivacy: id =>
      request(`/plugins/${id}/privacy`, { method: 'GET', responseType: 'text' }),
  },
  recordings: {
    create: body => request('/recordings', { method: 'POST', body }),
    sendConsent: (id, body) =>
      request(`/recordings/${id}/consent`, { method: 'POST', body }),
    getHasCompletedSession: recordingRequestId =>
      request(`/recordings/hasCompletedSession/${recordingRequestId}`, { method: 'GET' }),
  },
}
