import PropTypes from 'prop-types'

import style from './ControlBar.scss'
import PlayButton from './play-button'
import VideoBar from './video-bar'

const ControlBar = ({
  actions,
  currentTime,
  duration,
  onPause,
  onPlay,
  onSeek,
  playing,
}) => (
  <div className={style.container}>
    <PlayButton onPlay={onPlay} onPause={onPause} playing={playing} />
    <VideoBar
      duration={duration}
      currentTime={currentTime}
      onChange={onSeek ? evt => onSeek(parseInt(evt.target.value, 10)) : undefined}
    />
    {actions}
  </div>
)

ControlBar.propTypes = {
  actions: PropTypes.node,
  currentTime: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  onPause: PropTypes.func.isRequired,
  onPlay: PropTypes.func.isRequired,
  onSeek: PropTypes.func,
  playing: PropTypes.bool,
}

export default ControlBar
