import { Box } from '@chakra-ui/react'
import { Log } from '@retorio/sdk'
import config from '@retorio/sdk/src/config'
import { DeviceContext } from '@retorio/shared-components'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, {
  Dispatch,
  forwardRef,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { MediaStreamRecorder, RecordRTCPromisesHandler } from 'recordrtc'

const { mediaDevices, userAgent } = navigator

export enum RecorderState {
  INITIALIZING = 'init',
  READY_TO_RECORD = 'ready_record',
  RECORDING = 'recording',
  READY_TO_UPLOAD = 'ready_upload',
  UPLOADING = 'uploading',
  UPLOAD_SUCCESS = 'upload_success',
  ERROR = 'error',
}

export const enum RecorderError {
  noAccess = 'noAccess',
  fatalError = 'fatalError',
  uploadVideoError = 'uploadVideoError',
  audioError = 'audioError',
  videoError = 'videoError',
  videoAndAudioError = 'videoAndAudioError',
  orientationError = 'orientationError',
  sessionCreationError = 'sessionCreationError',
}

export const checkIsRecorderState = (state: RecorderState) =>
  [RecorderState.READY_TO_RECORD, RecorderState.RECORDING].includes(state)

export const checkIsPlayerState = (state: RecorderState) =>
  [RecorderState.READY_TO_UPLOAD, RecorderState.UPLOADING].includes(state)

const AVAILABLE_TRANSITIONS = {
  [RecorderState.INITIALIZING]: [RecorderState.READY_TO_RECORD, RecorderState.ERROR],
  [RecorderState.READY_TO_RECORD]: [
    RecorderState.RECORDING,
    RecorderState.INITIALIZING,
    RecorderState.ERROR,
  ],
  [RecorderState.RECORDING]: [
    RecorderState.READY_TO_UPLOAD,
    RecorderState.INITIALIZING,
    RecorderState.ERROR,
  ],
  [RecorderState.READY_TO_UPLOAD]: [
    RecorderState.UPLOADING,
    RecorderState.INITIALIZING,
    RecorderState.ERROR,
  ],
  [RecorderState.UPLOADING]: [RecorderState.UPLOAD_SUCCESS, RecorderState.ERROR],
  [RecorderState.UPLOAD_SUCCESS]: [RecorderState.INITIALIZING],
  [RecorderState.ERROR]: [RecorderState.INITIALIZING],
}

type StatusWithError = {
  state: RecorderState.ERROR
  error: RecorderError
}

type StatusWithoutError = {
  state: Exclude<RecorderState, RecorderState.ERROR>
}

export type RecorderStatus = StatusWithError | StatusWithoutError

export type OnRecorderStateChange = (payload: RecorderStatus) => void

const createTransitionFunction = (
  currentState: RecorderState,
  setState: Dispatch<SetStateAction<RecorderState>>,
  onStateChange?: OnRecorderStateChange
) => {
  const transition = (payload: RecorderStatus): boolean => {
    const { state } = payload
    const available = AVAILABLE_TRANSITIONS[currentState] || []

    if (!available.includes(state)) {
      // In order to not spam sentry, only log this error if the states are not the same
      if (currentState !== state) {
        Log.error(
          new Error(
            `MainRecorder: Unable to transition from state "${currentState}" to state "${state}"`
          )
        )
      }

      return false
    }

    setState(state)
    onStateChange?.(payload)
    // eslint-disable-next-line no-console
    console.log(`recorder state: ${currentState} -> ${state}`)
    Log.addBreadcrumb({
      category: 'state_transition',
      message: `recorder state: ${currentState} -> ${state}`,
    })

    return true
  }

  return transition
}

declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext
  }
}

type MainRecorderProps = {
  width: string
  height: string
  product: 'recruiting' | 'coaching'
  stage?: 'techCheck' | 'recording'
  payloadId?: string
  onStateChange?: OnRecorderStateChange
  setElapsedTimeCallback?: (elapsedTime: number) => void
  setVideoBlobCallback?: (blob: Blob) => void
  uploadMedia?: (videoBlob: Blob) => Promise<string>
}

export type MainRecorderHandle = {
  startRecording: () => Promise<void>
  stopRecording: () => Promise<void>
  reset: () => void
  saveRecording: () => Promise<void>
}

const MainRecorder = forwardRef<MainRecorderHandle, MainRecorderProps>(
  (
    {
      product,
      stage = 'recording',
      onStateChange,
      setElapsedTimeCallback,
      setVideoBlobCallback,
      uploadMedia,
      width,
      height,
      payloadId,
    },
    ref
  ) => {
    const [recorder, setRecorder] = useState<MediaStreamRecorder | null>(null)
    const [elapsedTime, setElapsedTime] = useState(0)
    const [videoBlob, setVideoBlob] = useState<Blob | null>(null)
    // do not use _setState, use transitionTo
    const [state, _setState] = useState<RecorderState>(RecorderState.INITIALIZING)
    const [videoTracks, setVideoTracks] = useState<string | undefined>()
    const [audioTracks, setAudioTracks] = useState<string | undefined>()
    const videoRef = useRef<HTMLVideoElement>(null)
    const timerRef = useRef<number>()
    const transitionTo = useRef(createTransitionFunction(state, _setState, onStateChange))
    const [streamRecorder, setStreamRecorder] = useState<MediaStream | null>(null)
    const { videoDevice, audioDevice } = useContext(DeviceContext)

    transitionTo.current = createTransitionFunction(state, _setState, onStateChange)

    /* helper functions */
    const startTimer = () => {
      setElapsedTime(0) // reset

      timerRef.current = window.setInterval(() => {
        setElapsedTime(c => c + 1)
      }, 1000)
    }

    // Add current recorder state to sentry context
    useEffect(() => {
      Log.setContext('Recorder State', {
        state,
        audioTracks,
        videoTracks,
        product,
        payloadId,
      })

      // Context is not searchable in sentry, so we add product and recorderState as a tag
      Log.setTag('product', product)
      Log.setTag('recorderState', state)
    }, [state, audioTracks, videoTracks, product, payloadId])

    const stopTimer = useCallback(() => {
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    }, [timerRef])

    const updateVideoBlob = useCallback(
      (blob: Blob | null) => {
        setVideoBlob(blob)
        if (blob && setVideoBlobCallback) {
          setVideoBlobCallback(blob)
        }
      },
      [setVideoBlobCallback]
    )

    const resetState = useCallback(async () => {
      updateVideoBlob(null)
      stopTimer()
      setElapsedTime(0)

      if (recorder) {
        await recorder.reset()
      }
    }, [updateVideoBlob, stopTimer, recorder])

    useEffect(() => {
      if (setElapsedTimeCallback) {
        setElapsedTimeCallback(elapsedTime)
      }
    }, [elapsedTime, setElapsedTimeCallback, state])

    /* State flow */
    const initRecorder = useCallback(async () => {
      if (!recorder) {
        const constraints: MediaStreamConstraints = {
          audio: {
            deviceId: audioDevice ? { exact: audioDevice } : undefined,
          },
          video: {
            deviceId: videoDevice ? { exact: videoDevice } : undefined,
            width: { max: 640 },
            height: { max: 720 },
          },
        }

        const stream = await mediaDevices.getUserMedia(constraints)

        setVideoTracks(stream.getVideoTracks()[0]?.label)
        setAudioTracks(stream.getAudioTracks()[0]?.label)
        if (videoRef.current && stream?.getAudioTracks()[0]?.muted === false) {
          videoRef.current.srcObject = stream
          setStreamRecorder(videoRef.current.srcObject)
          setRecorder(
            new RecordRTCPromisesHandler(videoRef.current.srcObject, {
              type: 'video',
              mimeType: 'video/webm;codecs=h264',
              frameRate: 24,
            })
          )
        } else {
          throw new Error('videoRef is not defined!')
        }
      }
    }, [videoDevice, audioDevice, recorder])

    useEffect(() => {
      setRecorder(null)
      if (videoDevice && audioDevice) {
        transitionTo.current({ state: RecorderState.INITIALIZING })
      }
    }, [audioDevice, videoDevice])

    useEffect(() => {
      if (state === RecorderState.INITIALIZING) {
        const start = async () => {
          try {
            await resetState()
            await initRecorder()
            transitionTo.current({ state: RecorderState.READY_TO_RECORD })
          } catch (e) {
            // TODO: retry init, set a max retry limit
            Log.error(new Error(`init error ${e}`), { extra: { cause: e } })
            transitionTo.current({
              state: RecorderState.ERROR,
              error: RecorderError.noAccess,
            })
          }
        }

        start()
      }

      return () => {
        if (stage === 'techCheck') {
          streamRecorder?.getTracks().forEach(track => {
            track.stop()
          })
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, stage])

    const isRecorderRefBusy = useRef(false)
    const checkAndWarnIfRecorderRefBusy = (functionName: string) => {
      if (isRecorderRefBusy.current) {
        return console.warn(
          `"recorderRef.${functionName}" is being called while another call to "recorderRef" is in progress. Consecutive calls are ignored. This error means you're probably doing something wrong while using "recorderRef".`
        )
      }

      return isRecorderRefBusy.current
    }

    useImperativeHandle(
      ref,
      () => ({
        startRecording: async () => {
          if (checkAndWarnIfRecorderRefBusy('startRecording')) {
            return
          }

          if (state === RecorderState.READY_TO_RECORD) {
            try {
              isRecorderRefBusy.current = true
              await recorder.startRecording()
              window.addEventListener(
                'orientationchange',
                async () => {
                  await recorder.stopRecording()
                  transitionTo.current({
                    state: RecorderState.ERROR,
                    error: RecorderError.orientationError,
                  })
                },
                false
              )

              startTimer()
              transitionTo.current({ state: RecorderState.RECORDING })
            } catch (e) {
              Log.error(new Error(`startRecording error ${e}`), { extra: { cause: e } })
              transitionTo.current({
                state: RecorderState.ERROR,
                error: RecorderError.fatalError,
              })
            } finally {
              isRecorderRefBusy.current = false
            }
          }
        },

        stopRecording: async () => {
          if (checkAndWarnIfRecorderRefBusy('stopRecording')) {
            return
          }

          if (state === RecorderState.RECORDING) {
            if (recorder) {
              try {
                isRecorderRefBusy.current = true
                await recorder.stopRecording()
                stopTimer()
                const blob: Blob = await recorder.getBlob()

                updateVideoBlob(blob)
                streamRecorder?.getTracks().forEach(track => {
                  track.stop()
                })

                setRecorder(null)

                if (videoRef?.current) {
                  videoRef.current.srcObject = null
                }

                transitionTo.current({ state: RecorderState.READY_TO_UPLOAD })
              } catch (e) {
                Log.error(new Error(`stopRecording error ${e}`), { extra: { cause: e } })
                transitionTo.current({
                  state: RecorderState.ERROR,
                  error: RecorderError.fatalError,
                })
              } finally {
                isRecorderRefBusy.current = false
              }
            } else {
              Log.error(new Error('stopRecording error: recorder is not defined!'))
              transitionTo.current({
                state: RecorderState.ERROR,
                error: RecorderError.fatalError,
              })
            }
          }
        },

        reset: () => {
          if (checkAndWarnIfRecorderRefBusy('stopRecording')) {
            return
          }

          transitionTo.current({ state: RecorderState.INITIALIZING })
        },

        saveRecording: async () => {
          if (checkAndWarnIfRecorderRefBusy('saveRecording')) {
            return
          }

          if (state === RecorderState.READY_TO_UPLOAD) {
            if (videoBlob && payloadId && product && uploadMedia) {
              try {
                transitionTo.current({ state: RecorderState.UPLOADING })
                isRecorderRefBusy.current = true
                const videoGSUrl = await uploadMedia(videoBlob)

                Log.setTag('videoGSUrl', videoGSUrl)

                await fetch(`${config.apiBaseUrl}/processVideo`, {
                  method: 'post',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({
                    videoUrl: videoGSUrl,
                    payloadId,
                    product,
                    mirrorVideo: true,
                    userMediaData: [videoTracks, audioTracks],
                    userNavigatorData: userAgent,
                  }),
                })

                transitionTo.current({ state: RecorderState.UPLOAD_SUCCESS })
              } catch (e) {
                // TODO :implement retry logic for 500 error case
                Log.error(new Error(`VideoUploadError ${e}`), { extra: { cause: e } })
                transitionTo.current({
                  state: RecorderState.ERROR,
                  error: RecorderError.uploadVideoError,
                })
              } finally {
                isRecorderRefBusy.current = false
              }
            } else {
              Log.error(new Error('saveRecording error: no video blob!'))
              transitionTo.current({
                state: RecorderState.ERROR,
                error: RecorderError.fatalError,
              })
            }
          }
        },
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        state,
        recorder,
        stopTimer,
        updateVideoBlob,
        videoBlob,
        payloadId,
        product,
        uploadMedia,
        videoTracks,
        audioTracks,
      ]
    )

    return (
      <Box
        style={{
          width,
          height,
        }}
      >
        <video
          playsInline
          muted
          preload="auto"
          ref={videoRef}
          style={{
            width: ' 100%',
            height: '100%',
            objectFit: 'cover',
            transform: 'scaleX(-1)',
            borderRadius: stage === 'techCheck' ? '0.25rem' : '',
          }}
          autoPlay
        />
      </Box>
    )
  }
)

export default MainRecorder
