/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Calls all the provided functions. Ignores the returned value.
 */

export const callAll =
  <Fn extends (...args: any) => any>(...fns: Array<Fn | undefined>) =>
  (arg: Parameters<Fn>[0]): void =>
    fns.forEach(fn => fn?.(arg))

/**
 * A function that returns void.
 */

export const constVoid = () => {}

/* eslint-disable @typescript-eslint/no-explicit-any */
type Curried<A extends Array<any>, R> = <P extends Partial<A>>(
  ...args: P
) => P extends A
  ? R
  : A extends [...SameLength<P>, ...infer S]
    ? S extends Array<any>
      ? Curried<S, R>
      : never
    : never

type SameLength<T extends Array<any>> = Extract<{ [K in keyof T]: any }, Array<any>>

/**
 * Returns a curried equivalent of the provided function.
 */
export function curry<A extends Array<any>, R>(fn: (...args: A) => R): Curried<A, R> {
  return (...args: Array<any>): any =>
    args.length >= fn.length
      ? fn(...(args as any))
      : curry((fn as any).bind(undefined, ...args))
}

/**
 * Create a promise that rejects in <ms> milliseconds
 */
export const promiseTimeout = <T>(ms: number, promise: Promise<T>) => {
  const timeout = new Promise<T>((_resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id)
      reject(new Error(`Timed out in ${ms}ms.`))
    }, ms)
  })

  // Returns a race between our timeout and the passed in promise
  return Promise.race([promise, timeout])
}
/* eslint-enable @typescript-eslint/no-explicit-any */
