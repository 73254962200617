import {
  FETCH_PLUGIN_CONFIG_FAILURE,
  FETCH_PLUGIN_CONFIG_NOT_AVAILABLE,
  FETCH_PLUGIN_CONFIG_START,
  FETCH_PLUGIN_CONFIG_SUCCESS,
  PLUGIN_SESSION_CACHE_VIDEO_PROCESSING_CONSENT,
  PLUGIN_SESSION_CONSENT_SENT,
  PLUGIN_SESSION_FINISHED,
} from './actions'

export default (state = {}, { type, payload } = {}) => {
  switch (type) {
    case FETCH_PLUGIN_CONFIG_SUCCESS:
      return { ...payload, hasLoaded: true }
    case FETCH_PLUGIN_CONFIG_NOT_AVAILABLE:
      return { hasLoaded: true, isNotActive: true }
    case PLUGIN_SESSION_FINISHED:
      return {
        ...state,
        recordingSessionId: payload,
      }
    case FETCH_PLUGIN_CONFIG_FAILURE:
      return { hasLoaded: true }
    case PLUGIN_SESSION_CACHE_VIDEO_PROCESSING_CONSENT:
      return { ...state, videoProcessingConsent: payload }
    case PLUGIN_SESSION_CONSENT_SENT:
      return { ...state, isSessionSubmitted: true }
    case FETCH_PLUGIN_CONFIG_START:
    default:
      return state
  }
}
