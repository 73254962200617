const sanitizeArray = tagsArray =>
  tagsArray
    .map(tag => (typeof tag === 'string' ? tag.trim() : null))
    .filter(tag => tag !== '' && tag !== null)

export const toTagArray = tags => {
  let tagsArray

  if (Array.isArray(tags)) {
    tagsArray = tags
  } else if (typeof tags === 'string') {
    tagsArray = tags.split(',')
  } else {
    tagsArray = null
  }

  if (tagsArray === null) {
    return null
  }

  return sanitizeArray(tagsArray)
}
