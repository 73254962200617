export type NavCssProps = {
  expanded: boolean
  width: string
}

export type AsideCssProps = {
  width: string
  minWidth: string
}

export type SidebarProps = {
  docked: boolean
  delayOnHover?: number
  /** Accepts CSS values */
  expandedWidth?: string
  /** Accepts CSS values */
  collapsedWidth?: string
}

export enum SidebarStates {
  ExpandedDocked = 'expanded-docked',
  ExpandedHover = 'expanded-hover',
  Collapsed = 'collapsed',
}

export type SidebarContextT = {
  state: SidebarStates
  expandedWidth: string
  collapsedWidth: string
}
