import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { hideDialog } from '../store/dialog'
import Button from './Button'
import style from './ConfirmationDialog.scss'
import { FormattedMessage } from './FormattedMessage'

const ConfirmationDialog = ({ children, onNo, onYes }) => {
  const dispatch = useDispatch()

  const onYesCb = () => {
    dispatch(hideDialog())
    onYes()
  }

  const onNoCb = () => {
    dispatch(hideDialog())
    onNo()
  }

  return (
    <div className={style.container}>
      {children}
      <div className={style.actions}>
        <Button onClick={onNoCb}>
          <FormattedMessage id="confirmation.no" />
        </Button>
        <Button variant="primary" onClick={onYesCb}>
          <FormattedMessage id="confirmation.yes" />
        </Button>
      </div>
    </div>
  )
}

ConfirmationDialog.propTypes = {
  children: PropTypes.node,
  onNo: PropTypes.func,
  onYes: PropTypes.func,
}

ConfirmationDialog.defaultProps = {
  onNo: () => {
    /* do nothing */
  },
  onYes: () => {
    /* do nothing */
  },
}

export default ConfirmationDialog
