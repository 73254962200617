import {
  chakra,
  forwardRef as chakraForwardRef,
  StylesProvider,
  useMultiStyleConfig,
  useRadioGroup,
} from '@chakra-ui/react'
import { useMemo } from 'react'

import { componentsThemable } from '../../theme/components-themable'
import {
  RadioButtonGroupContextT,
  RadioButtonGroupProvider,
} from './RadioButton.utilities'
import { RadioButtonGroupProps } from './RadioButtonGroup.types'

export const RadioButtonGroup = chakraForwardRef<RadioButtonGroupProps, 'div'>(
  (props, ref) => {
    const {
      size,
      variant,
      colorScheme,
      children,
      className,
      isFocusable,
      isDisabled,
      ...rest
    } = props

    const styles = useMultiStyleConfig(componentsThemable.RadioButton, {
      size,
      variant,
      colorScheme,
    })

    const { value, onChange, getRootProps, name, htmlProps } = useRadioGroup(rest)

    const group = useMemo(
      (): RadioButtonGroupContextT => ({
        onChange,
        value,
        name,
        isFocusable,
        isDisabled,
      }),
      [onChange, value, name, isFocusable, isDisabled]
    )

    const groupProps = getRootProps(htmlProps, ref)

    return (
      <RadioButtonGroupProvider value={group}>
        <StylesProvider value={styles}>
          <chakra.div
            {...groupProps}
            className={className}
            sx={{ display: 'flex', flexDirection: 'row' }}
          >
            {children}
          </chakra.div>
        </StylesProvider>
      </RadioButtonGroupProvider>
    )
  }
)
