import { Box, keyframes, usePrefersReducedMotion } from '@chakra-ui/react'

import { colors } from '../../theme'

const styles = {
  dot: {
    width: '10px',
    height: '10px',
    borderRadius: '5px',
    backgroundColor: colors.gray[200],
    color: colors.gray[100],
    display: ' inline-block',
    margin: '0 2px',
  },
}

const keyframeDot1 = keyframes`
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
`

const keyframeDot2 = keyframes`
 0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
`

const keyframeDot3 = keyframes`
 0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
`

export const ThreeDotsLoader = () => {
  const prefersReducedMotion = usePrefersReducedMotion()
  const animation1 = prefersReducedMotion
    ? undefined
    : `${keyframeDot1} infinite 1s linear`

  const animation2 = prefersReducedMotion
    ? undefined
    : `${keyframeDot2} infinite 1s linear`

  const animation3 = prefersReducedMotion
    ? undefined
    : `${keyframeDot3} infinite 1s linear`

  return (
    <Box>
      <Box style={styles.dot} animation={animation1} />
      <Box style={styles.dot} animation={animation2} />
      <Box style={styles.dot} animation={animation3} />
    </Box>
  )
}
