import { RECORDER_LANGUAGES_WITH_LABEL } from '@retorio/sdk'
import { colors, Language, LanguageSwitchMenu } from '@retorio/ui'
import moment from 'moment'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setLocale } from '../store/intl/effects'
import { selectLocale } from '../store/intl/selectors'

export const LanguageSwitch = () => {
  const dispatch = useDispatch()
  const locale = useSelector(selectLocale)

  const selectedLanguage: Language = RECORDER_LANGUAGES_WITH_LABEL.find(
    ({ value }) => value.split('-')[0] === locale
  ) || {
    value: 'en-US',
    label: 'English',
  }

  const handleOnChange = (value: string) => {
    dispatch(setLocale(value))
    // setting language of moment is necessary e.g. for the day on WelcomeBanner.tsx
    moment.locale(value)
  }

  return (
    <LanguageSwitchMenu
      selectedLanguage={selectedLanguage.value}
      availableLanguages={RECORDER_LANGUAGES_WITH_LABEL}
      onChange={handleOnChange}
      color={colors.recorderLanguageIconColor}
    />
  )
}
