import { RadioGroupContext } from '@chakra-ui/react'
import { createContext } from '@retorio/react-utils'

type RadioButtonGroupContextT = RadioGroupContext

const [RadioButtonGroupProvider, useRadioButtonGroupContext, RadioButtonGroupContext] =
  createContext<RadioButtonGroupContextT>({
    name: 'RadioButtonGroupContext',
    strict: true,
  })

export { RadioButtonGroupContext, RadioButtonGroupProvider, useRadioButtonGroupContext }

export type { RadioButtonGroupContextT }
