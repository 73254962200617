import cx from 'classnames'
import PropTypes from 'prop-types'
import { Component } from 'react'

import ControlBar from '../ControlBar'
import style from './style.scss'

const hasAudio = video =>
  video?.mozHasAudio ||
  Boolean(video?.webkitAudioDecodedByteCount) ||
  Boolean(video?.audioTracks && video?.audioTracks?.length)

const hasVideo = video =>
  Boolean(video?.mozParsedFrames) ||
  Boolean(video?.webkitVideoDecodedByteCount) ||
  Boolean(video?.videoTracks && video?.videoTracks?.length)

export default class Player extends Component {
  static propTypes = {
    className: PropTypes.string,
    onFinish: PropTypes.func,
    onPlay: PropTypes.func,
    onReady: PropTypes.func,
    onMetaReady: PropTypes.func,
    onStop: PropTypes.func,
    hasAudioTracks: PropTypes.func,
    hasVideoTracks: PropTypes.func,
    onVideoLoaded: PropTypes.func,
    playerFooter: PropTypes.node,
    videoMeta: PropTypes.shape({
      duration: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }),
    videoDuration: PropTypes.number,
    videoUrl: PropTypes.string,
    mirrorVideo: PropTypes.bool,
  }

  static defaultProps = {
    onFinish: Function.prototype,
    onMetaReady: Function.prototype,
    onPlay: Function.prototype,
    onReady: Function.prototype,
    onStop: Function.prototype,
  }

  static additionalControlsClass = style['additional-controls']

  state = {
    videoDuration: null,
    currentTime: 0,
    playing: false,
  }

  onCanPlay = () => {
    const { onReady } = this.props

    this.setState({
      videoDuration: this.props.videoDuration
        ? this.props.videoDuration
        : this.videoRef.duration,
    })

    onReady({
      play: this.handlePlay,
      pause: this.handlePause,
    })
  }

  onMetaReady = () => {
    const { onMetaReady } = this.props

    onMetaReady({
      duration: this.videoRef.duration,
      videoHeight: this.videoRef.videoHeight,
      videoWidth: this.videoRef.videoWidth,
    })
  }

  onPlay = () => {
    const { onPlay } = this.props

    onPlay()
    this.setState({ playing: true })
  }

  onPause = () => {
    const { onStop } = this.props

    onStop()
    this.setState({ playing: false })
  }

  onEnded = () => {
    const { onFinish } = this.props

    onFinish()
    this.setState({ playing: false })
  }

  onTimeUpdate = () => {
    this.setState({
      currentTime: this.videoRef.currentTime,
    })
  }

  handlePlay = () => {
    this.videoRef.play()
  }

  handlePause = () => {
    this.videoRef.pause()
  }

  onVideoBarChange = currentTime => {
    this.videoRef.currentTime = currentTime
  }

  componentWillUnmount() {
    this.videoElement.removeEventListener('loadeddata', this.onVideoDataLoaded)
    this.videoElement.remove() // since it was created outside the return statement
  }

  render() {
    const {
      className,
      playerFooter,
      videoUrl,
      mirrorVideo,
      hasAudioTracks,
      hasVideoTracks,
      onVideoLoaded,
    } = this.props

    const { currentTime, playing, videoDuration } = this.state
    // we create a new video component to set the autoplay to true to get the loaded data.
    const video = document.createElement('video')

    video.src = videoUrl
    video.muted = true
    video.autoplay = true
    video.playsInline = true

    const onVideoDataLoaded = () => {
      if (hasAudioTracks) {
        hasAudioTracks(hasAudio(video))
      }

      if (hasVideoTracks) {
        hasVideoTracks(hasVideo(video))
      }

      if (onVideoLoaded) {
        onVideoLoaded(false)
      }
    }

    video.addEventListener('loadeddata', onVideoDataLoaded)

    this.videoElement = video
    this.onVideoDataLoaded = onVideoDataLoaded

    return (
      <div className={cx(style.container, className)}>
        <video
          // force re-render of video tag when we change url
          playsInline
          key={videoUrl || 'empty'}
          className={mirrorVideo ? style.outputFlip : style.output}
          onCanPlay={this.onCanPlay}
          onPlay={this.onPlay}
          onPause={this.onPause}
          onEnded={this.onEnded}
          onLoadedMetadata={this.onMetaReady}
          onTimeUpdate={this.onTimeUpdate}
          preload="metadata"
          style={{ width: 640, height: 480 }}
          ref={el => {
            this.videoRef = el
          }}
        >
          {videoUrl && <source src={videoUrl} type="video/mp4" />}
        </video>
        <div className={style.controls}>
          <ControlBar
            actions={playerFooter}
            onPause={this.handlePause}
            onPlay={this.handlePlay}
            onSeek={this.onVideoBarChange}
            duration={videoDuration || 100}
            currentTime={currentTime}
            playing={playing}
          />
        </div>
      </div>
    )
  }
}
