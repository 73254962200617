// Chakra Theme overrides
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/button.ts

export const buttonTheme = {
  baseStyle: {
    borderRadius: 'base',
    fontWeight: 'medium',
    lineHeight: 1,
  },
  defaultProps: {
    size: 'sm',
    colorScheme: 'blue',
  },
  variants: {
    outline: {
      border: '1px',
    },
    // These variants don't match the figma styles but they are used across the app
    primary: {
      bg: '#17459C',
      color: 'white',
      _hover: {
        bg: '#2860CB !important',
        color: 'white',
      },
      _active: {
        bg: '#0E3170',
        color: 'white',
      },
      _disabled: {
        cursor: 'not-allowed',
        boxShadow: 'none',
      },
    },
    secondary: {
      fontWeight: 'normal',
      color: '#757575',
      border: '1px',
      borderColor: '#757575',
      _hover: {
        bg: 'gray.50',
      },
      _active: {
        bg: 'gray.100',
      },
    },
    warning: {
      bg: 'red.500',
      color: 'white',
      _hover: {
        bg: 'red.600',
        color: 'white',
      },
      _active: {
        bg: 'red.700',
        color: 'white',
      },
      _disabled: {
        cursor: 'not-allowed',
        boxShadow: 'none',
        bg: 'red.200',
      },
    },
  },
}
