import cx from 'classnames'
import PropTypes from 'prop-types'

import style from './Card.scss'

const Card = ({ children, className, ...other }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div className={cx(style.card, className)} {...other}>
    {children}
  </div>
)

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.any,
}

export default Card
