import { radioButtonTheme } from '../../components/RadioButton/RadioButton.theme'
import { alertTheme } from './alert.theme'
import { breadcrumbTheme } from './breadcrumb.theme'
import { buttonTheme } from './button.theme'
import { checkboxTheme } from './checkbox.theme'
import { inputTheme } from './input.theme'
import { linkTheme } from './link.theme'
import { menuTheme } from './menu.theme'
import { modalTheme } from './modal.theme'
import { radioTheme } from './radio.theme'
import { selectTheme } from './select.theme'
import { spinnerTheme } from './spinner.theme'
import { tabsTheme } from './tabs.theme'
import { tagTheme } from './tag.theme'

export const components = {
  Alert: alertTheme,
  Breadcrumb: breadcrumbTheme,
  Button: buttonTheme,
  Checkbox: checkboxTheme,
  Input: inputTheme,
  NumberInput: inputTheme,
  Link: linkTheme,
  Menu: menuTheme,
  Modal: modalTheme,
  Radio: radioTheme,
  RadioButton: radioButtonTheme,
  Select: selectTheme,
  Spinner: spinnerTheme,
  Tag: tagTheme,
  Tabs: tabsTheme,
}
