import { Icon } from '@chakra-ui/react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { FaCircle } from 'react-icons/fa'

import { convertSecondsToDuration } from '../utils'
import Button from './Button'
import style from './Timer.scss'

const Timer = ({ className, current, duration, running }) => (
  <Button
    className={cx(style.container, className)}
    variant="dark"
    size="sm"
    disabled
    data-cy="timer"
  >
    {running && <Icon className={style.icon} as={FaCircle} />}
    <span>{convertSecondsToDuration(duration - current)}</span>
  </Button>
)

Timer.propTypes = {
  className: PropTypes.any,
  duration: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  running: PropTypes.bool,
}

export default Timer
