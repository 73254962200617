import { Analyzer, Log } from '@retorio/sdk'
import config from '@retorio/sdk/src/config'

import FatalError from '../../components/FatalError'
import { RecorderError } from '../../components/MainRecorder'
import { uploadMediaFile } from '../../firebase/firebase-storage'
import { showError } from '../dialog'
import { finishSession } from '../plugin'
import {
  selectOrganizationId,
  selectPluginId,
  selectQuestion,
  selectQuestionIndex,
  selectQuestionnaire,
} from '../plugin/selectors'
import { selectActiveRecording, selectAllRecordings } from './selectors'

export const RECORDING_FINISH = 'recording/finish'

export const RECORDING_NEXT = 'recording/next'

export const RECORDING_START = 'recording/start'

export const RECORDING_START_DENIED = 'recording/start-denied'

export const RECORDING_RETRY = 'recording/retry'

export const RECORDING_RETRY_FAILED = 'recording/retry-failed'

export const RECORDING_USER_VIDEO_UPLOADING = 'recording/user-video-uploading'

export const RECORDING_USER_VIDEO_UPLOAD_FAILED = 'recording/user-video-upload-failed'

export const RECORDING_USER_VIDEO_UPLOADED = 'recording/user-video-uploaded'

export const RECORDING_USER_VIDEO_SELECTED = 'recording/user-video-selected'

export const RECORDING_STOP = 'recording/stop'

export const RECORDING_RESTART = 'recording/restart'

export const RECORDING_RESTART_FAILED = 'recording/restart-failed'

export const nextRecording = () => async dispatch => {
  try {
    const analysis = await Analyzer.createAnalysis()

    dispatch({
      type: RECORDING_NEXT,
      payload: {
        analysisId: analysis.id,
      },
    })
  } catch (error) {
    console.error(error)
  }
}

// We mark the recording as "done" and return wether or not this has been the
// last recording. This way Recorder and Player can decide by themselves where
// to redirect the user.
const finishOrNext = async (dispatch, state) => {
  const recordings = selectAllRecordings(state)
  const questionnaire = selectQuestionnaire(state)
  const { questionId } = selectActiveRecording(state)
  const questionIndex = selectQuestionIndex(state, questionId)
  const isLast = !questionnaire || questionnaire.questions.length === questionIndex + 1
  let done = false

  if (isLast) {
    try {
      await dispatch(finishSession(recordings))
      done = true
    } catch (error) {
      Log.error(
        new Error('MainRecorder: Error while finishing the session. Error:', {
          cause: error,
        })
      )

      dispatch(
        showError(<FatalError errorRecorder={[RecorderError.sessionCreationError]} />)
      )
    }
  } else {
    try {
      await dispatch(nextRecording())
    } catch (error) {
      console.error(error)
    }
  }

  return done
}

export const finishRecording = () => (dispatch, getState) => {
  const state = getState()

  dispatch({ type: RECORDING_FINISH })

  return finishOrNext(dispatch, state)
}

// Restarting is the same as retrying, except it's restoring the used "attempt".
export const restartRecording = () => async dispatch => {
  try {
    const analysis = await Analyzer.createAnalysis()

    dispatch({
      type: RECORDING_RESTART,
      payload: {
        analysisId: analysis.id,
      },
    })
  } catch (error) {
    console.error(error)
    dispatch({ type: RECORDING_RESTART_FAILED })
  }
}

export const retryRecording = () => async dispatch => {
  try {
    const analysis = await Analyzer.createAnalysis()

    dispatch({
      type: RECORDING_RETRY,
      payload: {
        analysisId: analysis.id,
      },
    })
  } catch (error) {
    console.error(error)
    dispatch({ type: RECORDING_RETRY_FAILED })
  }
}

// We need to increase `attempts` at every start and also set a flag in
// our redux store which marks the recorder as `running`.
export const startRecording = () => (dispatch, getState) => {
  const state = getState()
  const { questionId, ...recording } = selectActiveRecording(state)
  const question = selectQuestion(state, questionId)
  const attempts = recording.attempts + 1

  if (question && attempts > question.repeatable && question.repeatable !== 0) {
    // For a better debugging, I added a payload to better understand why the retry failed.
    dispatch({
      type: RECORDING_START_DENIED,
      payload: {
        questionId,
        attempts,
        repeatable: question.repeatable,
      },
    })
  } else {
    dispatch({ type: RECORDING_START })
  }
}

export const setUserVideo = video => dispatch => {
  let videoUrl

  try {
    videoUrl = video ? URL.createObjectURL(video) : null
  } catch (error) {
    const videoDataType = typeof video
    const videoMediaType = video && video.type
    const videoName = video && video.name
    const videoSize = video && video.size

    Log.error(new Error('Create video Error'), {
      extra: { cause: error, videoMediaType, videoName, videoSize, videoDataType },
      message: 'Unable to create video URL from BLOB',
    })
  }

  dispatch({
    type: RECORDING_USER_VIDEO_SELECTED,
    payload: {
      video,
      videoUrl,
    },
  })
}

export const uploadUserVideo = () => async (dispatch, getState) => {
  const state = getState()
  const { analysisId, blob } = selectActiveRecording(state)
  const organizationId = selectOrganizationId(state)
  const pluginId = selectPluginId(state)
  const url = `${config.apiBaseUrl}/processVideo`

  dispatch({ type: RECORDING_USER_VIDEO_UPLOADING })
  const videoGSUrl = await uploadMediaFile(blob, organizationId, pluginId)
  const body = JSON.stringify({
    videoUrl: videoGSUrl,
    payloadId: analysisId,
    product: 'recruiting',
    mirrorVideo: false,
    userNavigatorData: navigator.userAgent,
  })

  const headers = { 'Content-Type': 'application/json' }

  await fetch(url, {
    method: 'POST',
    headers,
    body,
  })

  dispatch({ type: RECORDING_USER_VIDEO_UPLOADED })

  /*
    TODO
    In local development, we run into the issue, that after video upload, the loading state 
    in the player is finished. Yet the loading spinner should be displayed until finishOrNext 
    below is be done as well. In production this is not an issue, as the backend runs on 
    multiple threads.
  */
  return finishOrNext(dispatch, state)
}

export const uploadVideo = (dispatch, history) => {
  dispatch(uploadUserVideo())
    .then(isFinished => history.push(isFinished ? '/submit' : '/recorder'))
    .catch(() => {
      dispatch(showError(<FatalError errorRecorder={[RecorderError.uploadVideoError]} />))
      history.push('/recorder')
    })
}

export const stopRecording = () => ({ type: RECORDING_STOP })
