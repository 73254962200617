import { getStorage, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage'
import { Dispatch, MutableRefObject, SetStateAction } from 'react'
import { v4 as uuidv4 } from 'uuid'

import config from '../config'
import { firebaseApp } from './firebase-client'

const firebaseUsersTemporaryStorage = getStorage(
  firebaseApp,
  `gs://${config.cloudStorage.usersTemporaryFileStorageBucketName}`
)

export const firebaseSpeedTestFile = `https://storage.googleapis.com/${config.cloudStorage.usersFileStorageBucketName}/speedTestFiles/Calculate%20your%20speaking%20rate.jpg`

export const enum NetworkType {
  upload = 'upload',
  download = 'download',
}

export const uploadMediaFile = async (
  file: Blob,
  organizationId: string,
  pluginId: string
) => {
  const filePath = `${organizationId}/${pluginId}/${uuidv4()}`

  const storageRef = ref(firebaseUsersTemporaryStorage, filePath)

  const firebaseFile = await uploadBytes(storageRef, file)
  const fileGSUrl = firebaseFile.ref.toString()

  return fileGSUrl
}

export const uploadForSpeedTest = async (
  file: Blob,
  uuid: string,
  startTime: number,
  showResultsCallback: (
    fileSize: number,
    startTime: number,
    endTime: number,
    checkType: NetworkType
  ) => void,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setHasAccessToGCP: Dispatch<SetStateAction<boolean>>,
  mounted: MutableRefObject<boolean>
) => {
  const filePath = `tempFolderSpeedTest/${uuid}`

  const storageRef = ref(firebaseUsersTemporaryStorage, filePath)
  const uploadFile = uploadBytesResumable(storageRef, file)

  const updateUploadSpeed = (size: number) => {
    const end = new Date().getTime()

    if (mounted.current) {
      showResultsCallback(size, startTime, end, NetworkType.upload)
      setIsLoading(false)
      setHasAccessToGCP(true)
    }
  }

  return new Promise((resolve, reject) => {
    uploadFile.on(
      'state_changed',
      snapshot => {
        if (snapshot.state !== 'running') {
          reject(new Error('Error'))
        }

        // update upload speed
        const { bytesTransferred } = snapshot

        if (bytesTransferred > 0) {
          updateUploadSpeed(bytesTransferred)
        }
      },
      error => {
        console.error(error)
        reject(new Error('Failed to upload to Firebase Storage'))
      },
      () => {
        updateUploadSpeed(file.size)
        resolve(true)
      }
    )
  })
}
