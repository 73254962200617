import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '../../components/Button'
import Container from '../../components/Container'
import { FormattedMessage } from '../../components/FormattedMessage'
import PrivacyLink from '../../components/PrivacyLink'
import SlideCheckbox from '../../components/SlideCheckbox'
import { cacheVideoProcessingConsent } from '../../store/plugin/actions'
import { selectPlugin } from '../../store/plugin/selectors'
import style from './Consent.scss'

const ConsentContainer = ({ history }) => {
  const dispatch = useDispatch()
  const [consent, setConsent] = useState([false, false])
  const [videoProcessingConsent, companyConsent] = consent
  const {
    organization: { companyName: organizationName, gdprEnabled },
  } = useSelector(selectPlugin)

  useEffect(() => {
    if (!gdprEnabled) {
      setConsent([true, true])
    }
  }, [gdprEnabled])

  const handleCheckboxChange = (
    nextVideoProcessingConsent = false,
    nextCompanyConsent = false
  ) => {
    setConsent([nextVideoProcessingConsent, nextCompanyConsent])
  }

  const handleSendConsent = async () => {
    /*
      The reason we only cache the consent at this place is that we want to 
      start processing of videos immediately. Yet, the user should still have
      option to submit / delete the videos at the last recorder step.
      When user submits the video, we save the company consent. In case the user
      chooses to delete the session, there is no consent saved and a cron job
      deletes the session data. 
    */
    await dispatch(cacheVideoProcessingConsent(videoProcessingConsent))
    history.push('/prerequisites')
  }

  return (
    <Container
      actions={[
        <Button
          key="submitBtn"
          variant="primary"
          onClick={handleSendConsent}
          disabled={!companyConsent}
        >
          <FormattedMessage id="route.consent.continue" />
        </Button>,
      ]}
      nextStep={<FormattedMessage id="navigation.step.prerequisites" />}
      prevStep={<FormattedMessage id="navigation.step.intro" />}
    >
      <div className={style.container}>
        <div className={style.explanations}>
          <p>
            <FormattedMessage id="consent.intro" />
          </p>
        </div>
        <div className="content">
          <div className={style.explanations}>
            <p>
              <FormattedMessage id="consent.explanation.1" />
            </p>
            <p>
              <FormattedMessage id="consent.explanation.2" />
            </p>
          </div>
          <div className={style.consent}>
            <div>
              <SlideCheckbox
                checked={!gdprEnabled}
                id="video-processing-consent"
                onChange={nextVideoProcessingConsent =>
                  handleCheckboxChange(nextVideoProcessingConsent, companyConsent)
                }
              />
            </div>
            <div>
              <p>
                <FormattedMessage
                  id="consent.retorio"
                  values={{
                    privacyLink: (
                      <PrivacyLink>
                        <FormattedMessage id="consent.privacyLink" />
                      </PrivacyLink>
                    ),
                  }}
                />
              </p>
            </div>
          </div>

          <div className={style.consent}>
            <div>
              <SlideCheckbox
                checked={!gdprEnabled}
                id="company-consent"
                onChange={nextCompanyConsent =>
                  handleCheckboxChange(videoProcessingConsent, nextCompanyConsent)
                }
              />
            </div>
            <div>
              <p>
                <FormattedMessage
                  id="consent.company"
                  values={{ company: <strong>{organizationName}</strong> }}
                />
              </p>
              <small>
                <FormattedMessage
                  id="consent.company.hint"
                  values={{ company: <strong>{organizationName}</strong> }}
                />
              </small>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

ConsentContainer.propTypes = {
  history: PropTypes.any,
}

export default ConsentContainer
