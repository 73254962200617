import { ChakraTheme, extendTheme } from '@chakra-ui/react'

import { components } from './components'
import { breakpoints } from './foundations/breakpoints'
import { colors, graphColors } from './foundations/colors'
import { fonts } from './foundations/fonts'

const retorioTheme: Partial<ChakraTheme> = {
  fonts,
  components,
  colors,
  breakpoints,
}

export const theme = extendTheme(retorioTheme)

export * from './components-themable'

export { colors, graphColors }
