import { createContext } from 'react'

import {
  defaultSidebarCollapsedWidth,
  defaultSidebarExpandedWidth,
} from './Sidebar.defaults'
import { SidebarContextT, SidebarStates } from './Sidebar.types'

export const SidebarContext = createContext<SidebarContextT>({
  state: SidebarStates.Collapsed,
  expandedWidth: defaultSidebarExpandedWidth,
  collapsedWidth: defaultSidebarCollapsedWidth,
})

export const isExpanded = (s: SidebarStates) =>
  s === SidebarStates.ExpandedDocked || s === SidebarStates.ExpandedHover

export const isExpandedDocked = (s: SidebarStates) => s === SidebarStates.ExpandedDocked

export const isExpandedHover = (s: SidebarStates) => s === SidebarStates.ExpandedHover

export const isCollapsed = (s: SidebarStates) => s === SidebarStates.Collapsed
