import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { hideDialog } from '../store/dialog'
import Button from './Button'
import { FormattedMessage, useIntl } from './FormattedMessage'
import { RecorderError } from './MainRecorder'
import style from './VideoUploadError.scss'

const FatalError = ({ errorRecorder }) => {
  const dispatch = useDispatch()
  const closeDialog = () => {
    dispatch(hideDialog())
  }

  const { formatMessage } = useIntl()

  return (
    <div className={style.container}>
      <p>
        {errorRecorder.includes(RecorderError.fatalError) && (
          <FormattedMessage id="error.fatalError.title" />
        )}
        {(errorRecorder.includes('audio') || errorRecorder.includes('video')) && (
          <p>
            {formatMessage(
              { id: 'error.videoError.description' },
              { error1: errorRecorder[0] }
            )}
          </p>
        )}

        {errorRecorder.length > 1 && errorRecorder.includes('audio') && (
          <p>
            {formatMessage(
              { id: 'error.videoError.audioError.description' },
              { error1: errorRecorder[0], error2: errorRecorder[1] }
            )}
          </p>
        )}

        {errorRecorder.includes(RecorderError.orientationError) && (
          <FormattedMessage id="error.orientationError.description" />
        )}
        {errorRecorder.includes(RecorderError.uploadVideoError) && (
          <FormattedMessage id="error.uploadVideo.description" />
        )}
        {errorRecorder.includes(RecorderError.sessionCreationError) && (
          <FormattedMessage id="error.sessionCreationError.description" />
        )}
      </p>
      {!errorRecorder.includes(RecorderError.sessionCreationError) && (
        <div className={style.actions}>
          <Button variant="primary" onClick={closeDialog}>
            <FormattedMessage id="error.videoUpload.okButton" />
          </Button>
        </div>
      )}
    </div>
  )
}

FatalError.propTypes = {
  errorRecorder: PropTypes.string,
}

export default FatalError
