import { getUserLanguage, Product } from '@retorio/sdk'
import sharedDe from '@retorio/shared-components/src/i18n/de.json'
import sharedEn from '@retorio/shared-components/src/i18n/en.json'
import sharedEs from '@retorio/shared-components/src/i18n/es.json'
import sharedFr from '@retorio/shared-components/src/i18n/fr.json'
import sharedIt from '@retorio/shared-components/src/i18n/it.json'
import sharedPt from '@retorio/shared-components/src/i18n/pt.json'

import recorderDe from '../../i18n/de.json'
import recorderEn from '../../i18n/en.json'
import recorderEs from '../../i18n/es.json'
import recorderFr from '../../i18n/fr.json'
import recorderIt from '../../i18n/it.json'
import recorderPt from '../../i18n/pt.json'
import types from './private/actions'

const messagesMap = {
  'en-US': { ...recorderEn, ...sharedEn },
  'de-DE': { ...recorderDe, ...sharedDe },
  'es-ES': { ...recorderEs, ...sharedEs },
  'pt-PT': { ...recorderPt, ...sharedPt },
  'it-IT': { ...recorderIt, ...sharedIt },
  'fr-FR': { ...recorderFr, ...sharedFr },
}

const getMessagesFromLocale = locale => {
  if (!messagesMap[locale]) {
    console.warn(
      `Warning: No messages found for locale ${locale}. Falling back to en-US.`
    )

    return messagesMap['en-US']
  }

  return messagesMap[locale]
}

// Retorio uses the IETF BCP 47 language tag (https://en.wikipedia.org/wiki/IETF_language_tag)
// which looks like "en-US" or "de-DE". react-intl uses the format "en" or "de".
const toIntlLocale = locale => locale.substring(0, 2)

const getDefaultState = () => {
  const fullLocale = getUserLanguage(Product.RECRUITING)
  const languageCode = toIntlLocale(fullLocale)

  // This is somewhat confusing naming because getUserLanguage() actually returns
  // a locale while toIntlLocale() just returns the language code. But i'm not
  // changing the naming in SDK and frontend for this fix so i added
  // https://gitlab.com/retorio/platform/js-clients/issues/145 to deal with this.
  return {
    locale: languageCode,
    messages: getMessagesFromLocale(fullLocale),
  }
}

export default (state = getDefaultState(), { type, data } = {}) => {
  switch (type) {
    case types.SET_LOCALE:
      return {
        locale: toIntlLocale(data),
        messages: getMessagesFromLocale(data),
      }
    default:
      return state
  }
}
