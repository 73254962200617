import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Container from '../../components/Container'
import { FormattedMessage } from '../../components/FormattedMessage'
import Question from '../../components/Question'
import Recorder from '../../components/Recorder'
import {
  selectNextQuestion,
  selectQuestion,
  selectQuestionnaire,
} from '../../store/plugin/selectors'
import { selectActiveRecording } from '../../store/recordings/selectors'
import { SCREENS } from '../../utils/constants'
import style from './Recorder.scss'

const RecorderContainer = () => {
  const questionnaire = useSelector(selectQuestionnaire)
  const { attempts, questionId } = useSelector(selectActiveRecording)
  const currentQuestion = useSelector(state => selectQuestion(state, questionId))
  const nextQuestion = useSelector(state => selectNextQuestion(state, questionId))
  const [nextStep, setNextStep] = useState(null)
  const [prevStep, setPrevStep] = useState(null)

  // Depending on the next question, the navigation on the bottom
  // either shows the number of the next question, like "Next: Question 2 of 3"
  // or it shows "Next: Finish" to signal the completion of the questionnaire.
  useEffect(() => {
    let nextQuestionIndex = -1

    if (nextQuestion) {
      nextQuestionIndex = questionnaire.questions.findIndex(
        ({ id }) => id === nextQuestion.id
      )
    }

    if (questionnaire && currentQuestion) {
      const currentQuestionIndex = questionnaire.questions.findIndex(
        ({ id }) => id === currentQuestion.id
      )

      if (currentQuestionIndex === 0) {
        setPrevStep(<FormattedMessage id="navigation.step.prerequisites" />)
      }

      setNextStep(
        nextQuestion ? (
          <FormattedMessage
            id="navigation.step.question"
            values={{
              current: nextQuestionIndex + 1,
              max: questionnaire.questions.length,
            }}
          />
        ) : (
          <FormattedMessage id="navigation.step.submit" />
        )
      )
    } else {
      setPrevStep(<FormattedMessage id="navigation.step.prerequisites" />)
      setNextStep(<FormattedMessage id="navigation.step.submit" />)
    }
  }, [currentQuestion, nextQuestion, questionnaire])

  return (
    <Container
      nextStep={nextStep}
      prevStep={prevStep}
      data-cy-route={SCREENS.RECORDER}
      data-cy-prop-repeatable={currentQuestion && currentQuestion.repeatable}
      data-cy-prop-tries={attempts}
    >
      <div className={style.container}>
        <Question current={currentQuestion} attempts={attempts} />
        <Recorder gauge height={480} key={questionId} />
      </div>
    </Container>
  )
}

export default RecorderContainer
