/* eslint-disable react/jsx-key */
import { ComponentWithAs, createIcon, Icon, IconProps } from '@chakra-ui/react'

import { colors } from '../theme'

export const RemoveCircleIcon = createIcon({
  viewBox: '0 0 12 12',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6C0 2.68629 2.68629 0 6 0C9.31211 0.00385738 11.9961 2.68789 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6ZM8.88131 8.52436C8.92706 8.34954 8.87494 8.16364 8.74496 8.03811L6.79496 6.0886C6.77148 6.06524 6.75828 6.03348 6.75828 6.00035C6.75828 5.96723 6.77148 5.93547 6.79496 5.9121L8.74496 3.9621C8.88012 3.83788 8.93633 3.64958 8.8914 3.47159C8.84647 3.2936 8.70761 3.15454 8.52968 3.10936C8.35176 3.06417 8.16337 3.12012 8.03896 3.2551L6.08896 5.2051C6.06551 5.22862 6.03367 5.24183 6.00046 5.24183C5.96725 5.24183 5.93541 5.22862 5.91196 5.2051L3.96196 3.2551C3.76686 3.0596 3.45022 3.05926 3.25471 3.25435C3.0592 3.44945 3.05886 3.7661 3.25396 3.9616L5.20396 5.9116C5.22744 5.93497 5.24064 5.96673 5.24064 5.99986C5.24064 6.03298 5.22744 6.06474 5.20396 6.0881L3.25396 8.03811C3.05877 8.23335 3.05877 8.54986 3.25396 8.7451C3.45054 8.93705 3.76438 8.93705 3.96096 8.7451L5.91096 6.7951C5.96002 6.74669 6.03889 6.74669 6.08796 6.7951L8.03796 8.7451C8.1635 8.87508 8.3494 8.92721 8.52421 8.88145C8.69903 8.8357 8.83555 8.69917 8.88131 8.52436Z"
      fill="#F06E6E"
    />
  ),
})

export const CheckCircleIcon = createIcon({
  viewBox: '0 0 12 12',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6C0 2.68629 2.68629 0 6 0C9.31211 0.00385738 11.9961 2.68789 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6ZM6.04098 8.74452L9.46348 4.10002C9.57918 3.95681 9.60682 3.76161 9.53543 3.5919C9.46404 3.4222 9.30516 3.30546 9.12188 3.28804C8.9386 3.27061 8.76057 3.35531 8.65847 3.50852L5.54498 7.73402L3.50698 6.10352C3.29131 5.93093 2.97657 5.96585 2.80398 6.18152C2.63139 6.39719 2.66631 6.71193 2.88198 6.88452L5.32598 8.83852C5.43282 8.92204 5.56873 8.95912 5.70319 8.94145C5.83764 8.92377 5.95935 8.85281 6.04098 8.74452Z"
      fill="#78E296"
    />
  ),
})

export const SnailIcon = createIcon({
  viewBox: '0 0 18 13',
  path: [
    <path
      d="M4.23 6.867a3.268 3.268 0 00-.78-.733l1.725-2.93a1.125 1.125 0 10-1.346-.669L2.798 4.287 2.175 2.54v-.01a1.125 1.125 0 10-1.355.672l.89 2.506c-.075.009-.15.022-.224.04C.559 6.006.004 6.894.004 8.125A4.881 4.881 0 004.879 13h2.259a.187.187 0 00.104-.344A6.564 6.564 0 014.23 6.867zM16.609 10.458a6.71 6.71 0 01-2.09 2.198.187.187 0 00.106.344h3a.375.375 0 00.37-.437 3.254 3.254 0 00-1.386-2.105z"
      fill="#17459C"
    />,
    <path
      d="M16.406 7.094a5.531 5.531 0 10-9.701 3.63.188.188 0 00.319-.185 5.868 5.868 0 01-.326-1.918 4.849 4.849 0 011.098-3.176A4.308 4.308 0 0111.188 4a3.004 3.004 0 013 3.065 2.391 2.391 0 01-2.5 2.498 1.658 1.658 0 01-1.655-1.657.562.562 0 111.125 0c0 .293.237.531.53.532a1.275 1.275 0 001.375-1.373 1.87 1.87 0 00-1.875-1.94 3.225 3.225 0 00-2.54 1.05 3.734 3.734 0 00-.825 2.444 4.14 4.14 0 002.58 3.98c.156.013.313.024.472.024a5.531 5.531 0 005.531-5.53z"
      fill="#17459C"
    />,
  ],
})

export const SpaceRocketFlyingIcon = createIcon({
  viewBox: '0 0 18 18',
  path: (
    <path
      d="M8.32 13.895a1.124 1.124 0 01-.488.287.188.188 0 00-.135.159l-.17 1.456a.772.772 0 001.313.636l2.004-2.003c.52-.528.932-1.15 1.216-1.835a9.302 9.302 0 00.275-2.255.188.188 0 00-.32-.14L8.32 13.896zM4.113 9.69l3.714-3.723a.188.188 0 00-.138-.32 9.742 9.742 0 00-2.315.261 5.765 5.765 0 00-1.811 1.24L1.566 9.145a.773.773 0 00.545 1.319c.027 0 1.18-.139 1.569-.186a.188.188 0 00.156-.13c.054-.173.15-.33.277-.458zM4.528 12.402c-.633-.635-1.593-.525-2.387.265-.562.562-1.139 2.374-1.525 3.796a.75.75 0 00.923.92c1.417-.39 3.225-.975 3.784-1.534.793-.793.9-1.753.266-2.386l-1.06-1.061zM16.965.6c-.936.189-2.817.628-3.4 1.212-.486.482-7.471 7.488-8.523 8.538a.187.187 0 000 .265l2.35 2.352a.188.188 0 00.265 0l8.533-8.532c.584-.585 1.024-2.466 1.212-3.4A.371.371 0 0016.965.6z"
      fill="#17459C"
    />
  ),
})

export const LoveFireIcon = createIcon({
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9652 11.0946C21.2752 11.2094 21.5038 11.4675 21.5728 11.7804C21.7129 12.4152 21.7833 13.0625 21.7827 13.7115C21.7827 18.9155 17.4028 23.1343 12 23.1343C6.59718 23.1343 2.21733 18.9155 2.21733 13.7115C2.20945 11.2039 3.25835 8.80051 5.12395 7.05149C5.35628 6.83267 5.68678 6.74018 6.00466 6.80502C6.32699 6.87344 6.59202 7.09334 6.71 7.39025C6.86472 7.77534 7.04924 8.1487 7.262 8.50718C7.3557 8.66499 7.53685 8.75512 7.72469 8.73738C7.91252 8.71965 8.07206 8.59735 8.13216 8.42502C8.83725 6.39287 8.71462 4.17862 7.78908 2.23025C7.63433 1.9095 7.68585 1.5315 7.92133 1.2601C8.16052 0.982919 8.54369 0.862648 8.9065 0.95087C14.0028 2.22928 17.7572 6.39621 18.3537 11.4361C18.3658 11.5911 18.458 11.7299 18.5991 11.8053C18.7397 11.8803 18.9099 11.8823 19.0524 11.8109C19.4097 11.6311 19.7533 11.4272 20.0807 11.2007C20.3384 11.0247 20.6705 10.9848 20.9652 11.0946ZM11.7058 20.4721C11.8786 20.6013 12.1204 20.6013 12.2932 20.4721C13.4538 19.6044 16.7917 16.9293 16.7917 14.8478C16.7917 12.3296 13.7844 11.3152 12.4035 13.3912C12.3154 13.5236 12.1633 13.6037 12 13.6037C11.8367 13.6037 11.6846 13.5236 11.5965 13.3912C10.2156 11.3152 7.20833 12.3296 7.20833 14.8478C7.20833 16.9293 10.5452 19.6044 11.7058 20.4721Z"
      fill="black"
    />
  ),
})

export const HeartIcon = createIcon({
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6244 0.00716485 23.9928 5.37555 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM12.181 17.811L17.113 12.666H17.112C18.2936 11.4847 18.2938 9.56911 17.1125 8.3875C15.9312 7.20589 14.0156 7.20566 12.834 8.387L12.176 9.045C12.1295 9.09207 12.0662 9.1187 12 9.119C11.9339 9.11816 11.8708 9.09162 11.824 9.045L11.166 8.386C10.4053 7.60389 9.28237 7.29188 8.22719 7.5694C7.172 7.84692 6.34792 8.671 6.0704 9.72619C5.79288 10.7814 6.10489 11.9043 6.887 12.665L11.82 17.811C11.8672 17.8602 11.9324 17.888 12.0005 17.888C12.0687 17.888 12.1338 17.8602 12.181 17.811Z"
      fill="black"
    />
  ),
})

export const BriefCaseIcon = createIcon({
  viewBox: '0 0 18 16',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.75 15.875H2.25C1.00736 15.875 0 14.8676 0 13.625V5.375C0 4.13236 1.00736 3.125 2.25 3.125H5.175C5.26868 3.12385 5.34714 3.05372 5.35875 2.96075L5.46675 2.096C5.60747 0.969957 6.5647 0.124942 7.6995 0.125H10.3005C11.4344 0.126076 12.3904 0.970807 12.531 2.096L12.639 2.96075C12.6507 3.05456 12.7305 3.12497 12.825 3.125H15.75C16.9926 3.125 18 4.13236 18 5.375V13.625C18 14.8676 16.9926 15.875 15.75 15.875ZM7.69948 1.625C7.32103 1.62473 7.00166 1.90646 6.95473 2.282L6.87598 2.915C6.86955 2.96824 6.88622 3.02168 6.92177 3.06182C6.95732 3.10196 7.00836 3.12496 7.06198 3.125H10.938C10.9916 3.12496 11.0426 3.10196 11.0782 3.06182C11.1138 3.02168 11.1304 2.96824 11.124 2.915L11.0452 2.282C10.9983 1.90646 10.6789 1.62473 10.3005 1.625H7.69948ZM3.9375 7.25C3.9375 6.93934 4.18934 6.6875 4.5 6.6875H13.5C13.8107 6.6875 14.0625 6.93934 14.0625 7.25V7.625C14.0625 7.93566 13.8107 8.1875 13.5 8.1875H4.5C4.18934 8.1875 3.9375 7.93566 3.9375 7.625V7.25ZM4.5 10.8125C4.18934 10.8125 3.9375 11.0643 3.9375 11.375V11.75C3.9375 12.0607 4.18934 12.3125 4.5 12.3125H13.5C13.8107 12.3125 14.0625 12.0607 14.0625 11.75V11.375C14.0625 11.0643 13.8107 10.8125 13.5 10.8125H4.5Z"
      fill="#17459C"
    />
  ),
})

export const NetworkUsersIcon = props => (
  <Icon viewBox="0 0 20 20" fill="transparent" {...props}>
    <path
      clipRule="evenodd"
      d="M4.731 15.625a1.969 1.969 0 100-3.937 1.969 1.969 0 000 3.937zM7.9 18.438a3.355 3.355 0 00-6.337 0H7.9zM15.27 15.625a1.969 1.969 0 100-3.938 1.969 1.969 0 000 3.938zM18.438 18.438a3.356 3.356 0 00-6.337 0h6.337zM10 11.688a5.063 5.063 0 100-10.126 5.063 5.063 0 000 10.126z"
      stroke="#17459C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.226 8.313h9.549M5.226 4.938h9.549M8.908 1.68a16.397 16.397 0 00.382 9.958M11.092 1.68c.396 1.428.597 2.902.595 4.382a16.272 16.272 0 01-.975 5.576"
      stroke="#17459C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export const QuestionHelpCircle = createIcon({
  viewBox: '0 0 16 17',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0C3.582 0 0 3.584 0 8.006c0 4.422 3.582 8.006 8 8.006s8-3.584 8-8.006C15.995 3.586 12.416.005 8 0zm0 12.676a1 1 0 110-2 1 1 0 010 2zm.667-3.444c0-.266.157-.506.4-.612a2.67 2.67 0 00-1.432-5.089 2.668 2.668 0 00-2.302 2.644.667.667 0 101.334 0 1.334 1.334 0 111.866 1.222 2.002 2.002 0 00-1.2 1.835.667.667 0 101.334 0z"
      fill="#BAD0F9"
    />
  ),
})

export const FingerTouchIcon = ({ ...props }: ComponentWithAs<'svg', IconProps>) => (
  <Icon {...props} viewBox="0 0 30.416 26.643">
    <path
      id="Subtraction_129"
      data-name="Subtraction 129"
      d="M108.861,26.643H99.447V23.913L96.686,20.04l-.136-.2V15.125l.351-.208,2.908-1.758V5.55a2.983,2.983,0,0,1,.852-2.09,2.9,2.9,0,0,1,.921-.636,2.8,2.8,0,0,1,1.124-.234h0a2.8,2.8,0,0,1,1.123.234,2.9,2.9,0,0,1,.921.636,2.983,2.983,0,0,1,.852,2.09v5.123l5.794,3.481.362.22v2.648a17.984,17.984,0,0,1-.1,2.108,8.18,8.18,0,0,1-.444,1.928,5.44,5.44,0,0,1-.382.81,6.049,6.049,0,0,1-.521.761,7.914,7.914,0,0,1-1.45,1.377v2.637Zm-9.052-11.76h0L98,15.981v3.377l2.761,3.885.136.2v1.723h6.518V23.277l.328-.22a5.069,5.069,0,0,0,2.116-2.521,10.016,10.016,0,0,0,.453-3.515v-1.8l-5.794-3.481-.362-.22V5.55a1.449,1.449,0,1,0-2.9,0V17.022H99.809V14.882Zm7.9-7.192h0l-1.335-.578a3.983,3.983,0,1,0-7.332,0L97.7,7.69A5.582,5.582,0,0,1,99.672.95a5.41,5.41,0,0,1,.922-.513,5.31,5.31,0,0,1,4.221,0,5.409,5.409,0,0,1,.922.513,5.524,5.524,0,0,1,.8.679,5.593,5.593,0,0,1,1.164,6.061ZM94.739,7.031A1.423,1.423,0,0,1,93.715,6.6a1.5,1.5,0,0,1,0-2.093,1.426,1.426,0,0,1,2.048,0,1.5,1.5,0,0,1,0,2.093A1.423,1.423,0,0,1,94.739,7.031Zm-3.983,0A1.423,1.423,0,0,1,89.732,6.6a1.5,1.5,0,0,1,0-2.093,1.427,1.427,0,0,1,2.048,0,1.5,1.5,0,0,1,0,2.093A1.424,1.424,0,0,1,90.756,7.031Zm-3.983,0A1.424,1.424,0,0,1,85.749,6.6a1.5,1.5,0,0,1,0-2.093,1.427,1.427,0,0,1,2.048,0,1.5,1.5,0,0,1,0,2.093A1.423,1.423,0,0,1,86.773,7.031Zm-3.983,0A1.423,1.423,0,0,1,81.766,6.6a1.5,1.5,0,0,1,0-2.093,1.427,1.427,0,0,1,2.048,0,1.5,1.5,0,0,1,0,2.093A1.424,1.424,0,0,1,82.79,7.031Z"
      transform="translate(-81.342)"
    />
  </Icon>
)

export const VideoPlayerIcon = ({ ...props }: ComponentWithAs<'svg', IconProps>) => (
  <Icon {...props} viewBox="0 0 29.128 24.55">
    <path
      id="noun-video-2824122_1_"
      data-name="noun-video-2824122 (1)"
      d="M98.973,66.328A2.467,2.467,0,0,0,96.5,68.8V80.565a2.467,2.467,0,0,0,2.469,2.469h24.187a2.467,2.467,0,0,0,2.469-2.469V68.8a2.467,2.467,0,0,0-2.469-2.469Zm0,1.015h24.187a1.462,1.462,0,0,1,1.454,1.454V80.565a1.462,1.462,0,0,1-1.454,1.454H98.973a1.462,1.462,0,0,1-1.454-1.454V68.8A1.462,1.462,0,0,1,98.973,67.343Zm10.151,2.907a.505.505,0,0,0-.527.508V78.6a.494.494,0,0,0,.789.421l5.885-3.922a.5.5,0,0,0,0-.845v0l-5.885-3.92a.486.486,0,0,0-.262-.087Zm.488,1.458,4.463,2.974-4.463,2.974Zm-3.1,14.232a2.477,2.477,0,0,0-2.329,1.648l-.111.314H97.01a.507.507,0,1,0,0,1.014h7.058l.111.314a2.469,2.469,0,0,0,4.655,0l.111-.314h16.177a.507.507,0,1,0,0-1.014H108.945l-.111-.314A2.473,2.473,0,0,0,106.508,85.94Zm0,1.016a1.453,1.453,0,1,1-1.454,1.454A1.46,1.46,0,0,1,106.508,86.956Z"
      transform="translate(-96.502 -66.328)"
    />
  </Icon>
)

export const SendLetterIcon = ({ ...props }: ComponentWithAs<'svg', IconProps>) => (
  <Icon {...props} viewBox="0 0 30.064 23.103">
    <path
      id="Subtraction_121"
      data-name="Subtraction 121"
      d="M47.516,23.1a6.168,6.168,0,0,1-4.348-1.791,6.106,6.106,0,0,1,2-9.974,6.172,6.172,0,0,1,6.7,1.326,6.106,6.106,0,0,1-1.995,9.974A6.149,6.149,0,0,1,47.516,23.1Zm0-10.872a4.766,4.766,0,0,0-3.382,1.393,4.738,4.738,0,0,0,0,6.727,4.8,4.8,0,0,0,6.764,0,4.738,4.738,0,0,0,0-6.727A4.765,4.765,0,0,0,47.516,12.231Zm0,7.478a.682.682,0,0,1-.485-1.166l.888-.876H45.466a.68.68,0,1,1,0-1.359h2.453l-.888-.876a.684.684,0,0,1,.97-.965L50.05,16.5a.677.677,0,0,1,0,.965L48,19.508A.681.681,0,0,1,47.516,19.709ZM37.95,17.667H24.284a.682.682,0,0,1-.684-.68V.68A.682.682,0,0,1,24.284,0H47.516A.681.681,0,0,1,48.2.68v6.8a.683.683,0,0,1-1.366,0v-3L36.194,9.452a.736.736,0,0,1-.588,0L24.968,4.471V16.308H37.949a.68.68,0,1,1,0,1.359ZM24.968,1.359v1.6L35.9,8.079,46.832,2.963v-1.6Z"
      transform="translate(-23.601)"
    />
  </Icon>
)

export const ClockIcon = ({ ...props }: ComponentWithAs<'svg', IconProps>) => (
  <Icon {...props} viewBox="0 0 53.921 53.921">
    <path
      id="Subtraction_149"
      data-name="Subtraction 149"
      d="M71,53.921a26.96,26.96,0,1,1,26.96-26.96A26.991,26.991,0,0,1,71,53.921ZM71,2.568A24.393,24.393,0,1,0,95.393,26.96,24.42,24.42,0,0,0,71,2.568Zm-.951,27.6a1.286,1.286,0,0,1-1.284-1.284V10.957a1.284,1.284,0,0,1,2.569,0V27.6H84.44a1.283,1.283,0,1,1,0,2.567Z"
      transform="translate(-44.04)"
    />
  </Icon>
)

export const VideoCarouselIcon = ({ ...props }: ComponentWithAs<'svg', IconProps>) => (
  <Icon {...props} viewBox="0 0 26.066 26.664">
    <path
      id="Subtraction_125"
      data-name="Subtraction 125"
      d="M32.334,26.664H10.015A1.876,1.876,0,0,1,8.141,24.79V7.836a1.876,1.876,0,0,1,1.874-1.875H32.334a1.876,1.876,0,0,1,1.873,1.875V24.79A1.876,1.876,0,0,1,32.334,26.664ZM10.015,6.922a.914.914,0,0,0-.913.913V24.79a.914.914,0,0,0,.913.913H32.334a.914.914,0,0,0,.913-.913V7.836a.914.914,0,0,0-.913-.913Zm7.806,15a.8.8,0,0,1-.8-.8v-9.2a.8.8,0,0,1,1.224-.679l7.342,4.6a.8.8,0,0,1,.374.679.787.787,0,0,1-.374.677L18.248,21.8A.818.818,0,0,1,17.821,21.918Zm.164-9.714v8.621l6.874-4.314ZM12.144,4.923h-.96A2.453,2.453,0,0,1,13.63,2.468H28.763a2.449,2.449,0,0,1,2.444,2.449h-.96a1.487,1.487,0,0,0-1.484-1.488H13.63a1.492,1.492,0,0,0-1.486,1.493ZM16.6,1.529h-.96A1.53,1.53,0,0,1,17.165,0h8.06A1.527,1.527,0,0,1,26.75,1.526h-.961a.565.565,0,0,0-.564-.564h-8.06a.568.568,0,0,0-.567.566Z"
      transform="translate(-8.141)"
    />
  </Icon>
)

export const ArrowTargetIcon = ({ ...props }: ComponentWithAs<'svg', IconProps>) => (
  <Icon {...props} viewBox="0 0 30.886 31.001">
    <path
      id="Subtraction_126"
      data-name="Subtraction 126"
      d="M59.042,31a14.2,14.2,0,0,1-10.127-4.209,14.378,14.378,0,0,1-4.184-9.84,14.489,14.489,0,0,1,.869-5.276,14.284,14.284,0,0,1,2.842-4.709l-.858-.861H44.946a.689.689,0,0,1,0-1.378h1.265L45.785,4.3H43.157a.689.689,0,0,1,0-1.378H45.4V.689a.686.686,0,1,1,1.373,0V3.345l.416.418V2.494a.686.686,0,1,1,1.373,0V5.141l.848.851a14.426,14.426,0,0,1,2.113-1.6A14.12,14.12,0,0,1,56.391,2.5a14.471,14.471,0,0,1,2.65-.244A14.2,14.2,0,0,1,69.168,6.467a14.315,14.315,0,0,1,3.926,7.416,14.492,14.492,0,0,1-.785,8.157A14.33,14.33,0,0,1,59.042,31ZM49.413,7.942a13.02,13.02,0,0,0,.472,17.876,12.918,12.918,0,0,0,18.313,0h0a13.042,13.042,0,0,0,0-18.377,12.915,12.915,0,0,0-17.814-.474l2.608,2.617A9.287,9.287,0,0,1,65.6,23.207a9.27,9.27,0,0,1-15.825-6.578,9.251,9.251,0,0,1,2.249-6.07L49.413,7.942ZM53,11.538h0a7.95,7.95,0,1,0,.97-.975l2.619,2.629a4.2,4.2,0,0,1,5.439.444,4.249,4.249,0,0,1,0,5.986A4.213,4.213,0,0,1,55.2,18.377a4.255,4.255,0,0,1,.416-4.209L53,11.538Zm3.61,3.624h0a2.857,2.857,0,0,0,.425,3.487,2.838,2.838,0,0,0,4.024,0,2.866,2.866,0,0,0,0-4.038,2.831,2.831,0,0,0-3.479-.425l1.951,1.958a.688.688,0,1,1-.971.974l-1.95-1.956Z"
      transform="translate(-42.47)"
    />
  </Icon>
)

export const CheckMarkIcon = ({ ...props }: ComponentWithAs<'svg', IconProps>) => (
  <Icon {...props} viewBox="0 0 25.979 25.702">
    <path
      id="Subtraction_127"
      data-name="Subtraction 127"
      d="M29.472,25.7H9.239a2.738,2.738,0,0,1-2.734-2.735V2.735A2.738,2.738,0,0,1,9.239,0H21.511a.684.684,0,1,1,0,1.368H9.239A1.369,1.369,0,0,0,7.872,2.735V22.967a1.369,1.369,0,0,0,1.367,1.367H29.471a1.369,1.369,0,0,0,1.368-1.367V16.258a.684.684,0,1,1,1.367,0v6.709A2.738,2.738,0,0,1,29.472,25.7Zm-10.915-8.24h-.014a.691.691,0,0,1-.489-.219l-5.284-5.7a.684.684,0,0,1,1-.93l4.8,5.18L31.318,3.051a.683.683,0,0,1,.967,0,.685.685,0,0,1,0,.967L19.04,17.262A.679.679,0,0,1,18.557,17.463Z"
      transform="translate(-6.505)"
    />
  </Icon>
)

export const SmileyIcon = createIcon({
  viewBox: '0 0 81 81',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 40.5C0 18.1325 18.1325 0 40.5 0C62.8575 0.0241814 80.9758 18.1425 81 40.5C81 62.8675 62.8675 81 40.5 81C18.1325 81 0 62.8675 0 40.5ZM6.75 40.5C6.75 59.1396 21.8604 74.25 40.5 74.25C59.1311 74.2295 74.2295 59.1311 74.25 40.5C74.25 21.8604 59.1396 6.75 40.5 6.75C21.8604 6.75 6.75 21.8604 6.75 40.5Z"
      fill={colors.primary}
    />,
    <path
      d="M27 37.125C30.7279 37.125 33.75 34.1029 33.75 30.375C33.75 26.6471 30.7279 23.625 27 23.625C23.2721 23.625 20.25 26.6471 20.25 30.375C20.25 34.1029 23.2721 37.125 27 37.125Z"
      fill={colors.primary}
    />,
    <path
      d="M54 37.125C57.7279 37.125 60.75 34.1029 60.75 30.375C60.75 26.6471 57.7279 23.625 54 23.625C50.2721 23.625 47.25 26.6471 47.25 30.375C47.25 34.1029 50.2721 37.125 54 37.125Z"
      fill={colors.primary}
    />,
    <path
      d="M27.001 54C27.001 54 32.0635 57.375 40.501 57.375C48.9385 57.375 54.001 54 54.001 54"
      stroke={colors.primary}
      strokeWidth="6.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
})
