import { promiseTimeout } from '@retorio/common-utils'

export type VideoMetadataType = {
  url: string
  duration: number
  videoHeight: number
  videoWidth: number
}

export type VideoMetadataOptionsType = {
  /**
   * @default 30000 - 30 seconds
   */
  timeout?: number
  crossOrigin?: string
}

export const videoMetadata = async (
  src: string,
  options: VideoMetadataOptionsType = {}
): Promise<VideoMetadataType> => {
  const ms = options.timeout || 1000 * 10

  return promiseTimeout<VideoMetadataType>(
    ms,
    new Promise((resolve, reject) => {
      const video = document.createElement('video')

      video.preload = 'metadata'
      video.crossOrigin = options.crossOrigin || null
      video.addEventListener('loadedmetadata', () => {
        const res: VideoMetadataType = {
          url: src,
          duration: video.duration,
          videoHeight: video.videoHeight,
          videoWidth: video.videoWidth,
        }

        resolve(res)
      })

      video.addEventListener('error', () => {
        reject(new Error(`Video info could not be fetched`))
      })

      // set video src *after* listening to events in case it loads so fast
      // that the events occur before we were listening.
      video.src = src
    })
  )
}
