// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react'

import Container from './Container'
import { FormattedMessage } from './FormattedMessage'

export enum ErrorStates {
  MISSING_GDPR = 'missingGDPR',
  SESSION_ALREADY_EXISTS = 'sessionAlreadyExists',
  RECORDER_INACTIVE = 'recorderInactive',
}

type Props = {
  error: ErrorStates
}

export const ErrorDialog = ({ error }: Props) => (
  // Typescript thinks optional prop-types are required
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <Container>
    <div style={{ margin: 'auto', padding: '2rem', textAlign: 'center' }}>
      <h1 style={{ marginBottom: '2rem', fontSize: '1.5rem' }}>
        <FormattedMessage id={`error.${error}.title`} />
      </h1>
      <FormattedMessage id={`error.${error}.text`} />
    </div>
  </Container>
)
