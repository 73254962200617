import { Analyzer, Log, Plugin } from '@retorio/sdk'

import { selectOption, selectOptions } from '../options/selectors'
import { selectSessionId } from './selectors'

const sortQuestions = questions => questions.sort((a, b) => a.order > b.order)

export const FETCH_PLUGIN_CONFIG_START = 'plugin/fetch-config-start'

export const FETCH_PLUGIN_CONFIG_SUCCESS = 'plugin/fetch-config-success'

export const FETCH_PLUGIN_CONFIG_NOT_AVAILABLE = 'plugin/fetch-config-not-available'

export const FETCH_PLUGIN_CONFIG_FAILURE = 'plugin/fetch-config-failure'

export const PLUGIN_SESSION_FINISHING = 'plugin/session-finishing'

export const PLUGIN_SESSION_FINISHED = 'plugin/session-finished'

export const PLUGIN_SESSION_FINISH_FAILED = 'plugin/session-finish-failed'

export const PLUGIN_SESSION_STARTING = 'plugin/session-starting'

export const PLUGIN_SESSION_STARTED = 'plugin/session-started'

export const PLUGIN_SESSION_START_FAILED = 'plugin/session-start-failed'

export const PLUGIN_SESSION_SENDING_CONSENT = 'plugin/session-sending-consent'

export const PLUGIN_SESSION_CONSENT_SENT = 'plugin/session-consent-sent'

export const PLUGIN_SESSION_CONSENT_FAILED = 'plugin/session-consent-failed'

export const PLUGIN_SESSION_CACHE_VIDEO_PROCESSING_CONSENT =
  'plugin/session-cache-video-processing-consent'

export const fetchPlugin = id => async (dispatch, getState) => {
  dispatch({ type: FETCH_PLUGIN_CONFIG_START })

  try {
    Plugin.warmUp().catch(() => {
      /* suppress "uncaught in promise" console message */
    })
  } catch (_) {
    /* ignore warm up errors */
  }

  try {
    const config = await Plugin.getConfig(id)
    const { questionnaire, ...plugin } = config
    const sortedQuestions = questionnaire && sortQuestions(questionnaire.questions)

    // check for users that use the recorder with a recording request, whether they
    // have already completed a recording session that has consent
    const state = getState()
    const recordingRequestExtId = selectOption('session')(state)
    let hasCompletedSession = false

    if (recordingRequestExtId) {
      hasCompletedSession = await Plugin.checkForExistingSession(recordingRequestExtId)
    }

    dispatch({
      type: FETCH_PLUGIN_CONFIG_SUCCESS,
      payload: {
        ...plugin,
        hasCompletedSession,
        questionnaire: questionnaire
          ? { ...questionnaire, questions: sortedQuestions }
          : null,
      },
    })
  } catch (error) {
    if (error === 'Recorder is not available') {
      dispatch({ type: FETCH_PLUGIN_CONFIG_NOT_AVAILABLE })
    } else {
      dispatch({ type: FETCH_PLUGIN_CONFIG_FAILURE })
    }

    // TODO show error to user
    Log.error(new Error('Recorder plugin Error'), { extra: { cause: error } })
  }
}

export const cacheVideoProcessingConsent = videoProcessingConsent => async dispatch => {
  dispatch({
    type: PLUGIN_SESSION_CACHE_VIDEO_PROCESSING_CONSENT,
    payload: videoProcessingConsent,
  })
}

export const sendConsent =
  (videoProcessingConsent, companyConsent) => async (dispatch, getState) => {
    dispatch({
      type: PLUGIN_SESSION_SENDING_CONSENT,
    })

    const state = getState()
    const sessionId = selectSessionId(state)

    if (typeof sessionId === 'undefined') {
      dispatch({ type: PLUGIN_SESSION_CONSENT_FAILED })
      throw new Error(`Saving consent without session id available`)
    }

    try {
      await Plugin.sendConsent(sessionId, videoProcessingConsent, companyConsent)

      dispatch({ type: PLUGIN_SESSION_CONSENT_SENT })
    } catch (error) {
      console.error('Unable to send user consent!', error)
      dispatch({ type: PLUGIN_SESSION_CONSENT_FAILED })
    }
  }

export const finishSession = recordings => async (dispatch, getState) => {
  const state = getState()
  const options = selectOptions(state)
  const analysisIds = recordings.map(({ analysisId }) => analysisId)

  dispatch({
    type: PLUGIN_SESSION_FINISHING,
    payload: analysisIds,
  })

  try {
    // `Plugin.approveRecording` is actually very misleading here. It is not
    // a "recording" in a sense of a "single video", but the whole "recording session".
    // The correct function name should be "approveRecordingSession". This is also
    // why we pass multiple analysis id's instead of just one.
    const session = await Plugin.approveRecording({
      analysisIds,
      meta: options.meta,
      pluginId: options.pluginId,
      session: options.session,
      tags: options.tags,
    })

    dispatch({ type: PLUGIN_SESSION_FINISHED, payload: session.id })
  } catch (error) {
    dispatch({ type: PLUGIN_SESSION_FINISH_FAILED })
    throw new Error(error)
  }
}

export const startSession = () => async dispatch => {
  dispatch({ type: PLUGIN_SESSION_STARTING })

  try {
    const analysis = await Analyzer.createAnalysis()

    dispatch({
      type: PLUGIN_SESSION_STARTED,
      payload: {
        analysisId: analysis.id,
      },
    })
  } catch (error) {
    console.error(error)
    dispatch({ type: PLUGIN_SESSION_START_FAILED })
  }
}
