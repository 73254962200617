/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Heading, Input, Text } from '@chakra-ui/react'
import { Field, useField } from 'formik'
import { CSSProperties, Fragment, PropsWithChildren } from 'react'

import { useIntl } from '../../common/FormattedMessage'

const validateField = (errorText: string) => (value: any) => {
  let error

  // here we don't punish empty string, that check is handled separately based on the requirements
  if (!/^$|^[^\s]+(\s+[^\s]+)*$/.test(value)) {
    error = errorText
  }

  return error
}

type FormikFormFieldProps = {
  errors?: string
  name: string
  label?: string
  subLabel?: string
  disabled?: boolean
  placeholder?: string
  minLength?: number
  maxLength?: number
  as?: any
  component?: any
  rows?: number
  cols?: number
  size?: string
  customValue?: any
  width?: string
  style?: CSSProperties
}

type FormikFormFieldHeaderProps = {
  label?: FormikFormFieldProps['label']
  subLabel?: FormikFormFieldProps['subLabel']
}

type FormikFormFieldErrorBoxProps = {
  errors?: FormikFormFieldProps['errors']
  touched?: boolean
}

export const FormikFormFieldHeader = ({
  label,
  subLabel,
}: FormikFormFieldHeaderProps) => (
  <Fragment>
    {label && (
      <Heading as="h6" color="gray.600" margin="3px" size="xs">
        {label}
      </Heading>
    )}
    {subLabel && (
      <Text mx="3px" my={2} size="xs">
        {subLabel}
      </Text>
    )}
  </Fragment>
)

export const FormikFormFieldErrorBox = ({
  touched,
  errors,
}: FormikFormFieldErrorBoxProps) =>
  touched &&
  errors && (
    <Box p={1} color="red">
      {errors}
    </Box>
  )

export const FormikFormField = ({
  errors,
  name,
  label,
  subLabel,
  disabled,
  placeholder,
  minLength = 0,
  maxLength = 255,
  as = Input,
  rows,
  cols,
  size = 'md',
  component,
  customValue,
  children,
  width,
  style,
}: PropsWithChildren<FormikFormFieldProps>) => {
  const { formatMessage } = useIntl()
  const [_, { touched }] = useField(name)
  // TODO create wrapper classes for the different input field types (text, number, date, etc)
  // -> Reduce the number of input properties that are passed to the input field
  // This is used for checkbox groups where you can't set an initialValue
  const withCustomValue = customValue ? { value: customValue } : {}

  // TODO validation must be more flexible. E.g. checkboxes don't require string validation
  const validateFieldIntl = validateField(
    formatMessage({ id: 'organization.settings.general.form.whiteSpace' }) || ''
  )

  return (
    <Fragment>
      <FormikFormFieldHeader label={label} subLabel={subLabel} />
      <Field
        placeholder={placeholder}
        style={{ backgroundColor: 'white' }}
        minLength={minLength}
        maxLength={maxLength}
        name={name}
        as={as}
        component={component}
        rows={rows}
        disabled={disabled}
        cols={cols}
        validate={validateFieldIntl}
        size={size}
        {...withCustomValue}
        width={width}
        borderRadius="sm"
        {...style}
      >
        {children}
      </Field>
      <FormikFormFieldErrorBox touched={touched} errors={errors} />
    </Fragment>
  )
}
