import PropTypes from 'prop-types'

import style from './style.scss'

const MessageCard = ({ title, description }) => (
  <div className={style['message-card']}>
    <h2> {title} </h2>
    <p> {description} </p>
  </div>
)

MessageCard.propTypes = {
  description: PropTypes.node,
  title: PropTypes.node,
}

export default MessageCard
