import { ChakraProvider } from '@chakra-ui/react'
import { getUserLanguage, Log, Product, setConfig } from '@retorio/sdk'
import { theme } from '@retorio/ui'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { MemoryRouter as Router } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'

import App from './App'
import { AppContextProvider } from './AppContext'
import config from './config'
import store from './store'
import { setOptions } from './store/options'
import { toTagArray } from './utils/to-tag-array'

setConfig(config)

const DEFAULT_EVENT_HANDLERS = {
  onPrepareScreenContent: () => null,
  onPrepareFinalScreenContent: () => null, // deprecated - use onPrepareScreenContent
  onFlowFinished: Function.prototype,
}

function createRecorder(selector, options = { meta: {}, tags: [] }, events = null) {
  Log.init()
  Log.setTag('sdk', 'recorder')
  Log.setTag('locale', getUserLanguage(Product.RECRUITING))

  const target = document.querySelector(selector)
  const datasetProps = { meta: {}, tags: [] }

  if (target.dataset.tags) {
    datasetProps.tags = toTagArray(target.dataset.tags)
  }

  Object.entries(target.dataset).forEach(([key, value]) => {
    if (key.startsWith('meta')) {
      datasetProps.meta[key[4].toLowerCase() + key.substr(5)] = value
    } else if (key !== 'tags') {
      datasetProps[key] = value
    }
  })

  store.dispatch(
    setOptions({
      ...datasetProps,
      ...options,
      meta: { ...datasetProps.meta, ...options.meta },
      tags: [...new Set([...datasetProps.tags, ...(options.tags || [])])],
    })
  )

  if (process.env.E2E) {
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_STORE__ = store
  }

  const appEvents = {
    ...DEFAULT_EVENT_HANDLERS,
    ...events,
  }

  const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent)

  const root = createRoot(target)

  root.render(
    <Provider store={store}>
      <IntercomProvider appId={config.intercom?.appID} initializeDelay={5000}>
        <ChakraProvider resetScope="body" theme={theme}>
          <AppContextProvider isMobile={isMobile} appEvents={appEvents}>
            <Router>
              <App
                messages={options.messages}
                pluginId={options.pluginId || datasetProps.pluginId}
              />
            </Router>
          </AppContextProvider>
        </ChakraProvider>
      </IntercomProvider>
    </Provider>
  )
}

export { createRecorder, setConfig }
