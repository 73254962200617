import { useSelector } from 'react-redux'

import Container from '../../components/Container'
import CustomerContent from '../../components/CustomerContent'
import { FormattedMessage } from '../../components/FormattedMessage'
import { selectIsSessionSubmitted } from '../../store/plugin/selectors'
import { SCREENS } from '../../utils/constants'
import style from './Done.scss'

const Done = () => {
  const isSessionSubmitted = useSelector(selectIsSessionSubmitted)

  return (
    <Container>
      <CustomerContent screen={SCREENS.DONE} className={style.container}>
        <div className={style.explanations}>
          <p>
            <FormattedMessage id={`route.done.message.submitted.${isSessionSubmitted}`} />
          </p>
        </div>
      </CustomerContent>
    </Container>
  )
}

export default Done
