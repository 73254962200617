import { Dictionary } from './common.types'

/**
 * Returns the names of the enumerable string properties and methods of an object.
 * Uses `Object.keys` under the hood.
 */
export const keys = <T extends Record<string, unknown>>(obj: T): ReadonlyArray<keyof T> =>
  Object.keys(obj)

export const mapObjToArray = <T, TResult, TKey extends string>(
  fn: (value: T, key: TKey, index: number) => TResult,
  obj: Record<TKey, T>
): Array<TResult> =>
  Object.entries<T>(obj).map(([key, value], index) => fn(value, key as TKey, index))

export type TransformQueryParams = Dictionary

/**
 * A simple function to transform a query to a string (required for Sentry).
 *
 * { foo: 'bar', sort: 'desc' } -> "foo:bar,sort:desc"
 *
 * @param {*} query
 * @returns {string}
 */
export const transformQuery = (query: TransformQueryParams = {}): string =>
  Object.entries(query)
    .reduce((output: Array<string>, [key, val]) => [...output, `${key}:${val}`], [])
    .join(',')
