import {
  FETCH_PLUGIN_CONFIG_SUCCESS,
  PLUGIN_SESSION_FINISHED,
  PLUGIN_SESSION_STARTED,
} from '../plugin/actions'
import {
  RECORDING_FINISH,
  RECORDING_NEXT,
  RECORDING_RESTART,
  RECORDING_RETRY,
  RECORDING_START,
  RECORDING_STOP,
  RECORDING_USER_VIDEO_SELECTED,
  RECORDING_USER_VIDEO_UPLOAD_FAILED,
  RECORDING_USER_VIDEO_UPLOADED,
  RECORDING_USER_VIDEO_UPLOADING,
} from './actions'

const selectActiveRecordingIndex = state => state.findIndex(({ active }) => active)

const selectNextRecordingIndex = state => selectActiveRecordingIndex(state) + 1

const createEntity = ({
  questionId = null,
  analysisId = null,
  blob = null,
  running = false,
  uploading = false,
  active = false,
  attempts = 0,
  done = false,
  videoUrl = null,
} = {}) => ({
  questionId,
  analysisId,
  blob,
  running,
  uploading,
  active,
  attempts,
  done,
  videoUrl,
})

const createState = (state, recordingIndex, changes = {}) =>
  [...state].map((recording, index) => {
    const props = typeof changes === 'function' ? changes(recording) : changes

    return index === recordingIndex ? createEntity({ ...recording, ...props }) : recording
  })

const createActiveRecordingState = (state, changes = {}) =>
  createState(state, selectActiveRecordingIndex(state), changes)

const initialState = []

// Every question is a recording. The recording is done via, file upload,
// or by actually live recording yourself through a camera. `recordings` makes
// sure that every recording has the correct data associated with it, like
// the analysis id (which changes after every retry) or question id.
//
// The whole recorder flow is now done via redux actions.
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PLUGIN_CONFIG_SUCCESS: {
      const { questionnaire } = payload

      if (questionnaire) {
        return questionnaire.questions.map(({ id: questionId }) =>
          createEntity({ questionId })
        )
      }

      // In case we have no questionnaire, we create an array with a single entity
      return [createEntity()]
    }

    case PLUGIN_SESSION_STARTED: {
      const { analysisId } = payload

      return createState(state, 0, {
        analysisId,
        active: true,
      })
    }

    // After the last recording, there is not "RECORDING_NEXT" call, and thus
    // would leave the last recording in an inconsistent state. Usually it's not
    // important, since nothing happens after that, but for the sake of consis-
    // tency we'll do it.
    case PLUGIN_SESSION_FINISHED:
      return createActiveRecordingState(state, {
        active: false,
        done: true,
      })
    case RECORDING_FINISH:
      return createActiveRecordingState(state, {
        running: false,
        done: true,
      })
    case RECORDING_START:
      return createActiveRecordingState(state, recording => ({
        attempts: recording.attempts + 1,
        running: true,
      }))
    case RECORDING_RESTART:
    case RECORDING_RETRY: {
      const { analysisId } = payload

      return createActiveRecordingState(state, ({ attempts }) => ({
        analysisId,
        blob: null,
        running: false,
        attempts: type === RECORDING_RESTART ? attempts - 1 : attempts,
        videoUrl: null,
      }))
    }

    case RECORDING_STOP:
      return createActiveRecordingState(state, { running: false })
    case RECORDING_USER_VIDEO_SELECTED: {
      const { video, videoUrl } = payload

      return createActiveRecordingState(state, {
        blob: video,
        videoUrl,
      })
    }

    case RECORDING_USER_VIDEO_UPLOADING: {
      return createActiveRecordingState(state, { uploading: true })
    }

    case RECORDING_USER_VIDEO_UPLOAD_FAILED: {
      return createActiveRecordingState(state, { uploading: false })
    }

    case RECORDING_USER_VIDEO_UPLOADED:
      return createActiveRecordingState(state, {
        done: true,
        uploading: false,
      })
    // It's important that only `RECORDING_NEXT` sets the old recording to inactive
    // and activates the new recording. The recorder flow expects that at least one
    // recording is active at all times. The only exceptions are non-recorder/player
    // routes (obviously).
    case RECORDING_NEXT: {
      const { analysisId } = payload

      return createState(
        createActiveRecordingState(state, { active: false }),
        selectNextRecordingIndex(state),
        { active: true, analysisId }
      )
    }

    default:
      return state
  }
}
