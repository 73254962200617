import PropTypes from 'prop-types'
import { Component } from 'react'

import Icon from '../icon'
import style from './style.scss'

export default class PlayButton extends Component {
  static defaultProps = {
    playing: false,
  }

  static propTypes = {
    onPause: PropTypes.func.isRequired,
    onPlay: PropTypes.func.isRequired,
    playing: PropTypes.bool,
  }

  onClick = event => {
    if (this.props.playing) {
      this.props.onPause(event)
    } else {
      this.props.onPlay(event)
    }
  }

  render() {
    return (
      <button className={style['play-button']} onClick={this.onClick} type="button">
        {/* eslint-disable-next-line react/destructuring-assignment */}
        <Icon name={this.props.playing ? 'pause' : 'play'} />
      </button>
    )
  }
}
