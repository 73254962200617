import { Flex, Icon } from '@chakra-ui/react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import Button from './Button'
import style from './IconButton.scss'

const IconButton = ({ className, icon, size = 'md', ...other }) => (
  <Button
    className={cx(style.btn, style[`btn-${size}`], className)}
    size={size}
    {...other}
  >
    <Flex w="100%" h="100%" justifyContent="center" alignItems="center">
      <Icon as={icon} />
    </Flex>
  </Button>
)

IconButton.propTypes = {
  className: PropTypes.any,
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['md', 'sm']),
}

export default IconButton
