import { getUserLanguage, Product } from '@retorio/sdk'
import { DeviceProvider, IntlProvider } from '@retorio/shared-components'
import * as Sentry from '@sentry/react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'

import style from './App.scss'
import Dialog from './components/Dialog'
import { ErrorDialog, ErrorStates } from './components/ErrorDialog'
import PluginRoute from './components/PluginRoute'
import RecorderTechCheck from './components/RecorderTechCheck'
import { RetorioIntercom } from './components/RetorioIntercom'
import RouteNotFoundError from './components/RouteNotFoundError'
import Spinner from './components/Spinner'
import Consent from './routes/consent'
import Done from './routes/done'
import Intro from './routes/intro'
import Player from './routes/player'
import Prerequisites from './routes/prerequisites'
import Recorder from './routes/recorder'
import Submit from './routes/submit'
import Terms from './routes/terms'
import { setLocale } from './store/intl/effects'
import { selectLocale, selectMessages } from './store/intl/selectors'
import { fetchPlugin } from './store/plugin'
import {
  selectGdprIsRequired,
  selectHasCompletedSession,
  selectHasLoadedPlugin,
  selectHasValidGDPRData,
  selectIsNotActivePlugin,
} from './store/plugin/selectors'
import { setMomentLocale } from './utils'

const App = ({ pluginId }) => {
  const dispatch = useDispatch()
  const dialog = useSelector(state => state.dialog)
  const locale = useSelector(selectLocale)

  // Moment needs to be set to the cached user language.
  // Otherwise moment locale falls back to English upon page reload.
  setMomentLocale(locale)

  const messages = useSelector(selectMessages)

  const gdprRequired = useSelector(selectGdprIsRequired)
  const hasValidGDPRData = useSelector(selectHasValidGDPRData)
  const isNotActiveInterview = useSelector(selectIsNotActivePlugin)
  const isMissingGDPR = gdprRequired && !hasValidGDPRData
  const hasLoaded = useSelector(selectHasLoadedPlugin)
  const hasRecordingSessionWithConsent = useSelector(selectHasCompletedSession)

  let errorState

  switch (true) {
    case isNotActiveInterview:
      errorState = ErrorStates.RECORDER_INACTIVE
      break
    case isMissingGDPR:
      errorState = ErrorStates.MISSING_GDPR
      break
    case hasRecordingSessionWithConsent:
      errorState = ErrorStates.SESSION_ALREADY_EXISTS
      break
    default:
      errorState = null
  }

  useEffect(() => {
    dispatch(fetchPlugin(pluginId))
  }, [dispatch, pluginId])

  useEffect(() => {
    dispatch(setLocale(getUserLanguage(Product.RECRUITING)))
  }, [dispatch])

  return hasLoaded ? (
    <IntlProvider locale={locale} messages={messages}>
      <RetorioIntercom />
      <div className={style.recorder}>
        {errorState && <ErrorDialog error={errorState} />}
        {!errorState && (
          <Switch>
            <PluginRoute exact path="/" component={Intro} />
            <PluginRoute path="/consent" component={Consent} />
            <PluginRoute path="/terms" component={Terms} />

            <DeviceProvider>
              <PluginRoute path="/prerequisites" component={Prerequisites} />
              <PluginRoute path="/tech-check" component={RecorderTechCheck} />
              <PluginRoute path="/recorder" component={Recorder} />
              <PluginRoute path="/player" component={Player} />
              <PluginRoute path="/submit" component={Submit} />
              <PluginRoute path="/done" component={Done} />
            </DeviceProvider>

            <Route component={RouteNotFoundError} />
          </Switch>
        )}
        {dialog && <Dialog error={dialog.type === 'error'}>{dialog.content}</Dialog>}
      </div>
    </IntlProvider>
  ) : (
    <Spinner color="primary" size="xlarge" />
  )
}

App.propTypes = {
  pluginId: PropTypes.string.isRequired,
}

export default withRouter(Sentry.withProfiler(App, { name: 'Recorder' }))
