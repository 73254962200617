import { Player as BasePlayer } from '@retorio/player'
import { config, maxVideoLength } from '@retorio/sdk'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { showDialog, showError } from '../store/dialog'
import { selectQuestion } from '../store/plugin/selectors'
import { retryRecording, uploadVideo } from '../store/recordings'
import { selectActiveRecording } from '../store/recordings/selectors'
import ApproveButton from './ApproveButton'
import ExceededTriesError from './ExceededTriesError'
import FatalError from './FatalError'
import InsufficientLengthError from './InsufficientLengthError'
import { RecorderState } from './MainRecorder'
import style from './Player.scss'
import RecordButton from './RecordButton'
import RecorderSpinner from './RecorderSpinner'
import RetryButton from './RetryButton'
import VideoUploadError from './VideoUploadError'

const Player = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  // It is not 100% guaranteed that the video is shown and ready to use. In case no meta
  // information is available, we hide our actions.
  const [videoMeta, setVideoMeta] = useState(null)
  const [hasAudio, setHasAudio] = useState(false)
  const [hasVideo, setHasVideo] = useState(false)
  const [isLoadingVideo, setIsLoadingVideo] = useState(true)
  const activeRecording = useSelector(selectActiveRecording)
  const question = useSelector(state => selectQuestion(state, activeRecording.questionId))
  const timeLimit = question ? question.timeLimit : maxVideoLength
  const { attempts, uploading, videoUrl } = activeRecording
  const handleRetry = () => {
    if (question && question.repeatable > 0 && attempts >= question.repeatable) {
      dispatch(showDialog(<ExceededTriesError tries={question.repeatable} />))
    } else {
      dispatch(retryRecording())
      history.push('/recorder')
    }
  }

  const handleApprove = () => uploadVideo(dispatch, history)

  // Normally we would have to use `restartRecording`, to make sure the `attempts` count
  // is resetted correctly. But since we don't count attempts for uploadings, we use
  // `handleRetry` as it does everything we need.
  const handleMetaReady = meta => {
    setVideoMeta(meta)

    // We must ensure that we don't exceed maximum length supported by AI
    // That is why we cannot use Math.floor for this check
    const supportedByAI = meta.duration < 300
    const intDuration = Math.floor(meta.duration)

    if (intDuration > timeLimit || !supportedByAI) {
      dispatch(
        showError(
          <VideoUploadError type="duration" maxLength={timeLimit} onClose={handleRetry} />
        )
      )
    } else if (intDuration < Math.min(timeLimit, config.minRecordingTime)) {
      dispatch(showDialog(<InsufficientLengthError />))
      handleRetry()
    }
  }

  useEffect(() => {
    const error = []

    if (hasAudio === false && !isLoadingVideo) {
      error.push('audio')
    }

    if (hasVideo === false && !isLoadingVideo) {
      error.push('video')
    }

    if (error.length > 0) {
      dispatch(showError(<FatalError errorRecorder={error} />))
      history.push('/recorder')
    }
  }, [hasAudio, isLoadingVideo, hasVideo])

  const playerBtns =
    uploading || !videoMeta ? (
      <RecordButton disabled state={RecorderState.UPLOADING} />
    ) : (
      <Fragment>
        <RetryButton onClick={handleRetry} />
        <ApproveButton disabled={isLoadingVideo} onClick={handleApprove} />
      </Fragment>
    )

  return uploading ? (
    <RecorderSpinner width="100%" height="100%" />
  ) : (
    <BasePlayer
      className={style.player}
      playerFooter={playerBtns}
      videoUrl={videoUrl}
      onMetaReady={handleMetaReady}
      hasAudioTracks={setHasAudio}
      hasVideoTracks={setHasVideo}
      onVideoLoaded={setIsLoadingVideo}
    />
  )
}

export default Player
