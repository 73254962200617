import PropTypes from 'prop-types'

import Container from '../container'
import MessageCard from '../message-card'

const Error = ({ title, description }) => (
  <Container main={<MessageCard title={title} description={description} />} />
)

Error.propTypes = {
  description: PropTypes.node,
  title: PropTypes.node,
}

export default Error
