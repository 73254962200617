import { Icon } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { FaCamera, FaCheck, FaPlay } from 'react-icons/fa'

import Button from '../../components/Button'
import Container from '../../components/Container'
import CustomerContent from '../../components/CustomerContent'
import { FormattedMessage } from '../../components/FormattedMessage'
import { SCREENS } from '../../utils/constants'
import style from './Terms.scss'

const TermsContainer = ({ history }) => (
  <Container
    actions={[
      <Button
        key="continueBtn"
        variant="primary"
        data-cy="continue-button"
        onClick={() => history.push('/prerequisites')}
      >
        <FormattedMessage id="route.terms.continueBtn" />
      </Button>,
    ]}
    data-cy-route={SCREENS.TERMS}
  >
    <center style={{ marginTop: '70px' }}>
      <FormattedMessage id="error.Camera.mic.description" />
      <br />
    </center>
    <CustomerContent screen={SCREENS.TERMS} className={style.container}>
      <div className={style.itemContainer}>
        <div className={style.item}>
          <div style={{ marginBottom: '50px' }} className={style.icon}>
            <Icon as={FaCamera} />
          </div>
          <h4>
            <FormattedMessage id="route.terms.camera.title" />
            <br />
            <small>
              <FormattedMessage id="route.terms.camera.description" />
              <br />
              <a
                style={{ marginRight: '5px' }}
                href="https://support.google.com/chrome/answer/2693767"
                target="_blank"
                rel="noreferrer"
              >
                Chrome
              </a>
              <a
                style={{ marginRight: '5px' }}
                href="https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions"
                target="_blank"
                rel="noreferrer"
              >
                Firefox
              </a>
              <a
                href="https://support.apple.com/en-gb/guide/safari/ibrwe2159f50/mac"
                target="_blank"
                rel="noreferrer"
              >
                Safari
              </a>
            </small>
          </h4>
        </div>
        <div className={style.item}>
          <div className={style.icon}>
            <Icon as={FaCheck} />
          </div>
          <h4>
            <FormattedMessage id="route.terms.continue.title" />
          </h4>
        </div>
        <div className={style.item}>
          <div className={style.icon}>
            <Icon as={FaPlay} />
          </div>
          <h4>
            <FormattedMessage id="route.terms.strat.title" />
            <br />
          </h4>
        </div>
      </div>
    </CustomerContent>
  </Container>
)

TermsContainer.propTypes = {
  history: PropTypes.object.isRequired,
}

export default TermsContainer
