export const HIDE_DIALOG = 'dialog/hide-dialog'

export const SHOW_DIALOG = 'dialog/show-dialog'

export const SHOW_ERROR = 'dialog/show-error'

export const hideDialog = () => ({ type: HIDE_DIALOG })

const defaultOptions = { error: false }

export const showDialog = (content, options = defaultOptions) => ({
  content,
  type: options.error ? SHOW_ERROR : SHOW_DIALOG,
})

export const showError = content => showDialog(content, { error: true })
