import api from './api'

export default class Plugin {
  static getConfig(pluginId) {
    return api.plugins.get(pluginId)
  }

  static approveRecording(params) {
    return api.recordings.create(params)
  }

  static sendConsent(recordingSessionId, videoProcessingConsent, companyConsent) {
    return api.recordings.sendConsent(recordingSessionId, {
      videoProcessingConsent,
      companyConsent,
    })
  }

  static checkForExistingSession(recordingRequestExtId) {
    return api.recordings.getHasCompletedSession(recordingRequestExtId)
  }

  static warmUp() {
    return api.analysis.warmUp()
  }
}
