import { assign, createMachine, TransitionConfigOrTarget } from 'xstate'

export type VideoPlayerMachineContext = {
  videoEl: HTMLVideoElement | null
  duration: number
  autoPlayError: boolean
}

type SeekEvent = { type: 'SEEK'; time: number }

export type VideoPlayerMachineEvent =
  | {
      type: 'LOADED'
      videoEl: HTMLVideoElement
      duration: number
    }
  | SeekEvent
  | { type: 'LOAD' }
  | { type: 'FAIL' }
  | { type: 'PLAY' }
  | { type: 'PLAY-OK' }
  | { type: 'PLAY-MUTED' }
  | { type: 'UNMUTE' }
  | { type: 'PAUSE' }
  | { type: 'END' }
  | { type: 'STOP' }

export const playVideo = async (
  { videoEl: mutableVideo }: VideoPlayerMachineContext,
  onSuccess?: () => void,
  onError?: (e: Error) => void
): Promise<Partial<VideoPlayerMachineContext>> => {
  if (mutableVideo) {
    try {
      await mutableVideo.play()
      onSuccess?.()
    } catch (error) {
      if (error instanceof Error) {
        mutableVideo.muted = true
        mutableVideo.play()
        onError?.(error)
      }
    }
  }

  return {}
}

const pauseVideo = ({ videoEl }: VideoPlayerMachineContext) => videoEl?.pause()
const unmuteVideo = ({ videoEl: mutableVideoEl }: VideoPlayerMachineContext) => {
  if (mutableVideoEl) {
    mutableVideoEl.muted = false
  }
}

const stopVideo = ({ videoEl: mutableVideoEl }: VideoPlayerMachineContext) => {
  if (mutableVideoEl) {
    mutableVideoEl.pause()
  }
}

const seekVideo: TransitionConfigOrTarget<VideoPlayerMachineContext, SeekEvent> = [
  {
    actions: (mutableContext, event) => {
      if (mutableContext.videoEl) {
        mutableContext.videoEl.currentTime = event.time
      }
    },
  },
]

// TODO Typing doesn't work
// https://github.com/statelyai/xstate/issues/2232
export const videoPlayerMachine = createMachine<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  VideoPlayerMachineContext,
  VideoPlayerMachineEvent
>({
  id: 'videoPlayerMachine',
  initial: 'loading',
  context: {
    videoEl: null,
    duration: 0,
    // elapsed: 0,
    autoPlayError: false,
  },
  states: {
    loading: {
      id: 'loading',
      on: {
        LOADED: {
          target: 'ready',
          actions: assign({
            videoEl: (_context, event) => event.videoEl,
            duration: (_context, event) => event.duration,
          }),
        },
        FAIL: 'failure',
      },
    },
    ready: {
      initial: 'paused',
      on: {
        SEEK: seekVideo,
      },
      states: {
        paused: {
          on: {
            PLAY: {
              actions: ['playVideo'],
            },
            'PLAY-OK': 'playing',
            'PLAY-MUTED': 'playing-muted',
          },
        },
        playing: {
          on: {
            PAUSE: {
              target: 'paused',
              actions: pauseVideo,
            },
            END: {
              target: 'ended',
              actions: stopVideo,
            },
            STOP: 'ended',
          },
        },
        'playing-muted': {
          on: {
            UNMUTE: {
              target: 'playing',
              actions: unmuteVideo,
            },
            PAUSE: {
              target: 'paused',
              actions: pauseVideo,
            },
            END: {
              target: 'ended',
              actions: stopVideo,
            },
            STOP: 'ended',
          },
        },
        ended: {
          on: {
            PLAY: 'playing',
            LOAD: '#loading',
          },
        },
      },
    },
    failure: { type: 'final' },
  },
})
