import { get } from 'lodash-es'

export const selectPlugin = state => state.plugin

export const selectHasLoadedPlugin = state => selectPlugin(state).hasLoaded

export const selectIsNotActivePlugin = state => selectPlugin(state).isNotActive === true

export const selectOrganization = state => selectPlugin(state).organization

export const selectOrganizationId = state => selectOrganization(state).id

export const selectPluginId = state => selectPlugin(state).id

export const selectSessionId = state => selectPlugin(state).recordingSessionId

export const selectQuestionnaire = state => selectPlugin(state).questionnaire

export const selectPreventUpload = state => selectPlugin(state).preventUpload

export const selectIsSessionSubmitted = state => !!selectPlugin(state).isSessionSubmitted

export const selectQuestionsFromQuestionnaire = state => {
  const questionnaire = selectQuestionnaire(state)

  return questionnaire ? questionnaire.questions : []
}

export const selectQuestion = (state, questionId) =>
  selectQuestionsFromQuestionnaire(state).find(({ id }) => id === questionId)

export const selectNextQuestion = (state, currentQuestionId) => {
  const questions = selectQuestionsFromQuestionnaire(state)
  const currentQuestionIndex = questions.findIndex(({ id }) => id === currentQuestionId)

  return questions[currentQuestionIndex + 1]
}

export const selectQuestionIndex = (state, questionId) =>
  selectQuestionsFromQuestionnaire(state).findIndex(({ id }) => id === questionId)

export const selectGdprIsRequired = state => {
  const plugin = selectPlugin(state)

  if (!get(plugin, ['organization_settings', 'gdprReviewed'], false)) {
    return false
  }

  return get(plugin, ['organization', 'gdprEnabled'], false)
}

export const selectGdprCompany = state =>
  get(selectPlugin(state), ['organization', 'gdprCompany'], {})

export const selectHasValidGDPRData = state => {
  const gdprCompany = selectGdprCompany(state)
  const companyProps = gdprCompany ? Object.keys(gdprCompany) : []

  const hasValidGDPRCompanyData =
    !!companyProps.length &&
    companyProps.reduce((isValid, prop) => {
      if (!isValid) {
        return false
      }

      return !!gdprCompany[prop]
    }, true)

  const privacyPolicy = get(selectPlugin(state), ['organization', 'privacyPolicy'], false)
  const hasPrivacyPolicy = privacyPolicy !== false

  return hasValidGDPRCompanyData && hasPrivacyPolicy
}

export const selectHasCompletedSession = state => selectPlugin(state).hasCompletedSession
