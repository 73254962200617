import {
  forwardRef as chakraForwardRef,
  HTMLChakraProps,
  SystemStyleObject,
  Tr,
} from '@chakra-ui/react'

const TrStyle: SystemStyleObject = {
  _hover: {
    bg: 'gray.100',
    cursor: 'pointer',
    transitionDuration: '80ms',
    transitionProperty: 'background-color',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  },
}

export type ClickableTrProps = HTMLChakraProps<'tr'>

/**
 * Chakra UI `Tr` with hover styles.
 */
export const ClickableTr = chakraForwardRef<ClickableTrProps, 'tr'>(
  ({ onClick, children, ...rest }, ref) => (
    <Tr onClick={onClick} sx={TrStyle} {...rest} ref={ref}>
      {children}
    </Tr>
  )
)
