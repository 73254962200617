/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled'
import { PropsWithChildren, useContext } from 'react'

import { defaultCssTransition } from './Sidebar.defaults'
import { isExpanded, SidebarContext } from './Sidebar.utilities'

type HeaderCssProps = {
  expanded: boolean
}

const Header = styled.h4<HeaderCssProps>`
  color: ${props => (props.theme as any).colors['primary-dark']};
  font-size: ${props => (props.theme as any).fontSizes.sm};
  letter-spacing: ${props => (props.theme as any).letterSpacings.widest};
  text-transform: uppercase;
  white-space: nowrap;
  opacity: ${props => (props.expanded ? 1 : 0)};
  transition: opacity ${defaultCssTransition};
`

const Container = styled.div<HeaderCssProps>`
  cursor: default;
  padding: 0 10px;
`

export const SidebarNavGroupTitle = ({ children }: PropsWithChildren) => {
  const { state } = useContext(SidebarContext)
  const expanded = isExpanded(state)

  return (
    <Container role="presentation" expanded={expanded}>
      <Header expanded={expanded}>{children}</Header>
    </Container>
  )
}
