import { Player as BasePlayer } from '@retorio/player'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { hideDialog, showError } from '../store/dialog'
import Button from './Button'
import { FormattedMessage } from './FormattedMessage'
import style from './Question.scss'

/*
  Here we store information on what video
  questions have been shown automatically
  and should not be shown again
 */
const shownQuestions = {}

const Question = ({ current: currentQuestion, attempts }) => {
  const dispatch = useDispatch()
  const isVideoQuestion =
    currentQuestion && currentQuestion.note && currentQuestion.note.includes('https://')

  const autoPlay = ({ play }) => play()
  const hideVideQuestion = () => dispatch(hideDialog())
  const showVideoQuestion = (question, { isAutorun } = {}) => {
    if (isAutorun) {
      if (shownQuestions[question.id]) {
        return
      }

      shownQuestions[question.id] = true
    }

    // we use showError to hide X button on top and to prevent "close on outside click"
    dispatch(
      showError(
        <div className={style.container}>
          <h3 className={style.title}>{question.text}</h3>
          <div className={style['video-question']}>
            <BasePlayer
              className={style.player}
              videoUrl={question.note}
              onReady={autoPlay}
              onFinish={hideVideQuestion}
            />
          </div>
          <h3 className={style.title}>
            <Button onClick={hideVideQuestion}>
              <FormattedMessage id="videoQuestion.answer" />
            </Button>
          </h3>
        </div>
      )
    )
  }

  useEffect(() => {
    if (isVideoQuestion) {
      showVideoQuestion(currentQuestion, { isAutorun: true })
    }
  }, [currentQuestion])

  if (!currentQuestion) {
    return null
  }

  const hasTries = currentQuestion.repeatable !== 0
  const triesLeft = hasTries ? currentQuestion.repeatable - attempts : Infinity

  return (
    <div className={style.container}>
      <h3 className={style.title}>
        {isVideoQuestion && (
          <Button
            onClick={() => showVideoQuestion(currentQuestion, { isAutorun: false })}
          >
            <FormattedMessage id="videoQuestion.watchAgain" />
          </Button>
        )}
        {!isVideoQuestion && currentQuestion.text}
        {!isVideoQuestion && currentQuestion.note && (
          <small>{currentQuestion.note}</small>
        )}
        {hasTries && (
          <div
            className={style['try-count']}
            data-cy="try-count-message"
            data-cy-prop-try-count={triesLeft}
          >
            <FormattedMessage
              id="recorder.question.limits"
              values={{
                tries: triesLeft,
                triesFormatted: <strong>{triesLeft}</strong>,
              }}
            />
          </div>
        )}
      </h3>
    </div>
  )
}

Question.propTypes = {
  current: PropTypes.object,
  attempts: PropTypes.number,
}

export default Question
