// Chakra Theme overrides
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/modal.ts

const modalTheme = {
  baseStyle: {
    overlay: {
      // TODO implement better looking blur which is compartible with all browsers
      '&:before': {
        content: '" "',
        display: 'block',
        height: '100vh',
        width: '100vw',
        backdropFilter: 'blur(20px)',
        opacity: '0.6',
        background: 'blue.700',
      },
    },
  },
}

export { modalTheme }
