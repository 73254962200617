import { curry } from '@retorio/common-utils'

import { SortOptions } from '../SortDirectionToggle'

export type Sortable = {
  value: string
  sortBy: SortOptions
}

export const eqByValue = curry((a: Sortable, b: Sortable) => a.value === b.value)

export const eqBySortOptions = curry((a: Sortable, b: Sortable) => a.sortBy === b.sortBy)
