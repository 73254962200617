import { Player as BasePlayer } from '@retorio/player'
import { config, Log, maxUploadSize, maxVideoLength } from '@retorio/sdk'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { uploadMediaFile } from '../firebase/firebase-storage'
import { showDialog, showError } from '../store/dialog'
import { selectDialog } from '../store/dialog/selectors'
import {
  selectOrganizationId,
  selectPluginId,
  selectPreventUpload,
  selectQuestion,
  selectQuestionIndex,
} from '../store/plugin/selectors'
import {
  finishRecording,
  restartRecording,
  retryRecording,
  setUserVideo,
  startRecording,
  stopRecording,
} from '../store/recordings'
import { selectActiveRecording } from '../store/recordings/selectors'
import ApproveButton from './ApproveButton'
import ExceededTriesError from './ExceededTriesError'
import FatalError from './FatalError'
import FirstTimeInfo from './FirstTimeInfo'
import InsufficientLengthError from './InsufficientLengthError'
import MainRecorder, {
  checkIsPlayerState,
  checkIsRecorderState,
  RecorderError,
  RecorderState,
} from './MainRecorder'
import stylePlayer from './Player.scss'
import RecordButton from './RecordButton'
import style from './Recorder.scss'
import RecorderSpinner from './RecorderSpinner'
import RetryButton from './RetryButton'
import Timer from './Timer'
import UploadButton from './UploadButton'
import VideoUploadError from './VideoUploadError'

// TODO: Show instructions if question is first question
// eslint-disable-next-line react/prop-types
const Recorder = ({ gauge, width, height }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const pluginId = useSelector(selectPluginId)
  const organizationId = useSelector(selectOrganizationId)
  const dialog = useSelector(selectDialog)
  const activeRecording = useSelector(selectActiveRecording)
  const question = useSelector(state => selectQuestion(state, activeRecording.questionId))
  const questionIndex = useSelector(state =>
    selectQuestionIndex(state, activeRecording.questionId)
  )

  const recorderRef = useRef(null)

  const [state, setState] = useState(null)
  const [recorderTime, setRecorderTime] = useState(0)
  const [errorRecorder, setErrorRecorder] = useState(null)
  const [recordedDuration, setRecordedDuration] = useState(0)
  const [triesExceeded, setTriesExceeded] = useState(false)
  const [startCountdown, setStartCountdown] = useState(false)
  const [isLoadingVideo, setIsLoadingVideo] = useState(true)
  const [hasAudio, setHasAudio] = useState(false)
  const [hasVideo, setHasVideo] = useState(false)

  const hasUploadPrevented = useSelector(selectPreventUpload)
  const timeLimit = question ? question.timeLimit : maxVideoLength
  const { analysisId, attempts, videoUrl } = activeRecording || {}

  Log.setTag('pluginId', pluginId)

  const handleResume = () => {
    recorderRef.current.reset()
    dispatch(restartRecording())
  }

  useEffect(() => {
    switch (state) {
      case RecorderState.ERROR:
        if (errorRecorder === RecorderError.noAccess) {
          history.push('/tech-check')
        }

        dispatch(showError(<FatalError errorRecorder={[errorRecorder]} />))
        handleResume()

        break
      case RecorderState.UPLOAD_SUCCESS:
        // After `handleApprove` the recording will be saved by recorder and will then
        // trigger a callback `onSaveOk`. This function here is executed when this happens.
        // We can't execute this code at `handleApprove` since it's not guaranteed that the
        // recording has been fully transferred.
        dispatch(finishRecording()).then(isFinished => {
          history.push(isFinished ? '/submit' : '/recorder')
        })

        break
      default:
        break
    }
  }, [state])

  const handleStart = async () => {
    await recorderRef.current.startRecording()
    dispatch(startRecording())
  }

  const handleStop = async () => {
    await recorderRef.current.stopRecording()

    if (recorderTime < Math.min(timeLimit, config.minRecordingTime)) {
      dispatch(showDialog(<InsufficientLengthError />))
      handleResume()

      return
    }

    if (attempts === 1 && questionIndex === 0) {
      dispatch(showDialog(<FirstTimeInfo />))
    }

    setRecordedDuration(recorderTime)

    dispatch(stopRecording())
  }

  const handleUpload = file => {
    if (file.size > maxUploadSize) {
      dispatch(showError(<VideoUploadError type="size" />))

      return
    }

    dispatch(setUserVideo(file))
    history.push('/player')
  }

  const uploadMedia = async videoBlob => {
    const videoGSUrl = await uploadMediaFile(videoBlob, organizationId, pluginId)

    return videoGSUrl
  }

  const setVideoBlob = video => {
    dispatch(setUserVideo(video))
  }

  const handleRetry = async () => {
    if (question && question.repeatable > 0 && attempts >= question.repeatable) {
      dispatch(showDialog(<ExceededTriesError tries={question.repeatable} />))
      setTriesExceeded(true)

      return
    }

    if (triesExceeded) {
      return
    }

    dispatch(retryRecording())

    recorderRef.current.reset()
  }

  const handleApprove = () => recorderRef.current.saveRecording()

  useEffect(() => {
    // Stop the recording if user has reached the time limit
    if (recorderTime >= timeLimit) {
      handleStop()
    }
  }, [timeLimit, recorderTime])

  useEffect(() => {
    /* reset `exceeded` flag when user navigates to next question */
    setTriesExceeded(false)
  }, [questionIndex])

  useEffect(() => {
    const error = []

    if (hasAudio === false && !isLoadingVideo) {
      error.push('audio')
    }

    if (hasVideo === false && !isLoadingVideo) {
      error.push('video')
    }

    if (error.length > 0) {
      dispatch(showError(<FatalError errorRecorder={error} />))
      handleResume()
      setIsLoadingVideo(true)
    }
  }, [hasAudio, isLoadingVideo])

  // Additional 10 seconds to prevent recorder crash due to pipe enforcing limits

  const isPlayerState = checkIsPlayerState(state)

  const isRecorderState = checkIsRecorderState(state)

  // Because the pipe recorder does playback without changing route, we'll
  // mark wether we're recording or playing as a data-cy prop
  const recorderOrPlayer = isPlayerState ? 'player' : 'recorder'

  const PlayerActions = (
    <Fragment>
      <RetryButton className={style.btn} disabled={triesExceeded} onClick={handleRetry} />
      <ApproveButton
        className={style.btn}
        disabled={isLoadingVideo}
        onClick={handleApprove}
      />
    </Fragment>
  )

  const handleStateChange = payload => {
    setState(payload.state)

    if (payload.state === RecorderState.ERROR) {
      setErrorRecorder(payload.error)
    }
  }

  return (
    <div
      className={style.recorder}
      data-cy-recorder-state={state}
      data-cy={recorderOrPlayer}
    >
      {analysisId && (
        <Fragment>
          <MainRecorder
            ref={recorderRef}
            payloadId={analysisId}
            product="recruiting"
            onStateChange={handleStateChange}
            setElapsedTimeCallback={setRecorderTime}
            setVideoBlobCallback={setVideoBlob}
            uploadMedia={uploadMedia}
            width={width}
            height={height}
          />
          <Fragment>
            {isRecorderState && gauge && (
              <div className={style.gauge}>
                <div className={style['gauge-left']} />
                <div className={style['gauge-right']} />
              </div>
            )}
            {isRecorderState && (
              <div className={style.actions}>
                <RecordButton
                  disabled={!!dialog || state === RecorderState.ERROR}
                  onResume={handleResume}
                  onStart={handleStart}
                  onStop={handleStop}
                  setStartCountdown={setStartCountdown}
                  state={state}
                />

                {[RecorderState.READY_TO_RECORD, RecorderState.ERROR].includes(state) &&
                  !hasUploadPrevented &&
                  !startCountdown && (
                    <UploadButton
                      className={style['upload-btn']}
                      onComplete={handleUpload}
                    />
                  )}
                <Timer
                  running={state === RecorderState.RECORDING}
                  className={style.timer}
                  duration={timeLimit}
                  current={!isPlayerState && recorderTime}
                />
              </div>
            )}
            {isPlayerState && recordedDuration && (
              <div className={style.play}>
                {state === RecorderState.UPLOADING ? (
                  <div>
                    <RecorderSpinner width={width} height={height} />
                  </div>
                ) : (
                  <BasePlayer
                    className={stylePlayer.player}
                    playerFooter={PlayerActions}
                    videoUrl={videoUrl}
                    hasAudioTracks={setHasAudio}
                    hasVideoTracks={setHasVideo}
                    onVideoLoaded={setIsLoadingVideo}
                    videoDuration={recordedDuration}
                    mirrorVideo
                  />
                )}
              </div>
            )}
          </Fragment>
        </Fragment>
      )}
    </div>
  )
}

export default Recorder
