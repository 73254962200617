import { createUseIntlHook } from '@retorio/shared-components'
import { useMemo } from 'react'

import en from '../../i18n/en.json'

const interpolateRegEx = /\$\{([a-zA-Z0-9_.[\]]+)\}/g

const interpolate = (value, payload, intl) => intl.formatMessage({ id: value }, payload)

export const useIntl = () => {
  const intlObject = createUseIntlHook(en)()

  const customIntl = () => ({
    ...intlObject,
    formatWithInterpolation: (text, payload = {}) =>
      text.replace(interpolateRegEx, (_, value) =>
        interpolate(value, payload, intlObject)
      ),
  })

  return useMemo(customIntl, [intlObject]) // return memoized object
}
