const defaultChunkDuration = 15
const maxVideoLength = 60
const defaultsOptions = {
  plugins: {
    record: {
      audio: true,
      video: {
        mandatory: {
          minWidth: 640,
          minHeight: 480,
        },
      },
      maxLength: Number.MAX_SAFE_INTEGER,
      timeSlice: defaultChunkDuration * 1000,
      frameWidth: 640,
      frameHeight: 480,
    },
  },
}

const maxVideoUploadSize = 200 * 1024 * 1024 // 200Mb
const maxImageUploadSize = 10 * 1024 * 1024 // 10Mb
const maxUploadSize = 900 * 1024 * 1024 // 900 Mb

export {
  defaultChunkDuration,
  defaultsOptions,
  maxImageUploadSize,
  maxUploadSize,
  maxVideoLength,
  maxVideoUploadSize,
}
