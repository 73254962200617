const types = {
  SET_LOCALE: 'intl/set_locale',
}

export const setLocale = val => ({
  type: types.SET_LOCALE,
  data: val,
})

export default types
