import { useDispatch } from 'react-redux'

import { hideDialog } from '../store/dialog'
import ApproveButton from './ApproveButton'
import Button from './Button'
import style from './FirstTimeInfo.scss'
import { FormattedMessage } from './FormattedMessage'
import RetryButton from './RetryButton'

// TODO: Save selection into redux-store when user closes dialog
const FirstTimeInfo = () => {
  const dispatch = useDispatch()

  return (
    <div className={style.container} data-cy="first-time-info">
      <h2>
        <FormattedMessage id="dialog.firstTime.title" />
      </h2>
      <p>
        <FormattedMessage
          id="dialog.firstTime.p.1"
          values={{ button: <ApproveButton disabled size="sm" /> }}
        />
      </p>
      <p>
        <FormattedMessage
          id="dialog.firstTime.p.2"
          values={{ button: <RetryButton disabled variant="default" size="sm" /> }}
        />
      </p>
      <div className={style.actions}>
        <Button
          variant="primary"
          onClick={() => dispatch(hideDialog())}
          data-cy="confirm-btn"
        >
          <FormattedMessage id="dialog.firstTime.gotItBtn" />
        </Button>
      </div>
    </div>
  )
}

export default FirstTimeInfo
