import PropTypes from 'prop-types'

import { FormattedMessage, useIntl } from './FormattedMessage'

const PrivacyLink = ({ children }) => {
  const { formatMessage } = useIntl()

  return (
    <a
      href={formatMessage({ id: 'privacyLink.url' })}
      target="_blank"
      rel="noopener noreferrer"
      data-cy="privacy-link"
    >
      {children || <FormattedMessage id="privacyLink.text" />}
    </a>
  )
}

PrivacyLink.propTypes = {
  children: PropTypes.node,
}

export default PrivacyLink
