import { Box, HStack, Icon, Spinner, Text, VStack } from '@chakra-ui/react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { AiFillInfoCircle } from 'react-icons/ai'
import { BsFillCircleFill } from 'react-icons/bs'

import {
  firebaseSpeedTestFile,
  NetworkType,
  uploadForSpeedTest,
} from '../firebase/firebase-storage'
import { FormattedMessage } from './FormattedMessage'

const enum NetworkSpeedEnum {
  veryLow = 'veryLow',
  low = 'low',
  stable = 'stable',
  fast = 'fast',
}

const getNetworkSpeed = (
  downloadSpeed: number,
  uploadSpeed: number
): NetworkSpeedEnum => {
  if (downloadSpeed < 10 || uploadSpeed < 5) {
    return NetworkSpeedEnum.veryLow
  }

  if (downloadSpeed >= 10 && downloadSpeed < 30 && uploadSpeed >= 5 && uploadSpeed < 10) {
    return NetworkSpeedEnum.low
  }

  if (
    downloadSpeed >= 10 &&
    downloadSpeed < 30 &&
    ((uploadSpeed >= 10 && uploadSpeed < 20) || uploadSpeed > 20)
  ) {
    return NetworkSpeedEnum.stable
  }

  if (downloadSpeed >= 30 && downloadSpeed < 50 && uploadSpeed >= 5 && uploadSpeed < 10) {
    return NetworkSpeedEnum.low
  }

  if (
    downloadSpeed >= 30 &&
    downloadSpeed < 50 &&
    ((uploadSpeed >= 10 && uploadSpeed < 20) || uploadSpeed > 20)
  ) {
    return NetworkSpeedEnum.stable
  }

  if (downloadSpeed >= 50 && uploadSpeed >= 20) {
    return NetworkSpeedEnum.fast
  }

  if (downloadSpeed >= 50 && uploadSpeed >= 10 && uploadSpeed < 20) {
    return NetworkSpeedEnum.stable
  }

  if (downloadSpeed >= 50 && uploadSpeed >= 5 && uploadSpeed < 10) {
    return NetworkSpeedEnum.low
  }

  return NetworkSpeedEnum.stable
}

const getNetworkStatusColor = (speed: NetworkSpeedEnum): string => {
  switch (speed) {
    case NetworkSpeedEnum.veryLow:
      return 'red.500'
    case NetworkSpeedEnum.low:
      return 'yellow.500'
    case NetworkSpeedEnum.stable:
      return 'green.200'
    case NetworkSpeedEnum.fast:
      return 'green.500'
    default:
      return 'green.200'
  }
}

type InternetSpeedMeterProps = {
  pingInterval?: number
  uuid: string
  getHasAccessToGCP?: (GCPAccess: boolean) => void
  getLoadingState?: (state: boolean) => void
}

const NetworkStatus = ({ downloadSpeed, uploadSpeed }) => {
  const speed = getNetworkSpeed(downloadSpeed, uploadSpeed)
  const color = getNetworkStatusColor(speed)

  return (
    <HStack spacing="3px">
      <Icon as={BsFillCircleFill} boxSize={3} color={color} />
      <Text fontSize="14px">
        {speed === NetworkSpeedEnum.veryLow && (
          <FormattedMessage id="route.terms.network.veryLow" />
        )}
        {speed === NetworkSpeedEnum.low && (
          <FormattedMessage id="route.terms.network.low" />
        )}
        {speed === NetworkSpeedEnum.stable && (
          <FormattedMessage id="route.terms.network.stable" />
        )}
        {speed === NetworkSpeedEnum.fast && (
          <FormattedMessage id="route.terms.network.fast" />
        )}
      </Text>
    </HStack>
  )
}

const DownloadUpload = ({ isLoading, downloadSpeed, uploadSpeed }) => {
  const speed = getNetworkSpeed(downloadSpeed, uploadSpeed)

  return (
    <Box
      display="flex"
      bgColor="gray.50"
      minW="270px"
      borderRadius="md"
      p={2}
      minH="60px"
      justifyContent="center"
      alignItems="center"
    >
      {isLoading ? (
        <Spinner size="sm" color="gray.500" />
      ) : (
        <Text fontSize="14px">
          {speed === NetworkSpeedEnum.veryLow && (
            <FormattedMessage id="route.terms.network.veryLow.text" />
          )}
          {speed === NetworkSpeedEnum.low && (
            <FormattedMessage id="route.terms.network.low.text" />
          )}
          {speed === (NetworkSpeedEnum.stable || NetworkSpeedEnum.fast) && (
            <FormattedMessage id="route.terms.network.stable.text" />
          )}
        </Text>
      )}
    </Box>
  )
}

export const InternetSpeedMeter = ({
  pingInterval = 2000,
  getHasAccessToGCP,
  getLoadingState,
  uuid,
}: InternetSpeedMeterProps) => {
  const [downloadSpeed, setDownloadSpeed] = useState(0)
  const [uploadSpeed, setUploadSpeed] = useState(0)
  const [isRunningTest, setIsRunningTest] = useState(false)
  const [hasAccessToGCP, setHasAccessToGCP] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  window.addEventListener('offline', () => setUploadSpeed(0))

  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const showResults = useCallback(
    (fileSize: number, startTime: number, endTime: number, checkType: NetworkType) => {
      const duration = (endTime - startTime) / 1000

      const bitsLoaded = fileSize * 8
      const speedBps = bitsLoaded / duration
      const speedMbps = parseFloat((speedBps / (1024 * 1024)).toFixed(2))

      if (checkType === 'download') {
        setDownloadSpeed(speedMbps)
      } else {
        setUploadSpeed(speedMbps)
      }
    },
    []
  )

  const measureConnectionSpeed = useCallback(async () => {
    if (mounted.current) {
      setIsRunningTest(true)
    } else {
      return
    }

    const startTime = new Date().getTime()

    try {
      const cacheBuster = `?nnn=${startTime}`
      const response = await fetch(firebaseSpeedTestFile + cacheBuster)
      const imageBlob = await response.blob()
      const endTime = new Date().getTime()

      if (mounted.current) {
        showResults(imageBlob.size, startTime, endTime, NetworkType.download)
      } else {
        return
      }

      // upload measurement
      // upload speed is continuously updated
      const start = new Date().getTime()

      await uploadForSpeedTest(
        imageBlob,
        uuid,
        start,
        showResults,
        setIsLoading,
        setHasAccessToGCP,
        mounted
      )
    } catch (err) {
      console.error(err)
      if (mounted.current) {
        setHasAccessToGCP(false)
        setIsLoading(true)
        setUploadSpeed(0)
        setDownloadSpeed(0)
      } else {
        return
      }
    }

    if (mounted.current) {
      setIsRunningTest(false)
    }
  }, [showResults, uuid])

  useEffect(() => {
    if (getHasAccessToGCP) {
      getHasAccessToGCP(hasAccessToGCP)
    }
  }, [getHasAccessToGCP, hasAccessToGCP])

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!isRunningTest) {
        await measureConnectionSpeed()
      }
    }, pingInterval)

    return () => clearInterval(intervalId)
  }, [isRunningTest, measureConnectionSpeed, pingInterval])

  useEffect(() => {
    if (getLoadingState) {
      getLoadingState(isLoading)
    }
  }, [isLoading, getLoadingState])

  return (
    <VStack alignItems="flex-start">
      {hasAccessToGCP ? (
        <Fragment>
          <HStack mr={1} flexWrap="wrap">
            <Text fontSize="18px" fontWeight={600}>
              <FormattedMessage id="route.terms.network" />
            </Text>
            {isLoading ? (
              <Spinner size="sm" color="gray.500" />
            ) : (
              <Box noOfLines={1} wordBreak="break-all">
                <NetworkStatus downloadSpeed={downloadSpeed} uploadSpeed={uploadSpeed} />
              </Box>
            )}
          </HStack>

          <DownloadUpload
            isLoading={isLoading}
            uploadSpeed={uploadSpeed}
            downloadSpeed={downloadSpeed}
          />
        </Fragment>
      ) : (
        <VStack alignItems="flex-start">
          <HStack spacing="3px">
            <Text fontSize="18px" fontWeight={600}>
              <FormattedMessage id="route.terms.accessToGCP.title" />
            </Text>
            <Icon as={AiFillInfoCircle} boxSize={3} color="red" />
            <Text fontSize="14px">
              <FormattedMessage id="route.terms.accessToGCP.disabled" />
            </Text>
          </HStack>
          <Text maxW="320px">
            <FormattedMessage id="route.terms.accessToGCP" />
          </Text>
        </VStack>
      )}
    </VStack>
  )
}
