import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

import { useAppContext } from '../AppContext'
import { SCREENS } from '../utils/constants'

const CustomerContent = ({ children, className, screen }) => {
  const {
    appEvents: { onPrepareScreenContent, onPrepareFinalScreenContent },
  } = useAppContext()

  const customerContentWrapper = useRef(null)
  const [customerContent, setCustomerContent] = useState(null)

  useEffect(() => {
    const deprecatedContent =
      screen === SCREENS.DONE ? onPrepareFinalScreenContent() : null

    const content = onPrepareScreenContent(screen) || deprecatedContent

    if (content) {
      setCustomerContent(content)
    }
  }, [])

  useEffect(() => {
    if (customerContent instanceof Node) {
      customerContentWrapper.current.appendChild(customerContent)
    }
  }, [customerContent])

  return (
    <div className={className}>
      {!customerContent && children}
      <div className="customer-content-wrapper" ref={customerContentWrapper} />
    </div>
  )
}

CustomerContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  screen: PropTypes.string.isRequired,
}

export default CustomerContent
