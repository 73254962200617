import { merge } from 'lodash-es'

const config = {
  apiBaseUrl: 'https://europe-west1-platformfirebase.cloudfunctions.net/',
  pipe: {
    // Account/enviroment settings we might change between environments
    accountHash: '16a26ee79608b8db994486522e8fe723',
    eid: 'EnEOdm', // production
    // eid: '6qy85A', // staging
    // eid: 'RuAT3I', // development
    // Our basic feature configuration of the pipe recorder
    qualityurl: 'avq/480p.xml',
    showMenu: 'false',
    asv: 0, // disable auto save video (only save after user approval)
    mv: 1, // mirror video horizontally
    sis: 1, // skip intro screen with custom pipe buttons
    st: 0, // disable showing the timer for the flash player (we handle this ourselves)
    ssb: 0, // disable settings buttons (microphone/camera meter)
    dup: 1, // enable video upload
    cornerradius: '0', // disable corner radius (we set this via css ourselves)
  },
  pipeUploadUrl: 'https://s3b-eu.addpipe.com/upload',
  minRecordingTime: 15,
}

export const setConfig = nextConfig => merge(config, nextConfig)

export default config
