import PropTypes from 'prop-types'
import { createContext, useContext, useState } from 'react'

import { AppEventsProp } from './utils/propTypes'

const AppContext = createContext([{}, Function.prototype])

export const AppContextProvider = ({ children, isMobile, appEvents }) => {
  const useStateValue = useState({ isMobile, appEvents })

  return <AppContext.Provider value={useStateValue}>{children}</AppContext.Provider>
}

AppContextProvider.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  appEvents: AppEventsProp,
  children: PropTypes.node.isRequired,
}

export const useAppContext = () => {
  const [context] = useContext(AppContext)

  return context
}
