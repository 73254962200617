import PropTypes from 'prop-types'
import { FaRedoAlt } from 'react-icons/fa'

import IconButton from './IconButton'

const RetryButton = props => (
  <IconButton variant="light" icon={FaRedoAlt} data-cy="retry-btn" {...props} />
)

RetryButton.propTypes = {
  className: PropTypes.any,
}

export default RetryButton
