import { RECRUITING_LANGUAGES_WITH_LABELS } from '@retorio/sdk'
import moment from 'moment'

export const getLangFromLocale = locale => locale.split('-')[0]

export const setMomentLocale = locale => {
  const selectedLanguage = RECRUITING_LANGUAGES_WITH_LABELS.find(
    ({ value }) => value.split('-')[0] === locale
  ) || {
    value: 'en-US',
    label: 'English',
  }

  moment.locale(selectedLanguage.value)
}
