import cx from 'classnames'
import PropTypes from 'prop-types'
import { useState } from 'react'

import style from './SlideCheckbox.scss'

const SlideCheckbox = ({
  checked: defaultChecked,
  className,
  id,
  onChange,
  ...props
}) => {
  const [checked, setChecked] = useState(defaultChecked)

  const handleChange = () =>
    setChecked(draft => {
      const newDraft = !draft

      onChange(newDraft)

      return newDraft
    })

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <label className={cx(style.container, className)} htmlFor={id} {...props}>
      <input type="checkbox" id={id} checked={checked} onChange={handleChange} />
      <div className={style.slider} />
    </label>
  )
}

SlideCheckbox.defaultProps = {
  checked: false,
  onChange: Function.prototype,
}

SlideCheckbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
}

export default SlideCheckbox
